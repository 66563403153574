import React, { useState, useEffect } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import ScenarioRoleModal from '../../components/modals/ScenarioRoleModal';
import './ScenarioOrgChart.css';
import { useFirebase } from '../../contexts/FirebaseContext';

const ScenarioOrgChart = ({ 
  employees = [], 
  scenarios = [], 
  selectedScenario, 
  onScenarioChange, 
  showPlannedHires,
  compensationRanges,
  exchangeRates,
  presentationCurrency 
}) => {
  const { updateScenario } = useFirebase();
  const [selectedRole, setSelectedRole] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  // Handler for updating a role
  const handleUpdateRole = async (updatedRole) => {
    try {
      // Find the scenario that contains this role
      const scenario = scenarios.find(s => s.name === selectedRole.scenarioType);
      if (!scenario) {
        console.error('Could not find scenario for role:', selectedRole);
        return;
      }

      // Create the updated scenario
      const updatedScenario = {
        ...scenario,
        plannedHires: scenario.plannedHires.map(hire => {
          // Normalize both IDs by removing any 'planned-' prefix and converting to string
          const normalizedHireId = String(hire.id).replace('planned-', '');
          const normalizedUpdateId = String(updatedRole.id).replace('planned-', '');
          const isMatch = normalizedHireId === normalizedUpdateId;
          return isMatch ? updatedRole : hire;
        })
      };

      // Update in Firebase
      await updateScenario(scenario.id, updatedScenario);

      // Update local state through the parent
      const updatedScenarios = scenarios.map(s => 
        s.id === scenario.id ? updatedScenario : s
      );
      onScenarioChange(updatedScenarios);

      // Close the modal
      setIsModalOpen(false);
      setSelectedRole(null);
    } catch (error) {
      console.error('Failed to update role:', error);
      alert('Failed to update role. Please try again.');
    }
  };

  // Handler for deleting a role
  const handleDeleteRole = async (roleId) => {
    try {
      // Find the scenario that contains this role
      const scenario = scenarios.find(s => s.name === selectedRole.scenarioType);
      if (!scenario) {
        console.error('Could not find scenario for role:', selectedRole);
        return;
      }

      // Create the updated scenario
      const updatedScenario = {
        ...scenario,
        plannedHires: scenario.plannedHires.filter(hire => hire.id !== roleId)
      };

      // Update in Firebase
      await updateScenario(scenario.id, updatedScenario);

      // Update local state through the parent
      const updatedScenarios = scenarios.map(s => 
        s.id === scenario.id ? updatedScenario : s
      );
      onScenarioChange(updatedScenarios);

      // Close the modal
      setIsModalOpen(false);
      setSelectedRole(null);
    } catch (error) {
      console.error('Failed to delete role:', error);
      alert('Failed to delete role. Please try again.');
    }
  };

  // Add state for transform values
  const [transformValues, setTransformValues] = useState(() => {
    const saved = localStorage.getItem('scenarioOrgChartTransform');
    return saved ? JSON.parse(saved) : { scale: 0.4, positionX: 0, positionY: 0 };
  });

  // Save transform values when they change
  useEffect(() => {
    localStorage.setItem('scenarioOrgChartTransform', JSON.stringify(transformValues));
  }, [transformValues]);

  // Helper function to convert month name to number
  const getMonthNumber = (monthName) => {
    const months = {
      'January': '01',
      'February': '02',
      'March': '03',
      'April': '04',
      'May': '05',
      'June': '06',
      'July': '07',
      'August': '08',
      'September': '09',
      'October': '10',
      'November': '11',
      'December': '12'
    };
    return months[monthName] || '01';
  };

  // Debug function to check for duplicates
  const findDuplicates = (arr, keyFn) => {
    const seen = new Map();
    const duplicates = [];
    
    arr.forEach((item, index) => {
      const key = keyFn(item);
      if (seen.has(key)) {
        duplicates.push({
          key,
          items: [seen.get(key), { item, index }]
        });
      } else {
        seen.set(key, { item, index });
      }
    });
    
    return duplicates;
  };

  const buildOrgChartData = React.useMemo(() => {
    return (employeesData) => {
      if (!employeesData || employeesData.length === 0) {
        return null;
      }
      
      // Check for duplicate IDs
      const idDuplicates = findDuplicates(employeesData, emp => emp.id);
      if (idDuplicates.length > 0) {
        console.warn('Found employees with duplicate IDs:', idDuplicates);
      }

      // Check for duplicate names
      const nameDuplicates = findDuplicates(employeesData, emp => `${emp.firstName} ${emp.surname}`);
      if (nameDuplicates.length > 0) {
        console.warn('Found employees with duplicate names:', nameDuplicates);
      }
      
      const filteredEmployees = showPlannedHires ? employeesData : employeesData.filter(emp => !emp.isPlannedHire);
      
      const employeeMap = new Map();
      filteredEmployees.forEach(emp => {
        const key = `${emp.firstName} ${emp.surname}_${emp.id}`;
        if (employeeMap.has(key)) {
          console.warn('Duplicate in employeeMap:', {
            key,
            existing: employeeMap.get(key),
            new: emp
          });
        }
        employeeMap.set(key, {
          ...emp,
          children: []
        });
      });
    
      const rootNodes = [];
      filteredEmployees.forEach(emp => {
        const empKey = `${emp.firstName} ${emp.surname}_${emp.id}`;
        const node = employeeMap.get(empKey);
        
        if (emp.directManager) {
          // Find the manager by name in the employeeMap
          const managerKey = Array.from(employeeMap.keys()).find(key => 
            key.startsWith(emp.directManager + '_')
          );
          
          if (managerKey && employeeMap.has(managerKey)) {
            const managerNode = employeeMap.get(managerKey);
            managerNode.children.push(node);
          } else {
            rootNodes.push(node);
          }
        } else {
          rootNodes.push(node);
        }
      });

      return rootNodes[0] || null;
    };
  }, [showPlannedHires]);

  const isLeaver = (node) => {
    if (!node) return false;
    try {
      return node.endDate ? new Date(node.endDate) <= new Date() : false;
    } catch (error) {
      console.warn('Error checking leaver status:', error);
      return false;
    }
  };
  
  const isFutureLeaver = (node) => {
    if (!node) return false;
    try {
      return node.endDate ? new Date(node.endDate) > new Date() : false;
    } catch (error) {
      console.warn('Error checking future leaver status:', error);
      return false;
    }
  };

  const isNewHire = (node) => {
    if (!node) return false;
    try {
      return node.startDate ? new Date(node.startDate) > new Date() : false;
    } catch (error) {
      console.warn('Error checking new hire status:', error);
      return false;
    }
  };

  const isBackfill = (node) => {
    if (!node) return false;
    return Boolean(node.isBackfill);
  };

  const handleNodeClick = (node) => {
    console.log('Node clicked:', JSON.stringify(node, null, 2));

    // Extract the original ID by removing the 'planned-' prefix if it exists
    const originalId = node.id?.replace('planned-', '') || node.id;
    console.log('Looking for hire with ID:', originalId);
    
    if (node.isPlannedHire) {
      // Search through all scenarios to find the matching hire
      for (const scenario of scenarios) {
        console.log('Checking scenario:', {
          name: scenario.name,
          plannedHiresCount: scenario.plannedHires?.length || 0,
          plannedHires: JSON.stringify(scenario.plannedHires, null, 2)
        });

        const plannedHire = scenario.plannedHires.find(h => h.id.toString() === originalId);
        if (plannedHire) {
          console.log('Found matching planned hire:', JSON.stringify(plannedHire, null, 2));

          // Include all the original data plus any additional context
          const roleData = {
            ...plannedHire,
            roleTitle: plannedHire.roleTitle || plannedHire.title || node.title,
            team: plannedHire.team || node.team || '',
            function: plannedHire.function || '',
            level: plannedHire.level || '',
            directManager: plannedHire.directManager || node.directManager || '',
            preferredStartMonth: plannedHire.preferredStartMonth || '',
            preferredStartYear: plannedHire.preferredStartYear || '',
            priority: plannedHire.priority || 'Medium',
            exceptionReason: plannedHire.exceptionReason || '',
            organization: plannedHire.organization || scenario.organization || node.organization || '',
            scenarioType: scenario.name,
            id: originalId,
            startDate: plannedHire.startDate || node.startDate || `${plannedHire.preferredStartYear || '2024'}-${getMonthNumber(plannedHire.preferredStartMonth || 'January')}-01`
          };

          console.log('Setting role data:', JSON.stringify(roleData, null, 2));
          
          // First set the role data
          setSelectedRole(roleData);
          
          // Then open the modal in the next tick
          setTimeout(() => {
            console.log('Opening modal for planned hire...');
            setIsModalOpen(true);
          }, 0);
          
          return;
        }
      }

      console.log('No matching planned hire found in any scenario');
    } else {
      // Handle regular employee
      const roleData = {
        ...node,
        roleTitle: node.title || '',
        team: node.team || '',
        function: node.function || '',
        level: node.level || '',
        directManager: node.directManager || '',
        preferredStartMonth: node.startDate ? new Date(node.startDate).toLocaleString('default', { month: 'long' }) : '',
        preferredStartYear: node.startDate ? new Date(node.startDate).getFullYear().toString() : '',
        priority: node.priority || 'Medium',
        exceptionReason: node.exceptionReason || '',
        organization: node.organization || '',
        id: originalId
      };

      console.log('Setting employee role data:', JSON.stringify(roleData, null, 2));
      
      // First set the role data
      setSelectedRole(roleData);
      
      // Then open the modal in the next tick
      setTimeout(() => {
        console.log('Opening modal for regular employee...');
        setIsModalOpen(true);
      }, 0);
    }
  };

  // Add effect to log state changes
  useEffect(() => {
    console.log('Modal state changed:', { isModalOpen, selectedRole });
  }, [isModalOpen, selectedRole]);

  const OrgChartNode = ({ node }) => {
    // Add early return if node is null or undefined
    if (!node) {
      return null;
    }

    const nodeClasses = ['org-node'];
    
    // Only add status classes if the node exists and has the required properties
    if (node) {
      if (isLeaver(node)) {
        nodeClasses.push('leaver');
      } else if (isFutureLeaver(node)) {
        nodeClasses.push('future-leaver');
      } else if (isNewHire(node)) {
        nodeClasses.push('new-hire');
      } else if (isBackfill(node)) {
        nodeClasses.push('backfill');
      }
      if (node.isPlannedHire) {
        nodeClasses.push('planned-hire');
      }
    }

    const onNodeClick = () => {
      if (node) {
        handleNodeClick(node);
      }
    };

    return (
      <div 
        className={nodeClasses.join(' ')}
        onClick={onNodeClick}
        role="button"
        tabIndex={0}
        style={{ cursor: 'pointer' }}
      >
        <div className="org-node-header">
          {node ? `${node.firstName || ''} ${node.surname || ''}` : 'Unknown'}
          {node?.isPlannedHire && node?.scenarioType && (
            <div className={`scenario-badge ${node.scenarioType.toLowerCase().replace(' ', '-')}`}>
              {node.scenarioType}
            </div>
          )}
        </div>
        <div className="org-node-body">
          <div className="node-info">
            <div className="node-title">{node?.title || 'No Title'}</div>
            <div className="node-separator">•</div>
            <div className="node-team">{node?.team || 'No Team'}</div>
          </div>
          {node?.endDate && (
            <div className="node-end-date">
              End: {new Date(node.endDate).toLocaleDateString()}
            </div>
          )}
          {node?.isPlannedHire && (
            <div className="node-badge">
              {isNewHire(node) ? 'New Hire' : 'Backfill'}
            </div>
          )}
          {node && (isLeaver(node) || isFutureLeaver(node)) && (
            <div className="node-badge">
              {isLeaver(node) ? 'Past Leaver' : 'Future Leaver'}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderNode = (node) => {
    if (!node) {
      return null;
    }

    return (
      <TreeNode label={<OrgChartNode node={node} />}>
        {node.children?.map((childNode, index) => (
          <React.Fragment key={`${childNode?.id || index}-${refreshKey}`}>
            {childNode && renderNode(childNode)}
          </React.Fragment>
        ))}
      </TreeNode>
    );
  };

  // Get the base employees with null check
  const baseEmployees = employees ? [...employees] : [];

  // Add planned hires from all scenarios or selected scenario
  if (showPlannedHires && scenarios && scenarios.length > 0) {
    const relevantScenarios = scenarios.filter(s => !selectedScenario || s.name === selectedScenario);
    
    const plannedHires = relevantScenarios.flatMap(scenario =>
      (scenario.plannedHires || [])
        .filter(hire => hire && hire.id)
        .map(hire => {
          // Format the start date if it's not already formatted
          const startDate = hire.startDate || (hire.preferredStartMonth && hire.preferredStartYear ? 
            `${hire.preferredStartYear}-${getMonthNumber(hire.preferredStartMonth)}-01` : '');

          // Create a display name from the role title
          const [firstName, ...restOfTitle] = (hire.roleTitle || '').split(' ');
          const surname = restOfTitle.join(' ');

          return {
            id: `planned-${hire.id}`,
            firstName: firstName || 'New',
            surname: surname || 'Role',
            title: hire.roleTitle || 'Untitled Role',
            team: hire.team || '',
            organization: hire.organization || scenario.organization || '',
            directManager: hire.directManager || '',
            startDate: startDate,
            isPlannedHire: true,
            scenarioType: scenario.name,
            preferredStartMonth: hire.preferredStartMonth,
            preferredStartYear: hire.preferredStartYear,
            priority: hire.priority,
            function: hire.function,
            level: hire.level,
            totalCost: hire.totalCost
          };
        })
    );

    baseEmployees.push(...plannedHires);
  }

  // Pass baseEmployees to buildOrgChartData
  const orgData = buildOrgChartData(baseEmployees);
  
  // Add debug logging to modal render
  const renderModal = () => {
    console.log('Rendering modal with props:', {
      isOpen: isModalOpen,
      hasRole: !!selectedRole,
      roleData: selectedRole
    });

    return isModalOpen && selectedRole ? (
      <ScenarioRoleModal
        key={`${selectedRole.id}-${isModalOpen}`}
        isOpen={isModalOpen}
        onClose={() => {
          console.log('Modal closing...');
          setIsModalOpen(false);
          setSelectedRole(null);
        }}
        role={selectedRole}
        onUpdateRole={handleUpdateRole}
        onDeleteRole={handleDeleteRole}
        compensationRanges={compensationRanges}
        exchangeRates={exchangeRates}
        presentationCurrency={presentationCurrency}
        employees={employees}
      />
    ) : null;
  };

  return (
    <div className="org-chart-container">
      {!baseEmployees || baseEmployees.length === 0 ? (
        <div className="no-data-message">
          No employees found. Add employees to see the organization chart.
        </div>
      ) : !orgData ? (
        <div className="no-data-message">
          No organization structure found. Please ensure there is at least one top-level manager.
        </div>
      ) : (
        <div className="zoom-controls">
          <TransformWrapper
            key={refreshKey}
            initialScale={transformValues.scale}
            initialPositionX={transformValues.positionX}
            initialPositionY={transformValues.positionY}
            minScale={0.1}
            maxScale={2}
            centerOnInit={false}
            limitToBounds={false}
            alignmentAnimation={{ disabled: true }}
            centerZoomedOut={false}
            wheel={{ disabled: false }}
            doubleClick={{ disabled: true }}
            onTransformed={(_, state) => {
              setTransformValues({
                scale: state.scale,
                positionX: state.positionX,
                positionY: state.positionY
              });
            }}
          >
            {({ zoomIn, zoomOut, resetTransform }) => (
              <>
                <div className="zoom-buttons">
                  <button onClick={() => zoomIn()}>Zoom In</button>
                  <button onClick={() => zoomOut()}>Zoom Out</button>
                  <button onClick={() => {
                    resetTransform();
                    setTransformValues({ scale: 0.4, positionX: 0, positionY: 0 });
                    // Remove the setTimeout since we're handling position with state
                  }}>Reset</button>
                </div>
                <div className="chart-wrapper">
                  <TransformComponent>
                    {orgData && orgData.children && (
                      <Tree
                        key={`tree-${refreshKey}`}
                        lineWidth={'2px'}
                        lineColor={'#363940'}
                        lineBorderRadius={'10px'}
                        label={<OrgChartNode node={orgData} />}
                      >
                        {orgData.children.map((node, index) => (
                          <React.Fragment key={`${node.id}-${index}-${refreshKey}`}>
                            {renderNode(node)}
                          </React.Fragment>
                        ))}
                      </Tree>
                    )}
                  </TransformComponent>
                </div>
              </>
            )}
          </TransformWrapper>
        </div>
      )}

      {renderModal()}
    </div>
  );
};

export default ScenarioOrgChart;