import React, { useState, useEffect } from 'react';
import './ApprovalQueue.css';
import { doc, deleteDoc, setDoc, writeBatch, collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useFirebase } from '../../contexts/FirebaseContext';
import { getFirestore } from 'firebase/firestore';

function ApprovalQueue({ scenarios, employees, onUpdateApproval }) {
  const { user, canAccessFeatures } = useFirebase();
  console.log('=== Debug: ApprovalQueue render ===');
  console.log('Props:', {
    scenariosCount: scenarios?.length,
    employeesCount: employees?.length,
    hasUpdateCallback: !!onUpdateApproval
  });

  const [pendingApprovals, setPendingApprovals] = useState([]);
  const [potentialBackfills, setPotentialBackfills] = useState([]);
  const [filterStatus, setFilterStatus] = useState('all');
  const [filterPriority, setFilterPriority] = useState('all');
  const [filterOrg, setFilterOrg] = useState('all');
  const [sortBy, setSortBy] = useState('date');
  const [sortDirection, setSortDirection] = useState('asc');
  const [recruitmentStatuses, setRecruitmentStatuses] = useState(() => {
    return JSON.parse(localStorage.getItem('recruitmentStatus') || '{}');
  });
  const [approvalStatuses, setApprovalStatuses] = useState(() => {
    return JSON.parse(localStorage.getItem('approvalStatuses') || '{}');
  });
  const [backfillStatuses, setBackfillStatuses] = useState(() => {
    const saved = localStorage.getItem('backfillStatuses');
    return saved ? JSON.parse(saved) : {};
  });
  const [roleDetails, setRoleDetails] = useState(() => {
    const saved = localStorage.getItem('taRoleDetails');
    return saved ? JSON.parse(saved) : {};
  });
  const [exchangeRates, setExchangeRates] = useState(() => {
    const saved = localStorage.getItem('exchangeRates');
    return saved ? JSON.parse(saved) : {
      USD: 1,
      EUR: 0.85,
      GBP: 0.73,
      JPY: 110.0
    };
  });
  const [presentationCurrency, setPresentationCurrency] = useState(() => {
    const saved = localStorage.getItem('presentationCurrency');
    return saved || 'USD';
  });

  // First useEffect - create backfills from employees
  useEffect(() => {
    console.log('Employees effect triggered');
    if (!employees?.length) {
      setPotentialBackfills([]);
      return;
    }

    const backfills = employees
      .filter(emp => emp.endDate)
      .map(emp => ({
        id: `backfill-${emp.id}`,
        roleTitle: emp.jobTitle || emp.title || 'No Title',
        organization: emp.org,
        team: emp.team,
        totalCost: emp.baseSalary || 0,
        directManager: emp.directManager,
        isBackfill: true,
        currentEmployee: `${emp.firstName} ${emp.surname}`,
        leaverDate: emp.endDate
      }));
    
    console.log('Generated backfills:', backfills);
    setPotentialBackfills(backfills);
  }, [employees]);

  // Handle storage changes for recruitment status updates
  useEffect(() => {
    console.log('Debug: Setting up storage change handler');
    const handleStorageChange = (e) => {
      if (!e?.key) return;
      
      console.log('Debug: Storage change detected:', e.key);
      // Debounce storage change handling
      const timeoutId = setTimeout(() => {
        if (e.key === 'recruitmentStatus' || e.key === 'approvalStatuses' || e.key === 'taRoleDetails') {
          console.log('Debug: Processing storage change for:', e.key);
          const newRecruitmentStatuses = JSON.parse(localStorage.getItem('recruitmentStatus') || '{}');
          const savedRoleDetails = JSON.parse(localStorage.getItem('taRoleDetails') || '{}');
          const savedApprovalStatuses = JSON.parse(localStorage.getItem('approvalStatuses') || '{}');
          
          setRecruitmentStatuses(newRecruitmentStatuses);
          setApprovalStatuses(savedApprovalStatuses);

          setPendingApprovals(current => {
            const updated = current.map(hire => {
              const roleDetails = savedRoleDetails[hire.id] || {};
              const isCompleted = newRecruitmentStatuses[hire.id]?.status === 'Completed';
              const status = savedApprovalStatuses[hire.id]?.status || 'Pending';
              
              if (!isCompleted) {
                return {
                  ...hire,
                  hireName: null,
                  isCompleted: false,
                  confirmedStartDate: null,
                  baseSalary: null,
                  currency: null,
                  location: null,
                  linkedinProfile: null,
                  approvalStatus: status
                };
              }
              
              return {
                ...hire,
                hireName: roleDetails.hireName,
                isCompleted: true,
                confirmedStartDate: roleDetails.confirmedStartDate,
                baseSalary: roleDetails.baseSalary,
                currency: roleDetails.currency,
                location: roleDetails.location,
                linkedinProfile: roleDetails.linkedinProfile,
                approvalStatus: status
              };
            });
            console.log('Debug: Updated approvals from storage change:', updated);
            return updated;
          });
        }
      }, 100); // Debounce for 100ms

      return () => clearTimeout(timeoutId);
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  // Sync backfill statuses
  useEffect(() => {
    const syncBackfillStatuses = () => {
      const savedBackfillStatuses = JSON.parse(localStorage.getItem('backfillStatuses') || '{}');
      console.log('Syncing backfill statuses:', savedBackfillStatuses);
      
      setPotentialBackfills(current => 
        current.map(backfill => ({
          ...backfill,
          approvalStatus: savedBackfillStatuses[backfill.id]?.status || 'Pending',
          approvalDate: savedBackfillStatuses[backfill.id]?.date || null
        }))
      );
    };

    // Initial sync
    syncBackfillStatuses();

    // Listen for changes
    const handleStorageChange = (e) => {
      if (e?.key === 'backfillStatuses') {
        console.log('Backfill statuses changed in storage');
        syncBackfillStatuses();
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  // Load initial data and handle storage changes
  useEffect(() => {
    if (!onUpdateApproval || !user?.orgId) return;

    console.log('=== Debug: Setting up initial data handlers ===');
    
    // Subscribe to approval statuses from Firestore
    const approvalStatusesRef = collection(db, `organizations/${user.orgId}/approvalStatuses`);
    const unsubscribe = onSnapshot(approvalStatusesRef, (snapshot) => {
      console.log('=== Received approval status update ===');
      console.log('Number of approval documents:', snapshot.size);
      
      const statuses = {};
      snapshot.forEach((doc) => {
        const data = doc.data();
        statuses[doc.id] = data;
        console.log('Approval status for role:', {
          roleId: doc.id,
          status: data.status,
          roleTitle: data.roleTitle,
          date: data.date
        });
      });
      
      setApprovalStatuses(statuses);
      console.log('Updated approval statuses in state');

      // Get all hires from all scenarios
      console.log('Debug: Full scenarios data:', scenarios.map(s => ({
        id: s.id,
        name: s.name,
        status: s.status,
        plannedHires: s.plannedHires?.map(h => ({
          id: h.id,
          roleTitle: h.roleTitle,
          approvalStatus: h.approvalStatus,
          status: h.status
        }))
      })));

      const allHires = (scenarios || []).flatMap(scenario => 
        (scenario?.plannedHires || [])
          .filter(hire => {
            // Only include hires that have been sent for approval
            const approvalStatus = statuses[hire.id]?.status;  // Use Firestore data instead of localStorage
            return approvalStatus === 'Pending' || approvalStatus === 'Approved' || approvalStatus === 'Rejected';
          })
          .map(hire => {
            console.log('Debug: Processing hire:', {
              id: hire.id,
              roleTitle: hire.roleTitle,
              approvalStatus: hire.approvalStatus,
              status: hire.status,
              scenarioName: scenario.name,
              scenarioStatus: scenario.status,
              firestoreStatus: statuses[hire.id]?.status
            });
            return {
              ...hire,
              scenarioName: scenario?.name || '',
              scenarioId: scenario?.id || '',
              approvalStatus: statuses[hire.id]?.status || hire.approvalStatus,
              approvalDate: statuses[hire.id]?.date || hire.approvalDate
            };
          })
      );

      console.log('Debug: All hires:', allHires.map(h => ({
        id: h.id,
        roleTitle: h.roleTitle,
        approvalStatus: h.approvalStatus,
        status: h.status
      })));

      setPendingApprovals(allHires);
    });

    return () => unsubscribe();
  }, [scenarios, onUpdateApproval, user?.orgId]);

  // Subscribe to backfill statuses from Firestore
  useEffect(() => {
    if (!user?.orgId) return;

    const backfillStatusesRef = collection(db, `organizations/${user.orgId}/backfills`);
    const unsubscribe = onSnapshot(backfillStatusesRef, (snapshot) => {
      const statuses = {};
      snapshot.forEach((doc) => {
        statuses[doc.id] = doc.data();
      });
      setBackfillStatuses(statuses);
      
      // Update potential backfills with new statuses
      setPotentialBackfills(current =>
        current.map(backfill => ({
          ...backfill,
          approvalStatus: statuses[backfill.id]?.status || 'Pending',
          approvalDate: statuses[backfill.id]?.date
        }))
      );
    });

    return () => unsubscribe();
  }, [user?.orgId]);

  // Handle backfill updates
  const handleBackfillUpdate = async (backfillId, newStatus) => {
    if (!user?.orgId) return;

    try {
      const currentBackfill = potentialBackfills.find(b => b.id === backfillId);
      if (!currentBackfill) {
        console.error('Backfill not found:', backfillId);
        return;
      }

      const batch = writeBatch(db);
      const backfillRef = doc(db, `organizations/${user.orgId}/backfills/${backfillId}`);
      const statusRef = doc(db, `organizations/${user.orgId}/recruitmentStatus/${backfillId}`);

      if (newStatus === 'Reset') {
        batch.delete(backfillRef);
        batch.delete(statusRef);
      } else {
        const backfillData = {
          status: newStatus,
          updatedAt: new Date().toISOString(),
          approver: user.displayName || 'Current User',
          approverId: user.uid,
          roleTitle: currentBackfill.roleTitle,
          organization: currentBackfill.organization,
          team: currentBackfill.team,
          directManager: currentBackfill.directManager,
          startDate: currentBackfill.leaverDate,
          totalCost: currentBackfill.totalCost,
          isBackfill: true,
          currentEmployee: currentBackfill.currentEmployee,
          recruitmentStatus: newStatus === 'Approved' ? 'Not Started' : null,
          isVisible: true,
          isActive: true,
          orgId: user.orgId
        };

        batch.set(backfillRef, backfillData);
        batch.set(statusRef, {
          status: newStatus,
          updatedAt: new Date().toISOString(),
          updatedBy: user.uid,
          updatedByTab: 'approvals'
        });
      }

      await batch.commit();
    } catch (error) {
      console.error('Error updating backfill:', error);
      alert('Error updating backfill status. Please try again.');
    }
  };

  // Subscribe to recruitment statuses from Firestore
  useEffect(() => {
    if (!user?.orgId) return;

    const recruitmentStatusRef = collection(db, `organizations/${user.orgId}/recruitmentStatus`);
    const unsubscribe = onSnapshot(recruitmentStatusRef, (snapshot) => {
      const statuses = {};
      snapshot.forEach((doc) => {
        statuses[doc.id] = doc.data();
      });
      setRecruitmentStatuses(statuses);
    });

    return () => unsubscribe();
  }, [user?.orgId]);

  // Subscribe to approval statuses from Firestore
  useEffect(() => {
    if (!user?.orgId) {
      console.log('No user.orgId available for approval status subscription');
      return;
    }

    console.log('Setting up approval status subscription for org:', user.orgId);
    const approvalStatusesRef = collection(db, `organizations/${user.orgId}/approvalStatuses`);
    
    const unsubscribe = onSnapshot(approvalStatusesRef, (snapshot) => {
      console.log('=== Received approval status update ===');
      console.log('Number of approval documents:', snapshot.size);
      
      const statuses = {};
      snapshot.forEach((doc) => {
        const data = doc.data();
        statuses[doc.id] = data;
        console.log('Approval status for role:', {
          roleId: doc.id,
          status: data.status,
          roleTitle: data.roleTitle,
          date: data.date
        });
      });
      
      setApprovalStatuses(statuses);
      console.log('Updated approval statuses in state');

      // Update pending approvals with new statuses
      setPendingApprovals(current =>
        current.map(hire => {
          const updatedHire = {
            ...hire,
            approvalStatus: statuses[hire.id]?.status || 'Pending',
            approvalDate: statuses[hire.id]?.date
          };
          console.log('Updated pending approval:', {
            roleId: hire.id,
            oldStatus: hire.approvalStatus,
            newStatus: updatedHire.approvalStatus
          });
          return updatedHire;
        })
      );
    }, (error) => {
      console.error('Error in approval status subscription:', error);
    });

    return () => {
      console.log('Cleaning up approval status subscription');
      unsubscribe();
    };
  }, [user?.orgId]);

  // Check if user has access
  if (!user || !canAccessFeatures()) {
    return (
      <div className="no-access-message">
        <p>You don't have permission to access this feature. Please contact an administrator.</p>
      </div>
    );
  }

  if (!onUpdateApproval) {
    console.error('onUpdateApproval prop is required but not provided');
    return <div>Error: Missing required props</div>;
  }

  // Handle new position approval
  const handleApprovalUpdate = async (hireId, newStatus) => {
    console.log('=== Debug: handleApprovalUpdate START ===');
    console.log('HireId:', hireId);
    console.log('New Status:', newStatus);

    if (!user?.orgId) {
      console.error('No organization ID available');
      return;
    }

    // Store previous state for rollback
    const previousState = [...pendingApprovals];

    try {
      // Find the scenario containing this hire
      const scenarioWithHire = scenarios.find(scenario => 
        scenario.plannedHires?.some(hire => hire.id === hireId)
      );

      if (!scenarioWithHire) {
        throw new Error('Scenario not found for this hire');
      }

      // Get the effective status - ensure proper case for Approved
      const effectiveStatus = newStatus === 'Reset' ? 'Pending' : newStatus;
      const effectiveScenarioStatus = effectiveStatus === 'Approved' ? 'Approved' : effectiveStatus.toLowerCase();
      console.log('Debug: Effective status:', effectiveStatus);
      console.log('Debug: Effective scenario status:', effectiveScenarioStatus);

      // Find the specific hire being updated
      const updatedHire = scenarioWithHire.plannedHires.find(hire => hire.id === hireId);

      // Start a batch write
      const batch = writeBatch(db);

      // Update or delete the approval status document
      const approvalRef = doc(db, `organizations/${user.orgId}/approvalStatuses/${hireId}`);
      if (newStatus === 'Reset') {
        batch.delete(approvalRef);
      } else {
        batch.set(approvalRef, {
          status: effectiveStatus,
          date: new Date().toISOString(),
          approver: user.displayName || 'Current User',
          approverId: user.uid,
          scenarioId: scenarioWithHire.id,
          scenarioType: scenarioWithHire.name,
          organization: scenarioWithHire.organization,
          roleTitle: updatedHire.roleTitle,
          team: updatedHire.team,
          directManager: updatedHire.directManager,
          totalCost: updatedHire.totalCost,
          startDate: updatedHire.startDate
        });
      }

      // Update the scenario document
      const updatedPlannedHires = scenarioWithHire.plannedHires.map(hire => {
        if (hire.id === hireId) {
          return {
            ...hire,
            status: effectiveStatus,
            approvalStatus: effectiveStatus,
            approvalDate: new Date().toISOString(),
            approvedBy: user.uid,
            approvedByName: user.displayName || 'Current User'
          };
        }
        return hire;
      });

      const scenarioRef = doc(db, `organizations/${user.orgId}/scenarios/${scenarioWithHire.id}`);
      batch.set(scenarioRef, {
        ...scenarioWithHire,
        plannedHires: updatedPlannedHires,
        lastModified: new Date().toISOString(),
        status: effectiveScenarioStatus
      });

      // Commit the batch
      await batch.commit();
      console.log('Successfully updated approval status in Firestore');

      // Update local state through callback
      await onUpdateApproval(scenarioWithHire.id, {
        ...scenarioWithHire,
        plannedHires: updatedPlannedHires,
        status: effectiveScenarioStatus
      });

    } catch (error) {
      console.error('Error in handleApprovalUpdate:', error);
      
      // Rollback UI state on error
      setPendingApprovals(previousState);
      
      if (error.message.includes('Missing or insufficient permissions')) {
        alert('You do not have permission to update approvals. Please contact your administrator.');
      } else {
        alert('Error updating approval status. Please try again or contact support.');
      }
    }
  };

  const getUniqueOrgs = () => {
    return [...new Set(pendingApprovals.map(hire => hire.organization))];
  };

  const getPendingCount = () => {
    return pendingApprovals.filter(hire => hire.approvalStatus === 'Pending').length;
  };

  const filteredAndSortedApprovals = () => {
    let filtered = pendingApprovals.filter(hire => {
      if (filterStatus !== 'all' && (!hire.approvalStatus || hire.approvalStatus.toLowerCase() !== filterStatus)) return false;
      if (filterPriority !== 'all' && (!hire.priority || hire.priority.toLowerCase() !== filterPriority.toLowerCase())) return false;
      if (filterOrg !== 'all' && hire.organization !== filterOrg) return false;
      return true;
    });

    return filtered.sort((a, b) => {
      const direction = sortDirection === 'asc' ? 1 : -1;
      
      switch (sortBy) {
        case 'date':
          return direction * (new Date(a.startDate || 0) - new Date(b.startDate || 0));
        case 'cost':
          return direction * (Number(a.totalCost || 0) - Number(b.totalCost || 0));
        case 'priority': {
          const priorityWeight = { 'High': 3, 'Medium': 2, 'Low': 1 };
          return direction * ((priorityWeight[b.priority] || 0) - (priorityWeight[a.priority] || 0));
        }
        default:
          return 0;
      }
    });
  };

  const handleSort = (newSortBy) => {
    if (sortBy === newSortBy) {
      setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(newSortBy);
      setSortDirection('asc');
    }
  };

  // Update the role card display to use the correct data
  const renderRoleDetails = (hire) => (
    <>
      <div className="detail-group">
        <label>Organization:</label>
        <span>{hire.organization || 'Not Specified'}</span>
      </div>
      <div className="detail-group">
        <label>Team:</label>
        <span>{hire.team || 'Not Specified'}</span>
      </div>
      <div className="detail-group">
        <label>{hire.isCompleted ? 'Start Date' : 'Target Start'}:</label>
        <span>
          {hire.isCompleted ? 
            (hire.confirmedStartDate ? new Date(hire.confirmedStartDate).toLocaleDateString() : 'Not set') : 
            (hire.startDate ? new Date(hire.startDate).toLocaleDateString() : 'Not set')}
        </span>
      </div>
      <div className="detail-group">
        <label>{hire.isCompleted ? 'Salary' : 'Budget'}:</label>
        <span>
          {hire.isCompleted ? 
            (hire.baseSalary ? `${hire.currency || 'USD'} ${Number(hire.baseSalary).toLocaleString()}` : 'Not set') :
            (hire.totalCost ? `USD ${Number(hire.totalCost).toLocaleString()}` : 'Not set')}
        </span>
      </div>
      {hire.isCompleted && hire.location && (
        <div className="detail-group">
          <label>Location:</label>
          <span>{hire.location}</span>
        </div>
      )}
      <div className="detail-group">
        <label>Priority:</label>
        <span className={`priority-tag ${(hire.priority || 'medium').toLowerCase()}`}>
          {hire.priority || 'Medium'}
        </span>
      </div>
      <div className="detail-group">
        <label>Scenario:</label>
        <span>{hire.scenarioName}</span>
      </div>
      <div className="detail-group">
        <label>Reports To:</label>
        <span>{hire.directManager || 'Not Specified'}</span>
      </div>
    </>
  );

  return (
    <div className="approval-queue">
      <div className="approval-header">
        <h2>Hiring Approvals ({getPendingCount()} pending)</h2>
        <div className="filter-controls">
          <select 
            value={filterStatus} 
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="all">All Statuses</option>
            <option value="pending">Pending</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
          </select>

          <select 
            value={filterPriority} 
            onChange={(e) => setFilterPriority(e.target.value)}
          >
            <option value="all">All Priorities</option>
            <option value="high">High Priority</option>
            <option value="medium">Medium Priority</option>
            <option value="low">Low Priority</option>
          </select>

          <select 
            value={filterOrg} 
            onChange={(e) => setFilterOrg(e.target.value)}
          >
            <option value="all">All Organizations</option>
            {getUniqueOrgs().map(org => (
              <option key={org} value={org}>{org}</option>
            ))}
          </select>
        </div>

        <div className="sort-controls">
          <button 
            onClick={() => handleSort('date')}
            className={sortBy === 'date' ? 'active' : ''}
          >
            Sort by Date {sortBy === 'date' && (sortDirection === 'asc' ? '↑' : '↓')}
          </button>
          <button 
            onClick={() => handleSort('cost')}
            className={sortBy === 'cost' ? 'active' : ''}
          >
            Sort by Cost {sortBy === 'cost' && (sortDirection === 'asc' ? '↑' : '↓')}
          </button>
          <button 
            onClick={() => handleSort('priority')}
            className={sortBy === 'priority' ? 'active' : ''}
          >
            Sort by Priority {sortBy === 'priority' && (sortDirection === 'asc' ? '↑' : '↓')}
          </button>
        </div>
      </div>

      <div className="approval-sections">
        {/* New Position Requests Section */}
        <section className="new-hires-section">
          <h3>New Position Requests</h3>
          <div className="approval-list">
            {filteredAndSortedApprovals().filter(hire => 
              !recruitmentStatuses[hire.id]?.status || 
              recruitmentStatuses[hire.id]?.status !== 'Completed'
            ).length === 0 ? (
              <div className="no-approvals">
                <p>No new position requests to display</p>
              </div>
            ) : (
              filteredAndSortedApprovals()
                .filter(hire => 
                  !recruitmentStatuses[hire.id]?.status || 
                  recruitmentStatuses[hire.id]?.status !== 'Completed'
                )
                .map(hire => (
                  <div key={hire.id} className={`approval-item ${hire.isCompleted ? 'completed' : ''}`}>
                    <div className="approval-header">
                      <div className="title-and-badge">
                        <div className="title-and-timestamp">
                          <h3>{hire.isCompleted && hire.hireName ? hire.hireName : hire.roleTitle}</h3>
                          {hire.approvalDate && (
                            <span className="timestamp">
                              Last updated: {new Date(hire.approvalDate).toLocaleString()}
                            </span>
                          )}
                        </div>
                        <span className={`request-type-badge ${hire.isCompleted ? 'hired' : 'new'}`}>
                          {hire.isCompleted ? 'Hired' : 'New Hire'}
                        </span>
                      </div>
                      <div className="status-badges">
                        {hire.approvalStatus === 'Approved' && recruitmentStatuses[hire.id]?.status && (
                          <span className={`recruitment-status ${(recruitmentStatuses[hire.id].status || '').toLowerCase().replace(' ', '-')}`}>
                            <span className="status-label">TA Status:</span> {recruitmentStatuses[hire.id].status}
                          </span>
                        )}
                        <span className={`status-badge ${(hire.approvalStatus || 'pending').toLowerCase()}`}>
                          {hire.approvalStatus || 'Pending'}
                        </span>
                      </div>
                    </div>
                    <div className="approval-details">
                      {renderRoleDetails(hire)}
                      <div className="approval-actions">
                        {(!hire.approvalStatus || hire.approvalStatus === 'Pending') ? (
                          <>
                            <button 
                              onClick={() => handleApprovalUpdate(hire.id, 'Approved')}
                              className="approve-button"
                            >
                              Approve
                            </button>
                            <button 
                              onClick={() => handleApprovalUpdate(hire.id, 'Rejected')}
                              className="reject-button"
                            >
                              Reject
                            </button>
                          </>
                        ) : (
                          <button 
                            onClick={() => {
                              console.log('=== Debug: Reset button clicked ===');
                              console.log('Hire ID:', hire.id);
                              console.log('Current approval status:', hire.approvalStatus);
                              handleApprovalUpdate(hire.id, 'Reset');
                            }}
                            className="reset-button"
                          >
                            Reset Status
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))
            )}
          </div>
        </section>

        {/* Backfills Section */}
        <section className="backfills-section">
          <h3>Potential Backfill Positions</h3>
          <div className="approval-list">
            {potentialBackfills.length === 0 ? (
              <div className="no-approvals">
                <p>No potential backfills at this time</p>
              </div>
            ) : (
              potentialBackfills.map(backfill => (
                <div key={backfill.id} className="approval-item">
                  <div className="approval-header">
                    <div className="title-and-badge">
                      <div className="title-and-timestamp">
                        <h3>{backfill.roleTitle}</h3>
                        {backfill.approvalDate && (
                          <span className="timestamp">
                            Last updated: {new Date(backfill.approvalDate).toLocaleString()}
                          </span>
                        )}
                      </div>
                      <span className="request-type-badge backfill">Potential Backfill</span>
                    </div>
                    <div className="status-badges">
                      {backfill.approvalStatus === 'Approved' && recruitmentStatuses[backfill.id]?.status && (
                        <span className={`recruitment-status ${recruitmentStatuses[backfill.id].status.toLowerCase().replace(' ', '-')}`}>
                          <span className="status-label">TA Status:</span> {recruitmentStatuses[backfill.id].status}
                        </span>
                      )}
                      <span className={`status-badge ${(backfill.approvalStatus || 'pending').toLowerCase()}`}>
                        {backfill.approvalStatus || 'Pending'}
                      </span>
                    </div>
                  </div>
                  <div className="approval-details">
                    <div className="detail-group">
                      <label>Current Employee:</label>
                      <span>{backfill.currentEmployee}</span>
                    </div>
                    <div className="detail-group">
                      <label>Expected Leave Date:</label>
                      <span>{new Date(backfill.leaverDate).toLocaleDateString()}</span>
                    </div>
                    <div className="detail-group">
                      <label>Organization:</label>
                      <span>{backfill.organization}</span>
                    </div>
                    <div className="detail-group">
                      <label>Team:</label>
                      <span>{backfill.team}</span>
                    </div>
                    <div className="detail-group">
                      <label>Reports To:</label>
                      <span>{backfill.directManager || 'Not Specified'}</span>
                    </div>
                  </div>
                  <div className="approval-actions">
                    {(!backfill.approvalStatus || backfill.approvalStatus === 'Pending') ? (
                      <>
                        <button
                          className="approve-button"
                          onClick={() => handleBackfillUpdate(backfill.id, 'Approved')}
                        >
                          Approve
                        </button>
                        <button
                          className="reject-button"
                          onClick={() => handleBackfillUpdate(backfill.id, 'Rejected')}
                        >
                          Reject
                        </button>
                      </>
                    ) : (
                      <button
                        className="reset-button"
                        onClick={() => handleBackfillUpdate(backfill.id, 'Reset')}
                      >
                        Reset Status
                      </button>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </section>

        {/* Completed Hires Section */}
        <section className="completed-hires-section">
          <div className="section-wrapper">
            <h3>Completed Hires</h3>
            <div className="approval-list">
              {filteredAndSortedApprovals().filter(hire => 
                recruitmentStatuses[hire.id]?.status === 'Completed'
              ).length === 0 ? (
                <div className="no-approvals">
                  <p>No completed hires to display</p>
                </div>
              ) : (
                filteredAndSortedApprovals()
                  .filter(hire => 
                    recruitmentStatuses[hire.id]?.status === 'Completed'
                  )
                  .map(hire => (
                    <div key={hire.id} className={`approval-item ${hire.isCompleted ? 'completed' : ''}`}>
                      <div className="approval-header">
                        <div className="title-and-badge">
                          <div className="title-and-timestamp">
                            <h3>{hire.isCompleted && hire.hireName ? hire.hireName : hire.roleTitle}</h3>
                            {hire.approvalDate && (
                              <span className="timestamp">
                                Last updated: {new Date(hire.approvalDate).toLocaleString()}
                              </span>
                            )}
                          </div>
                          <span className={`request-type-badge ${hire.isCompleted ? 'hired' : 'new'}`}>
                            {hire.isCompleted ? 'Hired' : 'New Hire'}
                          </span>
                        </div>
                        <div className="status-badges">
                          {hire.approvalStatus === 'Approved' && recruitmentStatuses[hire.id]?.status && (
                            <span className={`recruitment-status ${(recruitmentStatuses[hire.id].status || '').toLowerCase().replace(' ', '-')}`}>
                              <span className="status-label">TA Status:</span> {recruitmentStatuses[hire.id].status}
                            </span>
                          )}
                          <span className={`status-badge ${(hire.approvalStatus || 'pending').toLowerCase()}`}>
                            {hire.approvalStatus || 'Pending'}
                          </span>
                        </div>
                      </div>
                      <div className="approval-details">
                        {renderRoleDetails(hire)}
                        <div className="approval-actions">
                          {(!hire.approvalStatus || hire.approvalStatus === 'Pending') ? (
                            <>
                              <button 
                                onClick={() => handleApprovalUpdate(hire.id, 'Approved')}
                                className="approve-button"
                              >
                                Approve
                              </button>
                              <button 
                                onClick={() => handleApprovalUpdate(hire.id, 'Rejected')}
                                className="reject-button"
                              >
                                Reject
                              </button>
                            </>
                          ) : (
                            <button 
                              onClick={() => {
                                console.log('=== Debug: Reset button clicked ===');
                                console.log('Hire ID:', hire.id);
                                console.log('Current approval status:', hire.approvalStatus);
                                handleApprovalUpdate(hire.id, 'Reset');
                              }}
                              className="reset-button"
                            >
                              Reset Status
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ApprovalQueue;