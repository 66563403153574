import React, { useState, useEffect } from 'react';
import './Commission.css';

const CommissionStructureModal = ({ isOpen, onClose, onSave, currentStructures = {} }) => {
    const [selectedRole, setSelectedRole] = useState('');
    const [newRole, setNewRole] = useState('');
    const [showAddRole, setShowAddRole] = useState(false);
    const [structure, setStructure] = useState({
        thresholds: [
            { threshold: 80, payout: 80 },
            { threshold: 100, payout: 100 }
        ],
        defaultPayout: 0
    });

    // Initialize with first role or show add role form if no roles exist
    useEffect(() => {
        const roles = Object.keys(currentStructures);
        if (roles.length > 0) {
            setSelectedRole(roles[0]);
            setStructure(currentStructures[roles[0]]);
        } else {
            setShowAddRole(true);
        }
    }, [currentStructures]);

    const handleRoleChange = (role) => {
        setSelectedRole(role);
        setStructure(currentStructures[role] || {
            thresholds: [
                { threshold: 80, payout: 80 },
                { threshold: 100, payout: 100 }
            ],
            defaultPayout: 0
        });
    };

    const handleAddRole = () => {
        if (newRole && !currentStructures[newRole]) {
            onSave(newRole, {
                thresholds: [
                    { threshold: 80, payout: 80 },
                    { threshold: 100, payout: 100 }
                ],
                defaultPayout: 0
            });
            setSelectedRole(newRole);
            setNewRole('');
            setShowAddRole(false);
        }
    };

    const handleThresholdChange = (index, field, value) => {
        const newThresholds = [...structure.thresholds];
        newThresholds[index] = {
            ...newThresholds[index],
            [field]: parseFloat(value) || 0
        };
        setStructure({
            ...structure,
            thresholds: newThresholds
        });
    };

    const addThreshold = () => {
        setStructure({
            ...structure,
            thresholds: [
                ...structure.thresholds,
                { threshold: 0, payout: 0 }
            ]
        });
    };

    const removeThreshold = (index) => {
        const newThresholds = structure.thresholds.filter((_, i) => i !== index);
        setStructure({
            ...structure,
            thresholds: newThresholds
        });
    };

    if (!isOpen) return null;

    const roles = Object.keys(currentStructures);

    return (
        <div className="modal-overlay">
            <div className="commission-structure-modal">
                <div className="modal-header">
                    <h2>Commission Structure</h2>
                    <button className="close-button" onClick={onClose}>×</button>
                </div>
                
                <div className="modal-content">
                    <div className="role-selector">
                        <div className="form-group">
                            <label>Select Role</label>
                            <div className="role-selector-controls">
                                {roles.length > 0 ? (
                                    <>
                                        <select
                                            value={selectedRole}
                                            onChange={(e) => handleRoleChange(e.target.value)}
                                        >
                                            {roles.map(role => (
                                                <option key={role} value={role}>{role}</option>
                                            ))}
                                        </select>
                                        <button 
                                            className="add-role-button"
                                            onClick={() => setShowAddRole(true)}
                                        >
                                            Add New Role
                                        </button>
                                    </>
                                ) : (
                                    <div className="no-roles-message">
                                        No roles configured yet. Add your first role below.
                                    </div>
                                )}
                            </div>
                        </div>

                        {showAddRole && (
                            <div className="add-role-form">
                                <input
                                    type="text"
                                    value={newRole}
                                    onChange={(e) => setNewRole(e.target.value)}
                                    placeholder="Enter new role name"
                                />
                                <button 
                                    className="save-button"
                                    onClick={handleAddRole}
                                    disabled={!newRole.trim()}
                                >
                                    Add
                                </button>
                                <button 
                                    className="cancel-button"
                                    onClick={() => {
                                        setNewRole('');
                                        setShowAddRole(false);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        )}
                    </div>

                    {(selectedRole || roles.length === 0) && (
                        <>
                            <div className="structure-description">
                                <p>Configure how commission is calculated based on attainment percentage.</p>
                                <p>For each threshold, specify:</p>
                                <ul>
                                    <li>Attainment %: The percentage of target that needs to be reached</li>
                                    <li>Payout %: The percentage of maximum commission to be paid</li>
                                </ul>
                            </div>

                            <div className="thresholds-container">
                                <h3>Commission Thresholds for {selectedRole || 'New Role'}</h3>
                                {structure.thresholds.map((threshold, index) => (
                                    <div key={index} className="threshold-row">
                                        <div className="threshold-inputs">
                                            <div className="form-group">
                                                <label>Attainment %</label>
                                                <input
                                                    type="number"
                                                    value={threshold.threshold}
                                                    onChange={(e) => handleThresholdChange(index, 'threshold', e.target.value)}
                                                    min="0"
                                                    max="200"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Payout %</label>
                                                <input
                                                    type="number"
                                                    value={threshold.payout}
                                                    onChange={(e) => handleThresholdChange(index, 'payout', e.target.value)}
                                                    min="0"
                                                    max="200"
                                                />
                                            </div>
                                        </div>
                                        <button 
                                            className="remove-threshold-button"
                                            onClick={() => removeThreshold(index)}
                                            disabled={structure.thresholds.length <= 1}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                ))}
                                <button className="add-threshold-button" onClick={addThreshold}>
                                    Add Threshold
                                </button>
                            </div>

                            <div className="default-payout">
                                <div className="form-group">
                                    <label>Default Payout % (for attainment below first threshold)</label>
                                    <input
                                        type="number"
                                        value={structure.defaultPayout}
                                        onChange={(e) => setStructure({
                                            ...structure,
                                            defaultPayout: parseFloat(e.target.value) || 0
                                        })}
                                        min="0"
                                        max="100"
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>

                <div className="modal-footer">
                    <button className="cancel-button" onClick={onClose}>Cancel</button>
                    <button 
                        className="save-button"
                        onClick={() => {
                            onSave(selectedRole, structure);
                            onClose();
                        }}
                    >
                        Save Structure
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CommissionStructureModal; 