import React, { useState, useEffect, useRef } from 'react';
import { countries } from '../../utils/countries';
import { handleNewLocation } from '../../utils/locationUtils';
import './TARoleModal.css';

function TARoleModal({ isOpen, onClose, role, recruitmentStatus, onUpdateRole }) {
    const contractTypes = [
      'Full Time - Direct',
      'Part Time - Direct',
      'Fixed Term - Direct',
      'Contractor - Agency',
      'Contractor - Direct'
    ];

    const currencies = ['USD', 'EUR', 'GBP', 'AUD', 'CAD', 'SGD'];

    const [editMode, setEditMode] = useState(true);
    const [locationSuggestions, setLocationSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const suggestionRef = useRef(null);

    const [roleDetails, setRoleDetails] = useState(() => ({
      roleTitle: role.roleTitle || '',
      team: role.team || '',
      organization: role.organization || '',
      directManager: role.directManager || '',
      totalCost: role.totalCost || '',
      startDate: role.startDate || '',
      hireName: role.hireName || '',
      linkedinProfile: role.linkedinProfile || '',
      confirmedStartDate: role.confirmedStartDate || '',
      location: role.location || '',
      baseSalary: role.baseSalary || '',
      currency: role.currency || 'USD',
      contractType: role.contractType || 'Full Time - Direct',
      status: recruitmentStatus || role.status
    }));

    useEffect(() => {
      if (role) {
        setRoleDetails({
          roleTitle: role.roleTitle || '',
          team: role.team || '',
          organization: role.organization || '',
          directManager: role.directManager || '',
          totalCost: role.totalCost || '',
          startDate: role.startDate || '',
          hireName: role.hireName || '',
          linkedinProfile: role.linkedinProfile || '',
          confirmedStartDate: role.confirmedStartDate || '',
          location: role.location || '',
          baseSalary: role.baseSalary || '',
          currency: role.currency || 'USD',
          contractType: role.contractType || 'Full Time - Direct',
          status: recruitmentStatus || role.status
        });
      }
    }, [role, recruitmentStatus]);

    const handleSubmit = async (e) => {
      e.preventDefault();
      
      try {
        // Validate required fields for completion
        if (recruitmentStatus === 'Completed') {
          if (!roleDetails.hireName) {
            alert('Please enter the name of the hire');
            return;
          }
          if (!roleDetails.confirmedStartDate) {
            alert('Please enter the confirmed start date');
            return;
          }
          if (!roleDetails.location) {
            alert('Please enter the location');
            return;
          }
          if (!roleDetails.baseSalary) {
            alert('Please enter the base salary');
            return;
          }
          if (!roleDetails.contractType) {
            alert('Please select a contract type');
            return;
          }
          
          // Validate that location is a valid country
          if (!countries.includes(roleDetails.location)) {
            alert('Please select a valid country from the list');
            return;
          }

          // Create employee record for completed hire
          const newEmployee = {
            id: `hired-${role.id}`,
            firstName: roleDetails.hireName.split(' ')[0] || '',
            surname: roleDetails.hireName.split(' ').slice(1).join(' ') || '',
            title: roleDetails.roleTitle,
            org: roleDetails.organization,
            team: roleDetails.team,
            directManager: roleDetails.directManager,
            contractType: roleDetails.contractType,
            location: roleDetails.location,
            startDate: roleDetails.confirmedStartDate,
            baseSalary: roleDetails.baseSalary,
            currency: roleDetails.currency,
            linkedinProfile: roleDetails.linkedinProfile,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            source: 'talent-acquisition',
            originalRoleId: role.id
          };

          // Call the parent component with both the role update and the new employee
          const updatedRole = {
            ...role,
            ...roleDetails,
            status: 'Completed',  // Explicitly set status to Completed
            convertedToEmployee: true,
            employeeId: newEmployee.id
          };
          
          console.log('Completing hire with role:', updatedRole);
          await onUpdateRole(updatedRole, newEmployee);
        } else {
          // Just update the role if not completed
          const updatedRole = {
            ...role,
            ...roleDetails,
            status: roleDetails.status  // Preserve the current status
          };
          
          console.log('Updating role:', updatedRole);
          await onUpdateRole(updatedRole);
        }
        
        // Close the modal only if the update was successful
        onClose();
      } catch (error) {
        console.error('Error updating role:', error);
        if (error.code === 'resource-exhausted') {
          alert('Unable to save to Firebase due to quota limits. Your changes have been saved locally.');
        } else {
          alert('An error occurred while saving. Please try again.');
        }
      }
    };

    // Add location handling functions
    const handleLocationChange = (e) => {
      const input = e.target.value;
      setRoleDetails({...roleDetails, location: input});
      
      if (input.length > 0) {
        const filteredSuggestions = countries.filter(country =>
          country.toLowerCase().includes(input.toLowerCase())
        );
        setLocationSuggestions(filteredSuggestions);
        setShowSuggestions(true);
      } else {
        setLocationSuggestions([]);
        setShowSuggestions(false);
      }
    };

    const handleLocationSelect = (country) => {
      setRoleDetails({...roleDetails, location: country});
      setShowSuggestions(false);
    };

    // Add click outside handler for location suggestions
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (suggestionRef.current && !suggestionRef.current.contains(event.target)) {
          setShowSuggestions(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    if (!isOpen) return null;

    return (
      <div className="modal-overlay">
        <div className="ta-role-modal">
          <div className="modal-header">
            <h2>{recruitmentStatus === 'Completed' ? 'Complete Hire' : 'Edit Role Details'}</h2>
            <button className="close-button" onClick={onClose}>&times;</button>
          </div>
  
          <div className="role-details-content">
            <form onSubmit={handleSubmit} className="role-edit-form">
              <div className="form-section">
                <h3>Role Details</h3>
                <div className="form-grid">
                  {/* Basic role details that are always shown */}
                  <div className="form-group">
                    <label>Role Title:</label>
                    <input
                      type="text"
                      value={roleDetails.roleTitle}
                      onChange={(e) => setRoleDetails({...roleDetails, roleTitle: e.target.value})}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Team:</label>
                    <input
                      type="text"
                      value={roleDetails.team}
                      onChange={(e) => setRoleDetails({...roleDetails, team: e.target.value})}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Organization:</label>
                    <input
                      type="text"
                      value={roleDetails.organization}
                      onChange={(e) => setRoleDetails({...roleDetails, organization: e.target.value})}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Reports To:</label>
                    <input
                      type="text"
                      value={roleDetails.directManager}
                      onChange={(e) => setRoleDetails({...roleDetails, directManager: e.target.value})}
                    />
                  </div>

                  {/* Fields shown when completing a hire */}
                  {recruitmentStatus === 'Completed' ? (
                    <>
                      <div className="form-group">
                        <label>Name of Hire:</label>
                        <input
                          type="text"
                          value={roleDetails.hireName}
                          onChange={(e) => setRoleDetails({...roleDetails, hireName: e.target.value})}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>LinkedIn Profile:</label>
                        <input
                          type="url"
                          value={roleDetails.linkedinProfile}
                          onChange={(e) => setRoleDetails({...roleDetails, linkedinProfile: e.target.value})}
                        />
                      </div>
                      <div className="form-group">
                        <label>Confirmed Start Date:</label>
                        <input
                          type="date"
                          value={roleDetails.confirmedStartDate}
                          onChange={(e) => setRoleDetails({...roleDetails, confirmedStartDate: e.target.value})}
                          required
                        />
                      </div>
                      <div className="form-group location-input-container">
                        <label>Location:</label>
                        <input
                          type="text"
                          value={roleDetails.location}
                          onChange={handleLocationChange}
                          placeholder="Search for a country..."
                          required
                        />
                        {showSuggestions && locationSuggestions.length > 0 && (
                          <div className="location-suggestions" ref={suggestionRef}>
                            {locationSuggestions.map((country, index) => (
                              <div
                                key={index}
                                className="suggestion-item"
                                onClick={() => handleLocationSelect(country)}
                              >
                                {country}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label>Base Salary:</label>
                        <input
                          type="number"
                          value={roleDetails.baseSalary}
                          onChange={(e) => setRoleDetails({...roleDetails, baseSalary: e.target.value})}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>Currency:</label>
                        <select
                          value={roleDetails.currency}
                          onChange={(e) => setRoleDetails({...roleDetails, currency: e.target.value})}
                        >
                          {currencies.map(currency => (
                            <option key={currency} value={currency}>{currency}</option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group">
                        <label>Contract Type:</label>
                        <select
                          value={roleDetails.contractType}
                          onChange={(e) => setRoleDetails({...roleDetails, contractType: e.target.value})}
                        >
                          {contractTypes.map(type => (
                            <option key={type} value={type}>{type}</option>
                          ))}
                        </select>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="form-group">
                        <label>Budget:</label>
                        <input
                          type="number"
                          value={roleDetails.totalCost}
                          onChange={(e) => setRoleDetails({...roleDetails, totalCost: e.target.value})}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>Target Start Date:</label>
                        <input
                          type="date"
                          value={roleDetails.startDate}
                          onChange={(e) => setRoleDetails({...roleDetails, startDate: e.target.value})}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="modal-footer">
                <button type="submit" className="save-button">
                  {recruitmentStatus === 'Completed' ? 'Complete Hire' : 'Save Changes'}
                </button>
                <button type="button" className="cancel-button" onClick={onClose}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
}

export default TARoleModal;