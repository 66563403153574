import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import './CompensationFramework.css';
import { useFirebase } from '../../contexts/FirebaseContext';
import { updateCompensationRanges, getCompensationRanges } from '../../services/firestore';

function CompensationFramework({ employees = [], scenarios = [] }) {
  const { user } = useFirebase();
  const [selectedFunction, setSelectedFunction] = useState('Software Engineering');
  
  const functions = [
    'Biz Ops & Strategy',
    'Content',
    'Customer Service',
    'Data Analytics',
    'Finance',
    'Graphic Design',
    'Marketing',
    'People',
    'Product Design',
    'Product Management',
    'Sales',
    'SEO',
    'Software Engineering',
    'User Research',
    'Workplace & Culture',
    'Customer Success',
    'RevOps',
    'IT Support'
  ];

  const levels = [
    { id: 'ic1', display: 'IC1' },
    { id: 'ic2', display: 'IC2' },
    { id: 'ic3', display: 'IC3 / M3' },
    { id: 'ic4', display: 'IC4 / M4' },
    { id: 'ic5', display: 'IC5 / M5' },
    { id: 'ic6', display: 'IC6 / M6' },
    { id: 'l1', display: 'L1' },
    { id: 'l2', display: 'L2' },
    { id: 'l3', display: 'L3' },
    { id: 's1', display: 'S1' },
    { id: 's2', display: 'S2' },
    { id: 's3', display: 'S3' },
    { id: 's4', display: 'S4' },
    { id: 's5', display: 'S5' }
  ];

  const [compensationRanges, setCompensationRanges] = useState(() => {
    try {
      const savedRanges = localStorage.getItem('compensationRanges');
      return savedRanges ? JSON.parse(savedRanges) : {};
    } catch (error) {
      console.warn('Error loading compensation ranges from localStorage:', error);
      return {};
    }
  });

  const [selectedCurrency, setSelectedCurrency] = useState(() => {
    const saved = localStorage.getItem('compensationCurrency');
    return saved || 'USD';
  });

  // Predefined currencies from the exchange rate implementation
  const currencies = ['USD', 'EUR', 'GBP', 'CHF', 'SEK', 'PHP', 'INR'];

  useEffect(() => {
    localStorage.setItem('compensationCurrency', selectedCurrency);
  }, [selectedCurrency]);

  // Load compensation ranges from Firebase on mount
  useEffect(() => {
    const loadCompensationRanges = async () => {
      if (!user?.orgId) return;

      try {
        const ranges = await getCompensationRanges(user.orgId);
        if (ranges && Object.keys(ranges).length > 0) {
          setCompensationRanges(ranges);
          localStorage.setItem('compensationRanges', JSON.stringify(ranges));
        }
      } catch (error) {
        console.error('Error loading compensation ranges from Firebase:', error);
      }
    };

    loadCompensationRanges();
  }, [user?.orgId]);

  const [locationTiers, setLocationTiers] = useState(() => {
    try {
      const savedTiers = localStorage.getItem('locationTiers');
      return savedTiers ? JSON.parse(savedTiers) : {
        'Tier 1': 1.0,
        'Tier 2': 0.85,
        'Tier 3': 0.75,
        'Tier 4': 0.65,
        'Tier 5': 0.55
      };
    } catch (error) {
      console.warn('Error loading location tiers from localStorage:', error);
      return {
        'Tier 1': 1.0,
        'Tier 2': 0.85,
        'Tier 3': 0.75,
        'Tier 4': 0.65,
        'Tier 5': 0.55
      };
    }
  });

  const [locationTierAssignments, setLocationTierAssignments] = useState(() => {
    try {
      const savedAssignments = localStorage.getItem('locationTierAssignments');
      return savedAssignments ? JSON.parse(savedAssignments) : {};
    } catch (error) {
      console.warn('Error loading location tier assignments from localStorage:', error);
      return {};
    }
  });

  const [availableLocations, setAvailableLocations] = useState([]);

  const normalizeFunctionName = (name) => {
    if (!name) return '';
    return name
      .trim()
      .toLowerCase()
      .replace(/\s+/g, ' ')        // standardize spaces
      .replace(/&/g, 'and')        // standardize ampersands
      .replace(/\./g, '')          // remove periods
      .replace(/-/g, ' ');         // convert hyphens to spaces
  };

  const handleDownloadTemplate = () => {
    const template = {
      'Compensation Ranges': functions.flatMap(func =>
        levels.map(level => ({
          'Function': func,
          'Level': level.id.toUpperCase(),
          'Minimum': '',
          'Mid': '',
          'Maximum': ''
        }))
      )
    };

    const ws = XLSX.utils.json_to_sheet(template['Compensation Ranges']);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Compensation Ranges');
    XLSX.writeFile(wb, 'compensation_template.xlsx');
  };

  const handleImport = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = async (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        console.log('Available columns:', Object.keys(jsonData[0]));

        const newRanges = {};
        const errors = [];

        // In handleImport, replace the entire forEach loop with this:
jsonData.forEach((row, index) => {
    const rawFunctionName = row['Function'];
    const normalizedFunction = normalizeFunctionName(rawFunctionName);
    const levelName = row['Level']?.toLowerCase();
 
  
    // Debug logging for function matching
    console.log(`Row ${index} matching:`, {
      raw: rawFunctionName,
      normalized: normalizedFunction,
      availableFunctions: functions.map(f => ({
        original: f,
        normalized: normalizeFunctionName(f)
      }))
    });
  
    // Skip M-levels if we have an IC equivalent
    if (levelName?.startsWith('m') && jsonData.some(r => 
      r['Function'] === rawFunctionName && 
      r['Level']?.toLowerCase() === levelName.replace('m', 'ic')
    )) {
      return;
    }
  
    const midValue = row['Mid'];
  
    // Flexible function matching with detailed logging
    const matchedFunction = functions.find(f => {
      const normalizedF = normalizeFunctionName(f);
      const isMatch = normalizedF === normalizedFunction;
      console.log(`Comparing "${normalizedF}" with "${normalizedFunction}": ${isMatch}`);
      return isMatch;
    });
  
    const matchedLevel = levels.find(l => 
      l.id.toLowerCase() === levelName
    );
  
    if (matchedFunction && matchedLevel) {
      const key = `${matchedLevel.id}-${matchedFunction}`;
      const rangeData = {
        min: parseFloat(row['Minimum']) || 0,
        mid: parseFloat(midValue) || 0,
        max: parseFloat(row['Maximum']) || 0
      };
      console.log(`Matched data for ${key}:`, rangeData);
      newRanges[key] = rangeData;
    } else {
      errors.push(`Row ${index + 1}: Could not match "${rawFunctionName}" and/or "${levelName}"`);
    }
  });

        if (Object.keys(newRanges).length > 0) {
          console.log('Setting new ranges:', newRanges);
          setCompensationRanges(newRanges);
          localStorage.setItem('compensationRanges', JSON.stringify(newRanges));
          
          // Save to Firebase
          if (user?.orgId) {
            try {
              const rangesWithMetadata = {
                ranges: newRanges,
                currency: 'GBP'  // Since you mentioned it's in GBP
              };
              await updateCompensationRanges(user.orgId, rangesWithMetadata);
              alert(`Import completed. ${Object.keys(newRanges).length} entries imported and saved to Firebase.`);
            } catch (error) {
              console.error('Error saving compensation ranges to Firebase:', error);
              alert('Data imported locally but failed to save to Firebase. Please try again later.');
            }
          } else {
            alert(`Import completed. ${Object.keys(newRanges).length} entries imported.`);
          }
        } else {
          alert('No valid data could be imported. Please check the file format.');
        }

        if (errors.length > 0) {
          console.log('Import errors:', errors.join('\n'));
        }

      } catch (error) {
        console.error('Error importing data:', error);
        alert('Error importing data. Please check the file format and try again.');
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const getUniqueLocations = () => {
    const locations = new Set();
    
    if (employees?.length) {
      employees.forEach(emp => {
        if (emp.location) locations.add(emp.location);
      });
    }
    
    if (scenarios?.length) {
      scenarios.forEach(scenario => {
        scenario.plannedHires?.forEach(hire => {
          if (hire.approvalStatus === 'Completed' && hire.location) {
            locations.add(hire.location);
          }
        });
      });
    }

    Object.keys(locationTierAssignments).forEach(location => {
      locations.add(location);
    });
    
    return Array.from(locations).sort();
  };

  useEffect(() => {
    if (Object.keys(compensationRanges).length > 0) {
      localStorage.setItem('compensationRanges', JSON.stringify(compensationRanges));
    }
  }, [compensationRanges]);

  useEffect(() => {
    if (Object.keys(locationTiers).length > 0) {
      localStorage.setItem('locationTiers', JSON.stringify(locationTiers));
    }
  }, [locationTiers]);

  useEffect(() => {
    if (Object.keys(locationTierAssignments).length > 0) {
      localStorage.setItem('locationTierAssignments', JSON.stringify(locationTierAssignments));
    }
  }, [locationTierAssignments]);

  useEffect(() => {
    const locations = getUniqueLocations();
    if (JSON.stringify(locations) !== JSON.stringify(availableLocations)) {
      setAvailableLocations(locations);
    }
  }, [employees, scenarios, locationTierAssignments]);

  const handleRangeChange = async (level, type, value) => {
    const newRanges = {
      ...compensationRanges,
      [`${level}-${selectedFunction}`]: {
        ...compensationRanges[`${level}-${selectedFunction}`],
        [type]: parseFloat(value) || 0,
        currency: selectedCurrency
      }
    };
    
    setCompensationRanges(newRanges);
    localStorage.setItem('compensationRanges', JSON.stringify(newRanges));
    
    // Save to Firebase
    if (user?.orgId) {
      try {
        await updateCompensationRanges(user.orgId, newRanges);
      } catch (error) {
        console.error('Error saving compensation ranges to Firebase:', error);
      }
    }
    
    // Trigger storage event for other components
    window.dispatchEvent(new Event('storage'));
  };

  // Format number with currency
  const formatCurrency = (value) => {
    if (!value) return '';
    return `${value.toLocaleString()} ${selectedCurrency}`;
  };

  const handleTierChange = (tier, value) => {
    setLocationTiers(prev => ({
      ...prev,
      [tier]: parseFloat(value) || 0
    }));
  };

  const handleTierAssignment = (location, tier) => {
    setLocationTierAssignments(prev => ({
      ...prev,
      [location]: tier
    }));
  };

  const handleCurrencyChange = async (event) => {
    const newCurrency = event.target.value;
    console.log('Currency change requested:', { from: selectedCurrency, to: newCurrency });
    console.log('Current compensation ranges:', compensationRanges);

    setSelectedCurrency(newCurrency);
    localStorage.setItem('compensationCurrency', newCurrency);

    // Ensure we maintain the correct structure
    const updatedRanges = {
      ...compensationRanges,  // Keep all existing data
      currency: newCurrency   // Just update the currency
    };
    console.log('Updated ranges to save:', updatedRanges);

    // Save to Firebase
    if (user?.orgId) {
      try {
        await updateCompensationRanges(user.orgId, updatedRanges);
        setCompensationRanges(updatedRanges);
        console.log('Successfully saved updated ranges with new currency');
      } catch (error) {
        console.error('Error saving currency change:', error);
      }
    }
  };

  // Load compensation ranges from Firebase when component mounts
  useEffect(() => {
    const loadRanges = async () => {
      if (user?.orgId) {
        try {
          const ranges = await getCompensationRanges(user.orgId);
          console.log('Loaded compensation ranges:', ranges);
          setCompensationRanges(ranges || {});
          // Update selected currency if it exists in the ranges
          if (ranges?.currency) {
            console.log('Setting currency from loaded ranges:', ranges.currency);
            setSelectedCurrency(ranges.currency);
            localStorage.setItem('compensationCurrency', ranges.currency);
          }
        } catch (error) {
          console.error('Error loading compensation ranges:', error);
        }
      }
    };
    loadRanges();
  }, [user?.orgId]);

  return (
    <div className="compensation-framework">
      <div className="framework-section">
        <div className="compensation-header">
          <div className="compensation-header-content">
            <h2 className="compensation-header-title">Base Compensation Ranges</h2>
            <div className="compensation-currency-selector">
              <select
                value={selectedCurrency}
                onChange={handleCurrencyChange}
                className="compensation-currency-select"
              >
                {currencies.map(currency => (
                  <option key={currency} value={currency}>{currency}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="compensation-header-actions">
            <input
              type="file"
              id="import-compensation"
              accept=".xlsx,.xls"
              onChange={handleImport}
              style={{ display: 'none' }}
            />
            <button 
              className="compensation-action-button"
              onClick={() => document.getElementById('import-compensation').click()}
            >
              Import Data
            </button>
            <button 
              className="compensation-action-button"
              onClick={handleDownloadTemplate}
            >
              Download Template
            </button>
          </div>
        </div>
        
        <div className="function-tabs-wrapper">
          <div className="function-tabs-container">
            <div className="function-tabs">
              {functions.map(func => (
                <button
                  key={func}
                  className={`function-tab ${selectedFunction === func ? 'active' : ''}`}
                  onClick={() => setSelectedFunction(func)}
                >
                  {func}
                </button>
              ))}
            </div>
          </div>
        </div>

        <div className="compensation-matrix">
          <table>
            <thead>
              <tr>
                <th>Level</th>
                <th>Minimum</th>
                <th>Mid-Point</th>
                <th>Maximum</th>
              </tr>
            </thead>
            <tbody>
              {levels.map(level => (
                <tr key={level.id}>
                  <td>{level.display}</td>
                  <td>
                    <input
                      type="number"
                      value={compensationRanges[`${level.id}-${selectedFunction}`]?.min || ''}
                      onChange={(e) => handleRangeChange(level.id, 'min', e.target.value)}
                      placeholder={`0 ${selectedCurrency}`}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={compensationRanges[`${level.id}-${selectedFunction}`]?.mid || ''}
                      onChange={(e) => handleRangeChange(level.id, 'mid', e.target.value)}
                      placeholder={`0 ${selectedCurrency}`}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={compensationRanges[`${level.id}-${selectedFunction}`]?.max || ''}
                      onChange={(e) => handleRangeChange(level.id, 'max', e.target.value)}
                      placeholder={`0 ${selectedCurrency}`}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="framework-section">
        <h2>Location Tier Multipliers</h2>
        <div className="location-tiers-container">
          <div className="tiers-grid">
            {Object.entries(locationTiers).map(([tier, multiplier]) => (
              <div key={tier} className="tier-row">
                <div className="tier-name">{tier}</div>
                <input
                  type="number"
                  value={multiplier}
                  onChange={(e) => handleTierChange(tier, e.target.value)}
                  step="0.05"
                  min="0"
                  max="1"
                />
              </div>
            ))}
          </div>

          <div className="location-assignments">
            <h3>Location Assignments</h3>
            <div className="location-grid">
              {availableLocations.map(location => (
                <div key={location} className="location-row">
                  <div className="location-name">{location}</div>
                  <select
                    value={locationTierAssignments[location] || ''}
                    onChange={(e) => handleTierAssignment(location, e.target.value)}
                  >
                    <option value="">Select Tier</option>
                    {Object.keys(locationTiers).map(tier => (
                      <option key={tier} value={tier}>
                        {tier} ({(locationTiers[tier] * 100).toFixed()}%)
                      </option>
                    ))}
                  </select>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompensationFramework;