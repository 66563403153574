import React from 'react';
import './ScenarioRoleModal.css';

const ScenarioRoleModal = ({ isOpen, onClose, role, onUpdateRole, onDeleteRole, compensationRanges, exchangeRates, presentationCurrency, employees = [] }) => {
  if (!isOpen || !role) return null;

  // Get unique organizations from employees
  const organizations = Array.from(new Set(employees.map(emp => emp.org).filter(Boolean))).sort();

  // Get potential managers (employees with M, L, or S in their level)
  const potentialManagers = employees
    .filter(emp => {
      const level = (emp.level || '').toLowerCase();
      return level.includes('m') || level.includes('l') || level.includes('s');
    })
    .map(emp => ({
      id: emp.id,
      name: `${emp.firstName} ${emp.surname}`,
      team: emp.team || 'No Team'
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    
    // Format the start date
    const startDate = `${formData.get('preferredStartYear')}-${
      {
        'January': '01', 'February': '02', 'March': '03', 'April': '04',
        'May': '05', 'June': '06', 'July': '07', 'August': '08',
        'September': '09', 'October': '10', 'November': '11', 'December': '12'
      }[formData.get('preferredStartMonth')]
    }-01`;

    // Calculate budget based on function and level
    const key = `${formData.get('level')}-${formData.get('function')}`;
    const range = compensationRanges[key];
    let totalCost = 0;
    
    if (range?.mid) {
      const amount = range.mid;
      const fromCurrency = range.currency || 'USD';
      
      if (fromCurrency === presentationCurrency) {
        totalCost = amount;
      } else {
        const rates = exchangeRates || {
          USD: 1, EUR: 1.1, GBP: 1.27, CHF: 1.12,
          SEK: 0.096, PHP: 0.018, INR: 0.012
        };
        
        if (rates[fromCurrency] && rates[presentationCurrency]) {
          const inUSD = amount * rates[fromCurrency];
          totalCost = inUSD * (1 / rates[presentationCurrency]);
        } else {
          totalCost = amount;
        }
      }
    }

    const updatedRole = {
      ...role,
      roleTitle: formData.get('roleTitle'),
      team: formData.get('team'),
      function: formData.get('function'),
      level: formData.get('level'),
      directManager: formData.get('directManager'),
      preferredStartMonth: formData.get('preferredStartMonth'),
      preferredStartYear: formData.get('preferredStartYear'),
      priority: formData.get('priority'),
      exceptionReason: formData.get('exceptionReason'),
      organization: formData.get('organization'),
      startDate,
      totalCost,
      id: String(role.id).replace('planned-', '')
    };

    onUpdateRole(updatedRole);
    onClose();
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this role? This action cannot be undone.')) {
      onDeleteRole(role.id);
      onClose();
    }
  };

  return (
    <div className="modal-overlay">
      <div className="scenario-role-modal">
        <div className="modal-header">
          <h2>Edit Role</h2>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>

        <form onSubmit={handleSubmit} className="role-edit-form">
          <div className="form-section">
            <div className="form-grid">
              {/* First row */}
              <div className="form-group">
                <label htmlFor="roleTitle">Role Title:</label>
                <input
                  id="roleTitle"
                  name="roleTitle"
                  type="text"
                  defaultValue={role.roleTitle || role.title || ''}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="team">Team:</label>
                <input
                  id="team"
                  name="team"
                  type="text"
                  defaultValue={role.team || ''}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="function">Function:</label>
                <select
                  id="function"
                  name="function"
                  defaultValue={role.function || ''}
                  required
                >
                  <option value="">Select Function</option>
                  <option value="Biz Ops & Strategy">Biz Ops & Strategy</option>
                  <option value="Content">Content</option>
                  <option value="Customer Service">Customer Service</option>
                  <option value="Data Analytics">Data Analytics</option>
                  <option value="Finance">Finance</option>
                  <option value="Graphic Design">Graphic Design</option>
                  <option value="Marketing">Marketing</option>
                  <option value="People">People</option>
                  <option value="Product Design">Product Design</option>
                  <option value="Product Management">Product Management</option>
                  <option value="Sales">Sales</option>
                  <option value="SEO">SEO</option>
                  <option value="Software Engineering">Software Engineering</option>
                  <option value="User Research">User Research</option>
                  <option value="Workplace & Culture">Workplace & Culture</option>
                  <option value="Customer Success">Customer Success</option>
                  <option value="RevOps">RevOps</option>
                  <option value="IT Support">IT Support</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="level">Level:</label>
                <select
                  id="level"
                  name="level"
                  defaultValue={role.level || ''}
                  required
                >
                  <option value="">Select Level</option>
                  <option value="ic1">IC1</option>
                  <option value="ic2">IC2</option>
                  <option value="ic3">IC3 / M3</option>
                  <option value="ic4">IC4 / M4</option>
                  <option value="ic5">IC5 / M5</option>
                  <option value="ic6">IC6 / M6</option>
                  <option value="l1">L1</option>
                  <option value="l2">L2</option>
                  <option value="l3">L3</option>
                  <option value="s1">S1</option>
                  <option value="s2">S2</option>
                  <option value="s3">S3</option>
                  <option value="s4">S4</option>
                  <option value="s5">S5</option>
                </select>
              </div>

              {/* Second row */}
              <div className="form-group">
                <label htmlFor="directManager">Direct Manager:</label>
                <select
                  id="directManager"
                  name="directManager"
                  defaultValue={role.directManager || ''}
                  required
                >
                  <option value="">Select Manager</option>
                  {potentialManagers.map(manager => (
                    <option key={manager.id} value={manager.name}>
                      {manager.name} ({manager.team})
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="preferredStartMonth">Start Month:</label>
                <select
                  id="preferredStartMonth"
                  name="preferredStartMonth"
                  defaultValue={role.preferredStartMonth || ''}
                  required
                >
                  <option value="">Select Month</option>
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="preferredStartYear">Start Year:</label>
                <select
                  id="preferredStartYear"
                  name="preferredStartYear"
                  defaultValue={role.preferredStartYear || ''}
                  required
                >
                  <option value="">Select Year</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="priority">Priority:</label>
                <select
                  id="priority"
                  name="priority"
                  defaultValue={role.priority || 'Medium'}
                  required
                >
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </select>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="organization">Organization:</label>
              <select
                id="organization"
                name="organization"
                defaultValue={role.organization || ''}
                required
              >
                <option value="">Select Organization</option>
                {organizations.map(org => (
                  <option key={org} value={org}>{org}</option>
                ))}
              </select>
            </div>

            {role.scenarioType === 'Exceptions' && (
              <div className="form-group">
                <label htmlFor="exceptionReason">Exception Reason:</label>
                <textarea
                  id="exceptionReason"
                  name="exceptionReason"
                  defaultValue={role.exceptionReason || ''}
                  placeholder="Enter reason for any exceptions..."
                  required
                />
              </div>
            )}
          </div>

          <div className="modal-footer">
            <button type="button" className="delete-button" onClick={handleDelete}>Delete Role</button>
            <div className="right-buttons">
              <button type="button" className="cancel-button" onClick={onClose}>Cancel</button>
              <button type="submit" className="save-button">Save Changes</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ScenarioRoleModal; 