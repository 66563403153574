import React, { useState, useEffect } from 'react';
import './EditEmployeeModal.css';

function EditEmployeeModal({ employee, isOpen, closeModal, onEditEmployee, onDeleteEmployee, existingEmployees, organizationOptions, contractTypeOptions, teamOptions }) {
  // Helper function to normalize contract type
  const normalizeContractType = (contractType) => {
    if (!contractType) return '';
    // Find matching contract type ignoring case
    const matchingType = contractTypeOptions?.find(type => 
      type.toLowerCase() === contractType.toLowerCase() ||
      type.toLowerCase().replace(/\s+/g, '') === contractType.toLowerCase().replace(/\s+/g, '')
    );
    return matchingType || contractType;
  };

  // Initialize form data with employee data or empty values
  const getInitialFormData = () => {
    if (!employee) {
      return {
        firstName: '',
        surname: '',
        title: '',
        org: '',
        team: '',
        level: '',
        location: '',
        directManager: '',
        startDate: '',
        endDate: '',
        contractType: '',
        baseSalary: 0,
        currency: 'USD',
        stipend: 0,
        employeeTax: 0,
        nationalInsurance: 0,
        commission: 0,
        platformFees: 0,
        otherRecurringPayments: 0,
        employeeId: '',
        jobId: ''
      };
    }

    return {
      firstName: employee.firstName || '',
      surname: employee.surname || '',
      title: employee.title || '',
      org: employee.org || '',
      team: employee.team || '',
      level: employee.level || '',
      location: employee.location || '',
      directManager: employee.directManager || '',
      startDate: employee.startDate || '',
      endDate: employee.endDate || '',
      contractType: normalizeContractType(employee.contractType),
      baseSalary: employee.baseSalary || 0,
      currency: employee.currency || 'USD',
      stipend: employee.stipend || 0,
      employeeTax: employee.employeeTax || 0,
      nationalInsurance: employee.nationalInsurance || 0,
      commission: employee.commission || 0,
      platformFees: employee.platformFees || 0,
      otherRecurringPayments: employee.otherRecurringPayments || 0,
      employeeId: employee.employeeId || '',
      jobId: employee.jobId || ''
    };
  };

  const [formData, setFormData] = useState(getInitialFormData());

  // Update form data when employee changes
  useEffect(() => {
    if (!employee) return;
    setFormData(getInitialFormData());
  }, [employee]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: name.includes('Salary') || name.includes('stipend') || 
               name.includes('Tax') || name.includes('Insurance') || 
               name.includes('commission') || name.includes('Fees') || 
               name.includes('Payments')
        ? Number(value)
        : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!employee) return;
    onEditEmployee({ id: employee.id, ...formData });
    closeModal();
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this employee? This action cannot be undone.')) {
      onDeleteEmployee(employee.id);
      closeModal();
    }
  };

  if (!isOpen || !employee) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-panel">
        <h2 className="modal-title">Edit Employee</h2>
        <form onSubmit={handleSubmit} className="modal-form">
          <div className="form-grid">
            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="surname">Surname</label>
              <input
                type="text"
                id="surname"
                name="surname"
                value={formData.surname}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="org">Organization</label>
              <select
                id="org"
                name="org"
                value={formData.org}
                onChange={handleChange}
                required
              >
                <option value="">Select Organization</option>
                {organizationOptions?.map(org => (
                  <option key={org} value={org}>{org}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="team">Team</label>
              <div className="combobox-wrapper">
                <input
                  type="text"
                  list="team-options"
                  id="team"
                  name="team"
                  value={formData.team}
                  onChange={handleChange}
                  required
                  placeholder="Select or enter team name"
                />
                <datalist id="team-options">
                  {teamOptions?.map(team => (
                    <option key={team} value={team} />
                  ))}
                </datalist>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="level">Level</label>
              <input
                type="text"
                id="level"
                name="level"
                value={formData.level}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="location">Location</label>
              <div className="combobox-wrapper">
                <input
                  type="text"
                  list="location-options"
                  id="location"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  required
                  placeholder="Select or enter location"
                />
                <datalist id="location-options">
                  {[...new Set(existingEmployees.map(emp => emp.location))].map(location => (
                    <option key={location} value={location} />
                  ))}
                </datalist>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="directManager">Direct Manager</label>
              <select
                id="directManager"
                name="directManager"
                value={formData.directManager}
                onChange={handleChange}
                required
              >
                <option value="">Select Manager</option>
                {existingEmployees?.map(emp => (
                  <option key={emp.id} value={`${emp.firstName} ${emp.surname}`}>
                    {`${emp.firstName} ${emp.surname}`}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="startDate">Start Date</label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="endDate">End Date (Optional)</label>
              <input
                type="date"
                id="endDate"
                name="endDate"
                value={formData.endDate}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="contractType">Contract Type</label>
              <select
                id="contractType"
                name="contractType"
                value={normalizeContractType(formData.contractType)}
                onChange={handleChange}
                required
              >
                <option value="">Select Contract Type</option>
                {contractTypeOptions?.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="baseSalary">Base Salary</label>
              <input
                type="number"
                id="baseSalary"
                name="baseSalary"
                value={formData.baseSalary}
                onChange={handleChange}
                required
                min="0"
              />
            </div>

            <div className="form-group">
              <label htmlFor="currency">Currency</label>
              <select
                id="currency"
                name="currency"
                value={formData.currency}
                onChange={handleChange}
                required
              >
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="stipend">Stipend</label>
              <input
                type="number"
                id="stipend"
                name="stipend"
                value={formData.stipend}
                onChange={handleChange}
                min="0"
              />
            </div>

            <div className="form-group">
              <label htmlFor="employeeTax">Employee Tax</label>
              <input
                type="number"
                id="employeeTax"
                name="employeeTax"
                value={formData.employeeTax}
                onChange={handleChange}
                min="0"
              />
            </div>

            <div className="form-group">
              <label htmlFor="nationalInsurance">National Insurance</label>
              <input
                type="number"
                id="nationalInsurance"
                name="nationalInsurance"
                value={formData.nationalInsurance}
                onChange={handleChange}
                min="0"
              />
            </div>

            <div className="form-group">
              <label htmlFor="commission">Commission</label>
              <input
                type="number"
                id="commission"
                name="commission"
                value={formData.commission}
                onChange={handleChange}
                min="0"
              />
            </div>

            <div className="form-group">
              <label htmlFor="platformFees">Platform Fees</label>
              <input
                type="number"
                id="platformFees"
                name="platformFees"
                value={formData.platformFees}
                onChange={handleChange}
                min="0"
              />
            </div>

            <div className="form-group">
              <label htmlFor="otherRecurringPayments">Other Recurring Payments</label>
              <input
                type="number"
                id="otherRecurringPayments"
                name="otherRecurringPayments"
                value={formData.otherRecurringPayments}
                onChange={handleChange}
                min="0"
              />
            </div>

            <div className="form-group">
              <label htmlFor="employeeId">Employee ID</label>
              <input
                type="text"
                id="employeeId"
                name="employeeId"
                value={formData.employeeId}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="jobId">Job ID</label>
              <input
                type="text"
                id="jobId"
                name="jobId"
                value={formData.jobId}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="modal-actions">
            <button 
              type="button"
              className="delete-button"
              onClick={handleDelete}
            >
              Delete Employee
            </button>
            <div className="right-buttons">
              <button 
                type="button" 
                className="cancel-button" 
                onClick={closeModal}
              >
                Cancel
              </button>
              <button 
                type="submit" 
                className="submit-button"
              >
                Save Changes
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditEmployeeModal;