import React, { useState } from 'react';
import './TATeamModal.css';

function TATeamModal({ isOpen, onClose }) {
  const [activeTab, setActiveTab] = useState('team'); // 'team' or 'stages'
  const [teamMembers, setTeamMembers] = useState(() => {
    const saved = localStorage.getItem('taTeamMembers');
    return saved ? JSON.parse(saved) : [];
  });
  const [stages, setStages] = useState(() => {
    const saved = localStorage.getItem('recruitmentStages');
    return saved ? JSON.parse(saved) : [
      { id: '1', name: 'Not Started', order: 0, isDefault: true },
      { id: '2', name: 'Job Description', order: 1 },
      { id: '3', name: 'Sourcing', order: 2 },
      { id: '4', name: 'Interviewing', order: 3 },
      { id: '5', name: 'Offer Stage', order: 4 },
      { id: '6', name: 'Completed', order: 5, isDefault: true },
      { id: '7', name: 'Cancelled', order: 6, isDefault: true }
    ];
  });
  const [newMember, setNewMember] = useState({
    name: '',
    email: '',
    role: 'Recruiter',
    active: true
  });
  const [newStage, setNewStage] = useState('');

  const handleAddMember = (e) => {
    e.preventDefault();
    if (!newMember.name || !newMember.email) return;

    const updatedMembers = [...teamMembers, {
      ...newMember,
      id: Date.now().toString(),
      currentLoad: 0
    }];
    
    setTeamMembers(updatedMembers);
    localStorage.setItem('taTeamMembers', JSON.stringify(updatedMembers));
    
    setNewMember({
      name: '',
      email: '',
      role: 'Recruiter',
      active: true
    });
  };

  const handleAddStage = (e) => {
    e.preventDefault();
    if (!newStage) return;

    const updatedStages = [...stages, {
      id: Date.now().toString(),
      name: newStage,
      order: stages.length,
      isDefault: false
    }];
    
    setStages(updatedStages);
    localStorage.setItem('recruitmentStages', JSON.stringify(updatedStages));
    setNewStage('');
  };

  const handleDeleteStage = (stageId) => {
    const updatedStages = stages
      .filter(stage => stage.id !== stageId)
      .map((stage, index) => ({ ...stage, order: index }));
    
    setStages(updatedStages);
    localStorage.setItem('recruitmentStages', JSON.stringify(updatedStages));
  };

  const handleMoveStage = (stageId, direction) => {
    const currentIndex = stages.findIndex(s => s.id === stageId);
    if (
      (direction === 'up' && currentIndex === 0) || 
      (direction === 'down' && currentIndex === stages.length - 1)
    ) return;

    const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
    const updatedStages = [...stages];
    [updatedStages[currentIndex], updatedStages[newIndex]] = 
    [updatedStages[newIndex], updatedStages[currentIndex]];

    const reorderedStages = updatedStages.map((stage, index) => ({
      ...stage,
      order: index
    }));

    setStages(reorderedStages);
    localStorage.setItem('recruitmentStages', JSON.stringify(reorderedStages));
  };

  const toggleMemberStatus = (memberId) => {
    const updatedMembers = teamMembers.map(member =>
      member.id === memberId 
        ? { ...member, active: !member.active }
        : member
    );
    setTeamMembers(updatedMembers);
    localStorage.setItem('taTeamMembers', JSON.stringify(updatedMembers));
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="ta-team-modal">
        <div className="modal-header">
          <h2>TA Settings</h2>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>

        <div className="settings-tabs">
          <button 
            className={`tab-button ${activeTab === 'team' ? 'active' : ''}`}
            onClick={() => setActiveTab('team')}
          >
            Team Members
          </button>
          <button 
            className={`tab-button ${activeTab === 'stages' ? 'active' : ''}`}
            onClick={() => setActiveTab('stages')}
          >
            Recruitment Stages
          </button>
        </div>

        <div className="modal-content">
          {activeTab === 'team' ? (
            <>
              <form onSubmit={handleAddMember} className="add-member-form">
                <div className="form-group">
                  <label>Name:</label>
                  <input
                    type="text"
                    value={newMember.name}
                    onChange={(e) => setNewMember({ ...newMember, name: e.target.value })}
                    placeholder="Enter name"
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    value={newMember.email}
                    onChange={(e) => setNewMember({ ...newMember, email: e.target.value })}
                    placeholder="Enter email"
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Role:</label>
                  <select
                    value={newMember.role}
                    onChange={(e) => setNewMember({ ...newMember, role: e.target.value })}
                  >
                    <option value="Recruiter">Recruiter</option>
                    <option value="Senior Recruiter">Senior Recruiter</option>
                    <option value="TA Manager">TA Manager</option>
                  </select>
                </div>

                <button type="submit" className="add-button">Add Team Member</button>
              </form>

              <div className="team-members-list">
                <h3>Current Team Members</h3>
                {teamMembers.length === 0 ? (
                  <p className="no-members">No team members added yet</p>
                ) : (
                  <div className="members-grid">
                    {teamMembers.map(member => (
                      <div key={member.id} className={`member-card ${!member.active ? 'inactive' : ''}`}>
                        <div className="member-info">
                          <h4>{member.name}</h4>
                          <p>{member.email}</p>
                          <p className="member-role">{member.role}</p>
                          <p className="member-load">Current Load: {member.currentLoad} roles</p>
                        </div>
                        <div className="member-actions">
                          <button
                            onClick={() => toggleMemberStatus(member.id)}
                            className={member.active ? 'deactivate-button' : 'activate-button'}
                          >
                            {member.active ? 'Deactivate' : 'Activate'}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="stages-section">
              <form onSubmit={handleAddStage} className="add-stage-form">
                <div className="form-group">
                  <label>New Stage Name:</label>
                  <input
                    type="text"
                    value={newStage}
                    onChange={(e) => setNewStage(e.target.value)}
                    placeholder="Enter stage name"
                    required
                  />
                </div>
                <button type="submit" className="add-button">Add Stage</button>
              </form>

              <div className="stages-list">
                <h3>Current Stages</h3>
                {stages.map((stage, index) => (
                  <div key={stage.id} className="stage-item">
                    <span className="stage-name">{stage.name}</span>
                    <div className="stage-actions">
                      {!stage.isDefault && (
                        <button 
                          onClick={() => handleDeleteStage(stage.id)}
                          className="delete-button"
                        >
                          Delete
                        </button>
                      )}
                      <button
                        onClick={() => handleMoveStage(stage.id, 'up')}
                        disabled={index === 0}
                        className="move-button"
                      >
                        ↑
                      </button>
                      <button
                        onClick={() => handleMoveStage(stage.id, 'down')}
                        disabled={index === stages.length - 1}
                        className="move-button"
                      >
                        ↓
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TATeamModal;