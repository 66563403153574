import { initializeApp } from 'firebase/app';
import { getAuth, browserLocalPersistence, setPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Log environment variables (without exposing actual values)
console.log('Environment check:', {
  NODE_ENV: process.env.NODE_ENV,
  hasEnvVars: !!process.env.REACT_APP_FIREBASE_API_KEY,
  envVarLength: process.env.REACT_APP_FIREBASE_API_KEY?.length || 0
});

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Log config shape (without values)
console.log('Config shape check:', {
  hasApiKey: !!firebaseConfig.apiKey,
  hasAuthDomain: !!firebaseConfig.authDomain,
  hasProjectId: !!firebaseConfig.projectId,
  hasStorageBucket: !!firebaseConfig.storageBucket,
  hasMessagingSenderId: !!firebaseConfig.messagingSenderId,
  hasAppId: !!firebaseConfig.appId
});

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// Configure auth persistence
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log('Firebase persistence configured');
  })
  .catch((error) => {
    console.error('Error setting persistence:', error);
  });

export const db = getFirestore(app);

export default app; 