import React, { useState, useEffect } from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import { collection, doc, getDocs, setDoc, getDoc, getFirestore } from 'firebase/firestore';
import { useFirebase } from '../../contexts/FirebaseContext';
import { db } from '../../config/firebase';
import CommissionStructureModal from './CommissionStructureModal';
import './Commission.css';

const Commission = ({ employees = [] }) => {
    const { user } = useFirebase();
    const [expandedPerson, setExpandedPerson] = useState(null);
    const [commissionData, setCommissionData] = useState({});
    const [currentYear] = useState(new Date().getFullYear());
    const [isLoading, setIsLoading] = useState(true);
    const [isStructureModalOpen, setIsStructureModalOpen] = useState(false);
    const [commissionStructures, setCommissionStructures] = useState({});
    const [showAddQuarterModal, setShowAddQuarterModal] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);

    // Filter sales team members
    const salesTeamMembers = employees.filter(emp => emp.org === 'Sales');

    // Initialize commission data for a new person
    const initializePersonData = (employeeId) => ({
        quarters: {},
        yearlyTarget: 0,
        yearlyMaxCommission: 0,
        role: 'Sales Representative' // Default role
    });

    // Load commission data and structures from Firebase
    useEffect(() => {
        const loadData = async () => {
            if (!user?.orgId) return;
            
            try {
                // Load commission data
                const commissionRef = collection(db, 'organizations', user.orgId, 'commission');
                const commissionSnapshot = await getDocs(commissionRef);
                const loadedData = {};
                commissionSnapshot.forEach(doc => {
                    loadedData[doc.id] = doc.data();
                });
                setCommissionData(loadedData);

                // Load commission structures
                const structuresRef = collection(db, 'organizations', user.orgId, 'commission_structures');
                const structuresSnap = await getDocs(structuresRef);
                const loadedStructures = {};
                structuresSnap.forEach(doc => {
                    loadedStructures[doc.id] = doc.data();
                });
                setCommissionStructures(loadedStructures);
            } catch (error) {
                console.error('Error loading commission data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        loadData();
    }, [db, user?.orgId]);

    // Save commission data to Firebase
    const saveCommissionData = async (employeeId, data) => {
        if (!user?.orgId) return;
        
        try {
            const commissionRef = doc(db, 'organizations', user.orgId, 'commission', employeeId);
            await setDoc(commissionRef, data);
        } catch (error) {
            console.error('Error saving commission data:', error);
        }
    };

    // Save commission structure to Firebase
    const saveCommissionStructure = async (role, structure) => {
        if (!user?.orgId) return;
        
        try {
            const structureRef = doc(db, 'organizations', user.orgId, 'commission_structures', role);
            await setDoc(structureRef, structure);
            setCommissionStructures({
                ...commissionStructures,
                [role]: structure
            });
        } catch (error) {
            console.error('Error saving commission structure:', error);
            throw error; // Re-throw to handle in the UI
        }
    };

    // Add Quarter Modal
    const AddQuarterModal = ({ isOpen, onClose, onAdd }) => {
        const [selectedQuarter, setSelectedQuarter] = useState('1');
        const [selectedYear, setSelectedYear] = useState(currentYear);
        
        if (!isOpen) return null;

        // Generate year options (current year and next year)
        const yearOptions = [currentYear, currentYear + 1];

        return (
            <div className="modal-overlay">
                <div className="add-quarter-modal">
                    <div className="modal-header">
                        <h2>Add Quarter</h2>
                        <button className="close-button" onClick={onClose}>×</button>
                    </div>
                    <div className="modal-content">
                        <div className="year-quarter-selectors">
                            <div className="form-group">
                                <label>Year</label>
                                <select 
                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                                >
                                    {yearOptions.map(year => (
                                        <option key={year} value={year}>{year}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Quarter</label>
                                <select 
                                    value={selectedQuarter}
                                    onChange={(e) => setSelectedQuarter(e.target.value)}
                                >
                                    <option value="1">Q1</option>
                                    <option value="2">Q2</option>
                                    <option value="3">Q3</option>
                                    <option value="4">Q4</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="cancel-button" onClick={onClose}>Cancel</button>
                        <button 
                            className="save-button"
                            onClick={() => {
                                onAdd(selectedQuarter, selectedYear);
                                onClose();
                            }}
                        >
                            Add Quarter
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    // Handle adding a new quarter
    const handleAddQuarter = async (employeeId, quarter, year) => {
        const personData = commissionData[employeeId] || initializePersonData(employeeId);
        const quarterKey = `${year}-${quarter}`;
        
        if (personData.quarters[quarterKey]) {
            alert(`Quarter ${quarter} ${year} already exists for this employee.`);
            return;
        }

        const updatedData = {
            ...personData,
            quarters: {
                ...personData.quarters,
                [quarterKey]: {
                    target: 0,
                    maxCommission: 0,
                    year: year,
                    quarter: quarter,
                    attainment: {
                        [((quarter - 1) * 3) + 1]: 0,
                        [((quarter - 1) * 3) + 2]: 0,
                        [((quarter - 1) * 3) + 3]: 0
                    }
                }
            }
        };

        setCommissionData({
            ...commissionData,
            [employeeId]: updatedData
        });
        await saveCommissionData(employeeId, updatedData);
    };

    // Handle deleting a quarter
    const handleDeleteQuarter = async (employeeId, quarterKey) => {
        if (!window.confirm(`Are you sure you want to delete Q${quarterKey}?`)) return;

        const personData = commissionData[employeeId];
        const { [quarterKey]: deletedQuarter, ...remainingQuarters } = personData.quarters;

        const updatedData = {
            ...personData,
            quarters: remainingQuarters,
            yearlyTarget: Object.values(remainingQuarters).reduce((sum, q) => sum + (q.target || 0), 0),
            yearlyMaxCommission: Object.values(remainingQuarters).reduce((sum, q) => sum + (q.maxCommission || 0), 0)
        };

        setCommissionData({
            ...commissionData,
            [employeeId]: updatedData
        });
        await saveCommissionData(employeeId, updatedData);
    };

    // Handle role update
    const handleRoleUpdate = async (employeeId, role) => {
        const personData = commissionData[employeeId] || initializePersonData(employeeId);
        const updatedData = {
            ...personData,
            role
        };

        setCommissionData({
            ...commissionData,
            [employeeId]: updatedData
        });
        await saveCommissionData(employeeId, updatedData);
    };

    // Calculate commission payout based on attainment percentage and structure
    const calculateCommissionPayout = (attainmentPercentage, maxCommission, role) => {
        const structure = commissionStructures[role];
        if (!structure || !maxCommission) return 0;

        const { thresholds, defaultPayout } = structure;
        const sortedThresholds = [...thresholds].sort((a, b) => a.threshold - b.threshold);

        // Find the applicable threshold
        const threshold = sortedThresholds.reduce((acc, curr) => {
            if (attainmentPercentage >= curr.threshold) return curr;
            return acc;
        }, { threshold: 0, payout: defaultPayout });

        return (maxCommission * threshold.payout) / 100;
    };

    // Calculate total attainment for a quarter
    const calculateQuarterlyAttainment = (attainment) => {
        return Object.values(attainment).reduce((sum, value) => sum + value, 0);
    };

    // Calculate attainment percentage
    const calculateAttainmentPercentage = (attainment, target) => {
        if (!target) return 0;
        return (attainment / target) * 100;
    };

    // Handle target update
    const handleTargetUpdate = async (employeeId, quarterKey, field, value) => {
        const updatedData = {
            ...commissionData,
            [employeeId]: {
                ...(commissionData[employeeId] || initializePersonData(employeeId)),
                quarters: {
                    ...(commissionData[employeeId]?.quarters || {}),
                    [quarterKey]: {
                        ...(commissionData[employeeId]?.quarters[quarterKey] || {}),
                        [field]: parseFloat(value) || 0
                    }
                }
            }
        };

        // Update yearly totals
        if (field === 'target') {
            updatedData[employeeId].yearlyTarget = Object.values(updatedData[employeeId].quarters)
                .reduce((sum, q) => sum + (q.target || 0), 0);
        } else if (field === 'maxCommission') {
            updatedData[employeeId].yearlyMaxCommission = Object.values(updatedData[employeeId].quarters)
                .reduce((sum, q) => sum + (q.maxCommission || 0), 0);
        }

        setCommissionData(updatedData);
        await saveCommissionData(employeeId, updatedData[employeeId]);
    };

    // Handle attainment update
    const handleAttainmentUpdate = async (employeeId, quarterKey, month, value) => {
        const updatedData = {
            ...commissionData,
            [employeeId]: {
                ...(commissionData[employeeId] || initializePersonData(employeeId)),
                quarters: {
                    ...(commissionData[employeeId]?.quarters || {}),
                    [quarterKey]: {
                        ...(commissionData[employeeId]?.quarters[quarterKey] || {}),
                        attainment: {
                            ...(commissionData[employeeId]?.quarters[quarterKey]?.attainment || {}),
                            [month]: parseFloat(value) || 0
                        }
                    }
                }
            }
        };

        setCommissionData(updatedData);
        await saveCommissionData(employeeId, updatedData[employeeId]);
    };

    // Render the quarterly targets and attainment form
    const renderQuarterForm = (employeeId, quarterKey) => {
        const personData = commissionData[employeeId] || initializePersonData(employeeId);
        const quarterData = personData.quarters[quarterKey] || { target: 0, maxCommission: 0, attainment: {} };
        const [year, quarter] = quarterKey.split('-').map(Number);
        const monthsInQuarter = {
            1: [1, 2, 3],
            2: [4, 5, 6],
            3: [7, 8, 9],
            4: [10, 11, 12]
        };
        
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        const totalAttainment = calculateQuarterlyAttainment(quarterData.attainment);
        const attainmentPercentage = calculateAttainmentPercentage(totalAttainment, quarterData.target);
        const commissionPayout = calculateCommissionPayout(attainmentPercentage, quarterData.maxCommission, personData.role);

        return (
            <div className="quarter-form">
                <div className="quarter-header">
                    <h3>Q{quarter} {year}</h3>
                    <button 
                        className="delete-quarter-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteQuarter(employeeId, quarterKey);
                        }}
                    >
                        Delete Quarter
                    </button>
                </div>

                <div className="commission-form">
                    <div className="form-group">
                        <label>Quarterly Target ($)</label>
                        <input
                            type="number"
                            value={quarterData.target || 0}
                            onChange={(e) => handleTargetUpdate(employeeId, quarterKey, 'target', e.target.value)}
                            min="0"
                        />
                    </div>
                    <div className="form-group">
                        <label>Maximum Commission ($)</label>
                        <input
                            type="number"
                            value={quarterData.maxCommission || 0}
                            onChange={(e) => handleTargetUpdate(employeeId, quarterKey, 'maxCommission', e.target.value)}
                            min="0"
                        />
                    </div>
                </div>

                <div className="monthly-attainment">
                    <h4>Monthly Attainment</h4>
                    <div className="attainment-grid">
                        {monthsInQuarter[quarter].map(month => (
                            <div key={month} className="month-input">
                                <label>{monthNames[month - 1]}</label>
                                <input
                                    type="number"
                                    value={quarterData.attainment[month] || 0}
                                    onChange={(e) => handleAttainmentUpdate(employeeId, quarterKey, month, e.target.value)}
                                    min="0"
                                />
                            </div>
                        ))}
                    </div>
                </div>

                <div className="quarter-summary">
                    <div className="progress-bar">
                        <div 
                            className={`progress-fill ${
                                attainmentPercentage >= 100 ? 'success' :
                                attainmentPercentage >= 80 ? 'warning' : 'error'
                            }`}
                            style={{ width: `${Math.min(attainmentPercentage, 100)}%` }}
                        />
                    </div>
                    <div className="summary-stats">
                        <div className="stat-item">
                            <label>Total Attainment</label>
                            <span>${totalAttainment.toLocaleString()}</span>
                        </div>
                        <div className="stat-item">
                            <label>Attainment %</label>
                            <span>{attainmentPercentage.toFixed(1)}%</span>
                        </div>
                        <div className="stat-item">
                            <label>Commission Payout</label>
                            <span>${commissionPayout.toLocaleString()}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    if (isLoading) {
        return (
            <div className="commission-view">
                <div className="commission-header">
                    <h2>Sales Commission Tracking</h2>
                </div>
                <div className="loading-message">Loading commission data...</div>
            </div>
        );
    }

    return (
        <div className="commission-view">
            <div className="commission-header">
                <h2>Sales Commission Tracking</h2>
                <button 
                    className="structure-button"
                    onClick={() => setIsStructureModalOpen(true)}
                >
                    Set Commission Structure
                </button>
            </div>

            {salesTeamMembers.length === 0 ? (
                <div className="no-sales-members">
                    No sales team members found. Add employees to the Sales organization to track their commission.
                </div>
            ) : (
                salesTeamMembers.map(employee => {
                    const personData = commissionData[employee.id] || initializePersonData(employee.id);
                    const isExpanded = expandedPerson === employee.id;

                    // Calculate yearly totals
                    const yearlyAttainment = Object.values(personData.quarters).reduce((sum, quarter) => 
                        sum + calculateQuarterlyAttainment(quarter.attainment), 0);
                    const yearlyAttainmentPercentage = calculateAttainmentPercentage(yearlyAttainment, personData.yearlyTarget);
                    const yearlyPayout = Object.values(personData.quarters).reduce((sum, quarter) => {
                        const quarterlyAttainment = calculateQuarterlyAttainment(quarter.attainment);
                        const quarterlyPercentage = calculateAttainmentPercentage(quarterlyAttainment, quarter.target);
                        return sum + calculateCommissionPayout(quarterlyPercentage, quarter.maxCommission, personData.role);
                    }, 0);

                    return (
                        <div key={employee.id} className="sales-person-section">
                            <div 
                                className="sales-person-header"
                                onClick={() => setExpandedPerson(isExpanded ? null : employee.id)}
                            >
                                <div className="sales-person-info">
                                    <h4>{employee.firstName} {employee.surname}</h4>
                                    <div className="sales-person-stats">
                                        <span className="stat-badge">
                                            Role: 
                                            <select
                                                value={personData.role}
                                                onChange={(e) => {
                                                    e.stopPropagation();
                                                    handleRoleUpdate(employee.id, e.target.value);
                                                }}
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                {Object.keys(commissionStructures).map(role => (
                                                    <option key={role} value={role}>{role}</option>
                                                ))}
                                            </select>
                                        </span>
                                        <span className="stat-badge">
                                            YTD Target: ${personData.yearlyTarget.toLocaleString()}
                                        </span>
                                        <span className="stat-badge">
                                            YTD Attainment: {yearlyAttainmentPercentage.toFixed(1)}%
                                        </span>
                                        <span className="stat-badge">
                                            YTD Payout: ${yearlyPayout.toLocaleString()}
                                        </span>
                                    </div>
                                </div>
                                <button className="expand-button">
                                    {isExpanded ? '▼' : '►'}
                                </button>
                            </div>

                            {isExpanded && (
                                <div className="sales-person-content">
                                    <div className="quarters-header">
                                        <h3>Quarters</h3>
                                        <button 
                                            className="add-quarter-button"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setSelectedEmployee(employee.id);
                                                setShowAddQuarterModal(true);
                                            }}
                                        >
                                            Add Quarter
                                        </button>
                                    </div>
                                    {Object.keys(personData.quarters).length === 0 ? (
                                        <div className="no-quarters-message">
                                            No quarters configured. Click "Add Quarter" to start tracking commission.
                                        </div>
                                    ) : (
                                        Object.entries(personData.quarters).map(([quarterKey, _]) => (
                                            <div key={quarterKey} className="quarter-section">
                                                {renderQuarterForm(employee.id, quarterKey)}
                                            </div>
                                        ))
                                    )}
                                </div>
                            )}
                        </div>
                    );
                })
            )}

            <CommissionStructureModal
                isOpen={isStructureModalOpen}
                onClose={() => setIsStructureModalOpen(false)}
                onSave={saveCommissionStructure}
                currentStructures={commissionStructures}
            />

            <AddQuarterModal
                isOpen={showAddQuarterModal}
                onClose={() => setShowAddQuarterModal(false)}
                onAdd={(quarter, year) => {
                    handleAddQuarter(selectedEmployee, quarter, year);
                    setShowAddQuarterModal(false);
                }}
            />
        </div>
    );
};

export default Commission; 