import React, { useState, useEffect } from 'react';
import './ExchangeRateModal.css';
import { updateExchangeRates } from '../../services/firestore';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';

function ExchangeRateModal({ 
  isOpen, 
  onClose, 
  rates, 
  onRateChange,
  presentationCurrency,
  onPresentationCurrencyChange
}) {
  const [localRates, setLocalRates] = useState({...rates});

  useEffect(() => {
    console.log('Rates prop changed:', rates);
    setLocalRates({...rates});
  }, [rates]);

  if (!isOpen) return null;

  const handleRateChange = (currency, value) => {
    const numValue = value === '' ? '' : parseFloat(value);
    console.log('Handling rate change:', { currency, value, numValue });
    
    // Only update local state while typing
    setLocalRates(prev => ({
      ...prev,
      [currency]: numValue
    }));
  };

  const handlePresentationCurrencyChange = (newCurrency) => {
    console.log('Changing presentation currency to:', newCurrency);
    
    // First, convert all rates to be relative to the new presentation currency
    const oldPresentationRate = localRates[newCurrency];
    const newRates = {};

    Object.entries(localRates).forEach(([currency, rate]) => {
      if (currency === newCurrency) {
        // The new presentation currency's rate becomes 1
        newRates[currency] = 1;
      } else {
        // Convert other rates relative to the new presentation currency
        const convertedRate = rate / oldPresentationRate;
        newRates[currency] = Number(convertedRate.toFixed(6)); // Limit decimal places
      }
    });

    console.log('New rates after currency change:', newRates);
    setLocalRates(newRates);
    onPresentationCurrencyChange(newCurrency);
  };

  const handleSave = async () => {
    try {
      // Validate all rates are numbers and positive
      const hasErrors = Object.entries(localRates).some(([currency, rate]) => {
        const isInvalid = isNaN(rate) || rate <= 0;
        if (isInvalid) {
          console.log('Invalid rate found:', { currency, rate });
        }
        return isInvalid;
      });

      if (hasErrors) {
        alert('All rates must be positive numbers');
        return;
      }

      // Update Firebase
      const ratesRef = doc(db, 'exchangeRates', 'default');
      await updateDoc(ratesRef, {
        rates: localRates
      });

      // Update parent state with all rates at once
      onRateChange(localRates);
      onClose();
    } catch (error) {
      console.error('Error saving rates:', error);
      alert('Failed to save exchange rates. Please try again.');
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-panel">
        <h2 className="modal-title">Edit Exchange Rates</h2>

        <div className="presentation-currency">
          <label htmlFor="presentationCurrency">Presentation Currency</label>
          <select
            id="presentationCurrency"
            value={presentationCurrency}
            onChange={(e) => handlePresentationCurrencyChange(e.target.value)}
            className="currency-select"
          >
            {Object.keys(localRates).map(currency => (
              <option key={currency} value={currency}>
                {currency}
              </option>
            ))}
          </select>
          <p className="helper-text">
            All values will be converted to and displayed in this currency
          </p>
        </div>

        <div className="rates-grid">
          {Object.entries(localRates).map(([currency, rate]) => (
            <div key={currency} className="rate-input-group">
              <label htmlFor={`rate-${currency}`}>1 {currency} =</label>
              <div className="rate-input-wrapper">
                <input
                  type="number"
                  id={`rate-${currency}`}
                  value={rate === '' ? '' : Number(rate)}
                  onChange={(e) => handleRateChange(currency, e.target.value)}
                  step="0.000001"
                  min="0"
                  className="rate-input"
                />
                <span className="currency-label">{presentationCurrency}</span>
              </div>
            </div>
          ))}
        </div>

        <div className="modal-actions">
          <button 
            type="button" 
            onClick={onClose}
            className="cancel-button"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleSave}
            className="submit-button"
          >
            Save Rates
          </button>
        </div>
      </div>
    </div>
  );
}

export default ExchangeRateModal; 