import React, { useState, useEffect, useRef } from 'react';
import { useFirebase } from '../contexts/FirebaseContext';
import './NotificationBell.css';

const NotificationBell = () => {
  const { getUserOrganizations, handleInviteResponse } = useFirebase();
  const [pendingInvites, setPendingInvites] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchInvites = async () => {
      try {
        const orgs = await getUserOrganizations();
        // Filter to only get organizations with pending invites
        const pendingOrgs = orgs?.filter(org => org.hasPendingInvite) || [];
        setPendingInvites(pendingOrgs);
      } catch (error) {
        console.error('Error fetching invites:', error);
      }
    };

    fetchInvites();
    // Fetch invites every minute
    const interval = setInterval(fetchInvites, 60000);
    return () => clearInterval(interval);
  }, [getUserOrganizations]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleAccept = async (orgId) => {
    try {
      await handleInviteResponse(orgId, true);
      setPendingInvites(prevInvites => prevInvites.filter(invite => invite.id !== orgId));
    } catch (error) {
      console.error('Error accepting invite:', error);
    }
  };

  const handleDecline = async (orgId) => {
    try {
      await handleInviteResponse(orgId, false);
      setPendingInvites(prevInvites => prevInvites.filter(invite => invite.id !== orgId));
    } catch (error) {
      console.error('Error declining invite:', error);
    }
  };

  return (
    <div className="notification-bell-container" ref={dropdownRef}>
      <button 
        className="notification-bell-button" 
        onClick={() => setIsOpen(!isOpen)}
        aria-label="Notifications"
      >
        <svg 
          width="20" 
          height="20" 
          viewBox="0 0 24 24" 
          fill="none" 
          stroke="currentColor" 
          strokeWidth="2" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        >
          <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
          <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
        </svg>
        {pendingInvites.length > 0 && (
          <span className="notification-badge">{pendingInvites.length}</span>
        )}
      </button>

      {isOpen && (
        <div className="notification-dropdown">
          <div className="notification-header">
            <h3>Notifications</h3>
          </div>
          <div className="notification-list">
            {pendingInvites.length > 0 ? (
              pendingInvites.map((org) => (
                <div key={org.id} className="notification-item">
                  <div className="notification-content">
                    <p className="org-name">{org.name}</p>
                  </div>
                  <div className="notification-actions">
                    <button 
                      onClick={() => handleDecline(org.id)}
                      className="decline-button"
                    >
                      Decline
                    </button>
                    <button 
                      onClick={() => handleAccept(org.id)}
                      className="accept-button"
                    >
                      Accept
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="notification-item no-notifications">
                <p>No new notifications</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationBell; 