import { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import './Analysis.css';

const COLORS = ['#5e6ad2', '#2dd4bf', '#f43f5e', '#6366f1', '#8b5cf6', '#ec4899'];

function Analysis({ employees = [], scenarios = [] }) {
  const [approvalStatuses, setApprovalStatuses] = useState({});
  const [backfillStatuses, setBackfillStatuses] = useState(() => {
    const saved = localStorage.getItem('backfillStatuses');
    return saved ? JSON.parse(saved) : {};
  });
  const [approvedBackfills, setApprovedBackfills] = useState(() => {
    try {
      const saved = localStorage.getItem('approvedBackfills');
      if (!saved) return [];
      
      const parsed = JSON.parse(saved);
      if (!Array.isArray(parsed)) {
        console.warn('approvedBackfills in localStorage is not an array:', parsed);
        return [];
      }
      return parsed;
    } catch (error) {
      console.error('Error parsing approvedBackfills:', error);
      return [];
    }
  });
  const [recruitmentStatus, setRecruitmentStatus] = useState({});
  const [roleDetails, setRoleDetails] = useState({});
  const [selectedOrg, setSelectedOrg] = useState('All Organizations');
  const [exchangeRates, setExchangeRates] = useState(() => {
    const saved = localStorage.getItem('exchangeRates');
    return saved ? JSON.parse(saved) : {
      USD: 1,
      EUR: 0.85,
      GBP: 0.73,
      JPY: 110.0
    };
  });
  const [presentationCurrency, setPresentationCurrency] = useState(() => {
    const saved = localStorage.getItem('presentationCurrency');
    return saved || 'USD';
  });

  // Define today at component level since it's used in multiple functions
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  useEffect(() => {
    // Load initial data
    const loadData = () => {
      const savedApprovalStatuses = localStorage.getItem('approvalStatuses');
      if (savedApprovalStatuses) {
        const newValue = JSON.parse(savedApprovalStatuses);
        setApprovalStatuses(prev => 
          JSON.stringify(prev) !== savedApprovalStatuses ? newValue : prev
        );
      }
      
      const savedBackfillStatuses = localStorage.getItem('backfillStatuses');
      if (savedBackfillStatuses) {
        const newValue = JSON.parse(savedBackfillStatuses);
        setBackfillStatuses(prev => 
          JSON.stringify(prev) !== savedBackfillStatuses ? newValue : prev
        );
      }

      try {
        const savedApprovedBackfills = localStorage.getItem('approvedBackfills');
        if (savedApprovedBackfills) {
          const parsed = JSON.parse(savedApprovedBackfills);
          if (Array.isArray(parsed)) {
            setApprovedBackfills(prev => 
              JSON.stringify(prev) !== savedApprovedBackfills ? parsed : prev
            );
          }
        }
      } catch (error) {
        console.error('Error parsing approvedBackfills:', error);
      }
      
      const savedRecruitmentStatus = localStorage.getItem('recruitmentStatus');
      if (savedRecruitmentStatus) {
        const newValue = JSON.parse(savedRecruitmentStatus);
        setRecruitmentStatus(prev => 
          JSON.stringify(prev) !== savedRecruitmentStatus ? newValue : prev
        );
      }
      
      const savedRoleDetails = localStorage.getItem('taRoleDetails');
      if (savedRoleDetails) {
        const newValue = JSON.parse(savedRoleDetails);
        setRoleDetails(prev => 
          JSON.stringify(prev) !== savedRoleDetails ? newValue : prev
        );
      }

      const savedExchangeRates = localStorage.getItem('exchangeRates');
      if (savedExchangeRates) {
        const newValue = JSON.parse(savedExchangeRates);
        setExchangeRates(prev => 
          JSON.stringify(prev) !== savedExchangeRates ? newValue : prev
        );
      }
      
      const savedPresentationCurrency = localStorage.getItem('presentationCurrency');
      if (savedPresentationCurrency) {
        setPresentationCurrency(prev => 
          prev !== savedPresentationCurrency ? savedPresentationCurrency : prev
        );
      }
    };

    loadData();

    // Reduce polling frequency to every 5 seconds
    const interval = setInterval(loadData, 5000);

    // Set up storage event listener for real-time updates from other tabs
    const handleStorageChange = (e) => {
      if (e.key === 'approvalStatuses' && e.newValue) {
        const newValue = JSON.parse(e.newValue);
        setApprovalStatuses(prev => 
          JSON.stringify(prev) !== e.newValue ? newValue : prev
        );
      }
      if (e.key === 'backfillStatuses' && e.newValue) {
        const newValue = JSON.parse(e.newValue);
        setBackfillStatuses(prev => 
          JSON.stringify(prev) !== e.newValue ? newValue : prev
        );
      }
      if (e.key === 'approvedBackfills') {
        try {
          if (!e.newValue) {
            setApprovedBackfills(prev => prev.length ? [] : prev);
            return;
          }
          
          const newValue = JSON.parse(e.newValue);
          if (!Array.isArray(newValue)) {
            console.warn('New approvedBackfills value is not an array:', newValue);
            return;
          }
          
          setApprovedBackfills(prev => 
            JSON.stringify(prev) !== e.newValue ? newValue : prev
          );
        } catch (error) {
          console.error('Error handling approvedBackfills storage change:', error);
        }
      }
      if (e.key === 'recruitmentStatus' && e.newValue) {
        const newValue = JSON.parse(e.newValue);
        setRecruitmentStatus(prev => 
          JSON.stringify(prev) !== e.newValue ? newValue : prev
        );
      }
      if (e.key === 'taRoleDetails' && e.newValue) {
        const newValue = JSON.parse(e.newValue);
        setRoleDetails(prev => 
          JSON.stringify(prev) !== e.newValue ? newValue : prev
        );
      }
      if (e.key === 'exchangeRates' && e.newValue) {
        const newValue = JSON.parse(e.newValue);
        setExchangeRates(prev => 
          JSON.stringify(prev) !== e.newValue ? newValue : prev
        );
      }
      if (e.key === 'presentationCurrency' && e.newValue) {
        setPresentationCurrency(prev => 
          prev !== e.newValue ? e.newValue : prev
        );
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      clearInterval(interval);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const getUniqueOrgs = () => {
    const orgs = [...new Set(employees.map(emp => emp.org))].filter(Boolean);
    return ['All Organizations', ...orgs];
  };

  const getFilteredEmployees = () => {
    if (selectedOrg === 'All Organizations') return employees;
    return employees.filter(emp => emp.org === selectedOrg);
  };

  // Helper function to calculate total cost for an employee
  const calculateEmployeeCost = (emp) => {
    if (!emp.baseSalary) return 0;
    return convertCurrency(emp.baseSalary, emp.currency) + 
           convertCurrency(emp.stipend || 0, emp.currency) + 
           convertCurrency(emp.employeeTax || 0, emp.currency) + 
           convertCurrency(emp.nationalInsurance || 0, emp.currency) + 
           convertCurrency(emp.commission || 0, emp.currency) + 
           convertCurrency(emp.platformFees || 0, emp.currency) + 
           convertCurrency(emp.otherRecurringPayments || 0, emp.currency);
  };

  // Get approved hires from scenarios
  const getApprovedHires = () => {
    console.log('Scenarios in getApprovedHires:', scenarios);
    const hires = scenarios.flatMap(scenario => {
      console.log('Processing scenario:', scenario);
      if (!scenario.plannedHires) {
        console.log('No plannedHires in scenario:', scenario);
        return [];
      }
      return scenario.plannedHires
        .filter(hire => {
          const isApproved = approvalStatuses[hire.id]?.status === 'Approved';
          console.log('Hire:', hire, 'isApproved:', isApproved);
          return isApproved;
        })
        .map(hire => {
          console.log('Processing approved hire:', hire);
          const details = roleDetails[hire.id] || {};
          const isCompleted = recruitmentStatus[hire.id]?.status === 'Completed';
          
          // Use confirmed start date for completed hires, otherwise use planned start date
          const startDate = isCompleted && details.confirmedStartDate 
            ? details.confirmedStartDate 
            : hire.startDate;

          return {
            ...hire,
            status: 'Approved',
            totalCost: hire.totalCost || 0,
            startDate: startDate
          };
        });
    });
    console.log('Processed hires:', hires);
    return hires;
  };

  // Get approved backfills
  const getApprovedBackfills = () => {
    console.log('getApprovedBackfills called, current value:', approvedBackfills);
    
    if (!Array.isArray(approvedBackfills)) {
      console.warn('approvedBackfills is not an array:', approvedBackfills);
      return [];
    }

    try {
      const processed = approvedBackfills.map(backfill => {
        if (!backfill || typeof backfill !== 'object') {
          console.warn('Invalid backfill entry:', backfill);
          return null;
        }
        const result = {
          ...backfill,
          id: backfill.id || '',
          status: 'Approved',
          totalCost: backfill.salary || 0,
          startDate: backfill.startDate || '',
          title: backfill.title || '',
          organization: backfill.organization || ''
        };
        console.log('Processed backfill:', result);
        return result;
      }).filter(Boolean);
      
      console.log('Final processed backfills:', processed);
      return processed;
    } catch (error) {
      console.error('Error processing backfills:', error);
      return [];
    }
  };

  // Cost distribution calculations
  const costsByTeam = () => {
    const costs = {};
    getFilteredEmployees().forEach(emp => {
      if (!emp.team || !emp.baseSalary) return;
      costs[emp.team] = (costs[emp.team] || 0) + calculateEmployeeCost(emp);
    });
    return Object.entries(costs).map(([name, value]) => ({ name, value }));
  };

  const costsByLocation = () => {
    const costs = {};
    getFilteredEmployees().forEach(emp => {
      if (!emp.location || !emp.baseSalary) return;
      costs[emp.location] = (costs[emp.location] || 0) + calculateEmployeeCost(emp);
    });
    return Object.entries(costs).map(([name, value]) => ({ name, value }));
  };

  const costsByLevel = () => {
    const costs = {};
    getFilteredEmployees().forEach(emp => {
      if (!emp.level || !emp.baseSalary) return;
      costs[emp.level] = (costs[emp.level] || 0) + calculateEmployeeCost(emp);
    });
    return Object.entries(costs).map(([name, value]) => ({ name, value }));
  };

  // Future projections calculations
  const calculateProjections = (type = 'cost') => {
    console.log('Starting calculateProjections with type:', type);
    const months = [];
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    // Generate next 24 months
    for (let i = 0; i < 24; i++) {
      const date = new Date(today.getFullYear(), today.getMonth() + i, 1);
      months.push({
        name: date.toLocaleString('default', { month: 'short', year: '2-digit' }),
        date: date,
        currentTeam: type === 'cost' ? { total: 0, employees: [] } : 0,
        newJoiners: type === 'cost' ? { total: 0, employees: [] } : 0,
        backfills: type === 'cost' ? { total: 0, employees: [] } : 0,
        approvedHires: type === 'cost' ? { total: 0, employees: [] } : 0,
        leavers: type === 'cost' ? { total: 0, employees: [] } : 0
      });
    }

    // Process current employees
    getFilteredEmployees().forEach(emp => {
      const monthlyCost = type === 'cost' ? calculateEmployeeCost(emp) / 12 : 1;
      const employeeDetail = {
        name: `${emp.firstName} ${emp.lastName}`,
        cost: monthlyCost,
        id: emp.id
      };
      
      months.forEach(month => {
        const startDate = emp.startDate ? new Date(emp.startDate) : null;
        const endDate = emp.endDate ? new Date(emp.endDate) : null;
        
        if (startDate) startDate.setDate(1);
        if (endDate) endDate.setDate(1);

        // Skip if they've already left by this month
        if (endDate && endDate < month.date) return;

        // Handle future joiners
        if (startDate && startDate > today) {
          if (startDate.getMonth() === month.date.getMonth() && 
              startDate.getFullYear() === month.date.getFullYear()) {
            if (type === 'cost') {
              month.newJoiners.total += monthlyCost;
              month.newJoiners.employees.push(employeeDetail);
            } else {
              month.newJoiners += 1;
            }
          } else if (startDate < month.date) {
            if (type === 'cost') {
              month.currentTeam.total += monthlyCost;
              month.currentTeam.employees.push(employeeDetail);
            } else {
              month.currentTeam += 1;
            }
          }
        } else {
          // Current team member
          if (!startDate || startDate <= month.date) {
            if (type === 'cost') {
              month.currentTeam.total += monthlyCost;
              month.currentTeam.employees.push(employeeDetail);
            } else {
              month.currentTeam += 1;
            }
          }
        }

        // Handle leavers in their last month
        if (endDate && endDate.getMonth() === month.date.getMonth() && 
            endDate.getFullYear() === month.date.getFullYear()) {
          if (type === 'cost') {
            month.leavers.total -= monthlyCost;
            month.leavers.employees.push({...employeeDetail, cost: -monthlyCost});
            // Remove from current team in the month they leave
            month.currentTeam.total -= monthlyCost;
            month.currentTeam.employees = month.currentTeam.employees.filter(e => e.id !== emp.id);
          } else {
            month.leavers -= 1;
            month.currentTeam -= 1;
          }
        }
      });
    });

    // Process approved new hires
    getApprovedHires().forEach(hire => {
      const monthlyCost = type === 'cost' ? hire.totalCost / 12 : 1;
      const employeeDetail = {
        name: hire.title || 'New Hire',
        cost: monthlyCost,
        id: hire.id
      };
      
      const isCompleted = recruitmentStatus[hire.id]?.status === 'Completed';
      const startDate = new Date(hire.startDate);
      startDate.setDate(1);
      
      months.forEach(month => {
        if (isCompleted) {
          const confirmedStartDate = new Date(roleDetails[hire.id].confirmedStartDate);
          confirmedStartDate.setDate(1);
          if (confirmedStartDate.getMonth() === month.date.getMonth() && 
              confirmedStartDate.getFullYear() === month.date.getFullYear()) {
            if (type === 'cost') {
              month.newJoiners.total += monthlyCost;
              month.newJoiners.employees.push(employeeDetail);
            } else {
              month.newJoiners += 1;
            }
          } else if (confirmedStartDate < month.date) {
            if (type === 'cost') {
              month.currentTeam.total += monthlyCost;
              month.currentTeam.employees.push(employeeDetail);
            } else {
              month.currentTeam += 1;
            }
          }
        } else if (startDate <= month.date) {
          if (type === 'cost') {
            month.approvedHires.total += monthlyCost;
            month.approvedHires.employees.push(employeeDetail);
          } else {
            month.approvedHires += 1;
          }
        }
      });
    });

    // Process approved backfills
    getApprovedBackfills().forEach(backfill => {
      const monthlyCost = type === 'cost' ? backfill.totalCost / 12 : 1;
      const employeeDetail = {
        name: backfill.title || 'Backfill',
        cost: monthlyCost,
        id: backfill.id
      };
      
      const isCompleted = recruitmentStatus[backfill.id]?.status === 'Completed';
      const startDate = new Date(backfill.startDate);
      startDate.setDate(1);
      
      months.forEach(month => {
        if (isCompleted) {
          const confirmedStartDate = new Date(roleDetails[backfill.id].confirmedStartDate);
          confirmedStartDate.setDate(1);
          if (confirmedStartDate.getMonth() === month.date.getMonth() && 
              confirmedStartDate.getFullYear() === month.date.getFullYear()) {
            if (type === 'cost') {
              month.newJoiners.total += monthlyCost;
              month.newJoiners.employees.push(employeeDetail);
            } else {
              month.newJoiners += 1;
            }
          } else if (confirmedStartDate < month.date) {
            if (type === 'cost') {
              month.currentTeam.total += monthlyCost;
              month.currentTeam.employees.push(employeeDetail);
            } else {
              month.currentTeam += 1;
            }
          }
        } else if (startDate <= month.date) {
          if (type === 'cost') {
            month.backfills.total += monthlyCost;
            month.backfills.employees.push(employeeDetail);
          } else {
            month.backfills += 1;
          }
        }
      });
    });

    return months;
  };

  const convertCurrency = (amount, fromCurrency) => {
    if (!amount || !fromCurrency) return 0;
    if (fromCurrency === presentationCurrency) return amount;
    
    // Convert to USD first if not already in USD
    let inUSD = fromCurrency === 'USD' 
      ? amount 
      : amount * exchangeRates[fromCurrency];
    
    // Convert from USD to presentation currency
    return presentationCurrency === 'USD' 
      ? inUSD 
      : inUSD * exchangeRates[presentationCurrency];
  };

  const formatCurrency = (value) => {
    return `${presentationCurrency} ${Math.abs(value).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })}`;
  };

  // Helper function to render employee details in a cell
  const renderEmployeeDetails = (employees) => {
    if (!employees || employees.length === 0) return '-';
    return (
      <div style={{ whiteSpace: 'pre-wrap' }}>
        {employees.map((emp, idx) => (
          <div key={`${emp.id}-${idx}`}>
            {emp.name}: {formatCurrency(emp.cost)}
          </div>
        ))}
        <div style={{ borderTop: '1px solid var(--border)', marginTop: '4px', paddingTop: '4px' }}>
          Total: {formatCurrency(employees.reduce((sum, emp) => sum + emp.cost, 0))}
        </div>
      </div>
    );
  };

  return (
    <div className="analysis">
      <div className="analysis-header">
        <div className="filter-controls">
          <select
            value={selectedOrg}
            onChange={(e) => setSelectedOrg(e.target.value)}
            className="org-filter"
          >
            {getUniqueOrgs().map(org => (
              <option key={org} value={org}>
                {org}
              </option>
            ))}
          </select>
        </div>
      </div>
      
      <div className="distribution-charts">
        <div className="distribution-charts-grid">
          <div>
            <h3>Cost by Team</h3>
            <ResponsiveContainer width="100%" height={280}>
              <PieChart>
                <Pie
                  data={costsByTeam()}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {costsByTeam().map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => `${presentationCurrency} ${value.toLocaleString()}`} />
              </PieChart>
            </ResponsiveContainer>
          </div>

          <div>
            <h3>Cost by Location</h3>
            <ResponsiveContainer width="100%" height={280}>
              <PieChart>
                <Pie
                  data={costsByLocation()}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {costsByLocation().map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => `${presentationCurrency} ${value.toLocaleString()}`} />
              </PieChart>
            </ResponsiveContainer>
          </div>

          <div>
            <h3>Cost by Level</h3>
            <ResponsiveContainer width="100%" height={280}>
              <PieChart>
                <Pie
                  data={costsByLevel()}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {costsByLevel().map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => `${presentationCurrency} ${value.toLocaleString()}`} />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      <div className="chart-wrapper">
        <h3>Future Cost Projection</h3>
        <div className="chart-container">
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={calculateProjections('cost').map(month => ({
                ...month,
                currentTeam: month.currentTeam.total,
                newJoiners: month.newJoiners.total,
                backfills: month.backfills.total,
                approvedHires: month.approvedHires.total,
                leavers: month.leavers.total
              }))}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              stackOffset="sign"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis tickFormatter={(value) => `${presentationCurrency} ${Math.abs(value / 1000)}k`} />
              <Tooltip 
                formatter={(value, name) => [
                  formatCurrency(value),
                  name.charAt(0).toUpperCase() + name.slice(1)
                ]}
              />
              <Legend />
              <Bar dataKey="currentTeam" stackId="stack" fill="#4ade80" name="Current Team" />
              <Bar dataKey="newJoiners" stackId="stack" fill="#2dd4bf" name="New Joiners" />
              <Bar dataKey="backfills" stackId="stack" fill="#6366f1" name="Backfills" />
              <Bar dataKey="approvedHires" stackId="stack" fill="#8b5cf6" name="Approved Hires" />
              <Bar dataKey="leavers" stackId="stack" fill="#f43f5e" name="Leavers" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="chart-wrapper">
        <h3>Future Headcount Projection</h3>
        <div className="chart-container">
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={calculateProjections('headcount')}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              stackOffset="sign"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip 
                formatter={(value, name) => [
                  `${Math.abs(value)} people`,
                  name.charAt(0).toUpperCase() + name.slice(1)
                ]}
              />
              <Legend />
              <Bar dataKey="currentTeam" stackId="stack" fill="#4ade80" name="Current Team" />
              <Bar dataKey="newJoiners" stackId="stack" fill="#2dd4bf" name="New Joiners" />
              <Bar dataKey="backfills" stackId="stack" fill="#6366f1" name="Backfills" />
              <Bar dataKey="approvedHires" stackId="stack" fill="#8b5cf6" name="Approved Hires" />
              <Bar dataKey="leavers" stackId="stack" fill="#f43f5e" name="Leavers" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}

export default Analysis; 