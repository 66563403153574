import { Dialog } from '@headlessui/react';
import { useState } from 'react';
import './AddEmployeeModal.css';

export default function AddEmployeeModal({ isOpen, closeModal, onAddEmployee, existingEmployees, organizationOptions, contractTypeOptions, teamOptions }) {
  const [formData, setFormData] = useState({
    firstName: '',
    surname: '',
    title: '',
    organization: '',
    team: '',
    level: '',
    location: '',
    directManager: '',
    startDate: '',
    endDate: '',
    contractType: '',
    baseSalary: '',
    currency: 'USD',
    stipend: '',
    employeeTax: '',
    nationalInsurance: '',
    commission: '',
    platformFees: '',
    otherRecurringPayments: '',
    employeeId: '',
    jobId: ''
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = 'First name is required';
    if (!formData.surname) newErrors.surname = 'Surname is required';
    if (!formData.title) newErrors.title = 'Title is required';
    if (!formData.organization) newErrors.organization = 'Organization is required';
    if (!formData.team) newErrors.team = 'Team is required';
    if (!formData.location) newErrors.location = 'Location is required';
    if (!formData.directManager) newErrors.directManager = 'Manager is required';
    if (!formData.startDate) newErrors.startDate = 'Start date is required';
    if (!formData.contractType) newErrors.contractType = 'Contract type is required';
    if (!formData.baseSalary) newErrors.baseSalary = 'Base salary is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onAddEmployee({
        ...formData,
        baseSalary: Number(formData.baseSalary),
        stipend: Number(formData.stipend) || 0,
        employeeTax: Number(formData.employeeTax) || 0,
        nationalInsurance: Number(formData.nationalInsurance) || 0,
        commission: Number(formData.commission) || 0,
        platformFees: Number(formData.platformFees) || 0,
        otherRecurringPayments: Number(formData.otherRecurringPayments) || 0,
      });
      closeModal();
      setFormData({
        firstName: '',
        surname: '',
        title: '',
        startDate: '',
        endDate: '',
        baseSalary: '',
        currency: 'USD',
        location: '',
        level: '',
        directManager: '',
        team: '',
        organization: '',
        contractType: '',
        stipend: '',
        employeeTax: '',
        nationalInsurance: '',
        commission: '',
        platformFees: '',
        otherRecurringPayments: ''
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-panel">
        <h2 className="modal-title">Add New Employee</h2>
        <form onSubmit={handleSubmit} className="modal-form">
          <div className="form-grid">
            <div className="form-group">
              <label htmlFor="firstName">First Name*</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className={errors.firstName ? 'error' : ''}
              />
              {errors.firstName && <span className="error-message">{errors.firstName}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="surname">Surname*</label>
              <input
                type="text"
                id="surname"
                name="surname"
                value={formData.surname}
                onChange={handleChange}
                className={errors.surname ? 'error' : ''}
              />
              {errors.surname && <span className="error-message">{errors.surname}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="title">Title*</label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className={errors.title ? 'error' : ''}
              />
              {errors.title && <span className="error-message">{errors.title}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="organization">Organization*</label>
              <select
                id="organization"
                name="organization"
                value={formData.organization}
                onChange={handleChange}
                className={errors.organization ? 'error' : ''}
              >
                <option value="">Select Organization</option>
                {organizationOptions?.map(org => (
                  <option key={org} value={org}>{org}</option>
                ))}
              </select>
              {errors.organization && <span className="error-message">{errors.organization}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="team">Team*</label>
              <div className="combobox-wrapper">
                <input
                  type="text"
                  list="team-options"
                  id="team"
                  name="team"
                  value={formData.team}
                  onChange={handleChange}
                  className={errors.team ? 'error' : ''}
                  placeholder="Select or enter team name"
                />
                <datalist id="team-options">
                  {teamOptions?.map(team => (
                    <option key={team} value={team} />
                  ))}
                </datalist>
              </div>
              {errors.team && <span className="error-message">{errors.team}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="location">Location*</label>
              <div className="combobox-wrapper">
                <input
                  type="text"
                  list="location-options"
                  id="location"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  className={errors.location ? 'error' : ''}
                  placeholder="Select or enter location"
                />
                <datalist id="location-options">
                  {[...new Set(existingEmployees.map(emp => emp.location))].map(location => (
                    <option key={location} value={location} />
                  ))}
                </datalist>
              </div>
              {errors.location && <span className="error-message">{errors.location}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="directManager">Manager*</label>
              <select
                id="directManager"
                name="directManager"
                value={formData.directManager}
                onChange={handleChange}
                className={errors.directManager ? 'error' : ''}
              >
                <option value="">Select Manager</option>
                {existingEmployees.map(emp => (
                  <option key={emp.id} value={`${emp.firstName} ${emp.surname}`}>
                    {`${emp.firstName} ${emp.surname}`}
                  </option>
                ))}
              </select>
              {errors.directManager && <span className="error-message">{errors.directManager}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="startDate">Start Date*</label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
                className={errors.startDate ? 'error' : ''}
              />
              {errors.startDate && <span className="error-message">{errors.startDate}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="endDate">End Date (Optional)</label>
              <input
                type="date"
                id="endDate"
                name="endDate"
                value={formData.endDate}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="contractType">Contract Type*</label>
              <select
                id="contractType"
                name="contractType"
                value={formData.contractType}
                onChange={handleChange}
                className={errors.contractType ? 'error' : ''}
              >
                <option value="">Select Contract Type</option>
                {contractTypeOptions?.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
              {errors.contractType && <span className="error-message">{errors.contractType}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="baseSalary">Base Salary*</label>
              <input
                type="number"
                id="baseSalary"
                name="baseSalary"
                value={formData.baseSalary}
                onChange={handleChange}
                className={errors.baseSalary ? 'error' : ''}
              />
              {errors.baseSalary && <span className="error-message">{errors.baseSalary}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="currency">Currency</label>
              <select
                id="currency"
                name="currency"
                value={formData.currency}
                onChange={handleChange}
              >
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="stipend">Stipend</label>
              <input
                type="number"
                id="stipend"
                name="stipend"
                value={formData.stipend}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="employeeTax">Employee Tax</label>
              <input
                type="number"
                id="employeeTax"
                name="employeeTax"
                value={formData.employeeTax}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="nationalInsurance">National Insurance</label>
              <input
                type="number"
                id="nationalInsurance"
                name="nationalInsurance"
                value={formData.nationalInsurance}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="commission">Commission</label>
              <input
                type="number"
                id="commission"
                name="commission"
                value={formData.commission}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="platformFees">Platform Fees</label>
              <input
                type="number"
                id="platformFees"
                name="platformFees"
                value={formData.platformFees}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="otherRecurringPayments">Other Recurring Payments</label>
              <input
                type="number"
                id="otherRecurringPayments"
                name="otherRecurringPayments"
                value={formData.otherRecurringPayments}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="employeeId">Employee ID</label>
              <input
                type="text"
                id="employeeId"
                name="employeeId"
                value={formData.employeeId}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="jobId">Job ID</label>
              <input
                type="text"
                id="jobId"
                name="jobId"
                value={formData.jobId}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="modal-actions">
            <button type="button" onClick={closeModal} className="cancel-button">
              Cancel
            </button>
            <button type="submit" className="submit-button">
              Add Employee
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}