import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, Navigate } from 'react-router-dom';
import { FirebaseProvider, useFirebase } from './contexts/FirebaseContext';
import LandingPage from './features/landing/LandingPage';
import CurrentOrganization from './features/current-org/CurrentOrganization';
import { AdminPanel } from './features/admin/AdminPanel';
import ScenarioPlanning from './features/scenarios/ScenarioPlanning';
import Analysis from './features/analysis/Analysis';
import ApprovalQueue from './features/approvals/ApprovalQueue';
import TalentAcquisition from './features/talent/TalentAcquisition';
import CompensationFramework from './features/compensation/CompensationFramework';
import TeamViewer from './features/team-viewer/TeamViewer';
import Commission from './features/commission/Commission';
import MobileMenu from './components/MobileMenu';
import NotificationBell from './components/NotificationBell';
import './App.css';

function AppContent() {
  const { 
    user, 
    isAdmin, 
    logout, 
    scenarios: firebaseScenarios, 
    employees: firebaseEmployees,
    updateScenario,
    updateEmployee,
    createEmployee,
    deleteEmployee
  } = useFirebase();
  const [showLanding, setShowLanding] = useState(() => {
    const hasVisited = localStorage.getItem('hasVisited');
    return !hasVisited;
  });
  const [exchangeRates, setExchangeRates] = useState(() => {
    const savedRates = localStorage.getItem('exchangeRates');
    return savedRates ? JSON.parse(savedRates) : {
      USD: 1,
      EUR: 1.1,
      GBP: 1.27,
      CHF: 1.12,
      SEK: 0.096,
      PHP: 0.018,
      INR: 0.012
    };
  });
  const [presentationCurrency, setPresentationCurrency] = useState(() => {
    const saved = localStorage.getItem('presentationCurrency');
    return saved || 'USD';
  });

  // Handle approval updates
  const handleApprovalUpdate = useCallback(async (scenarioId, updateData) => {
    try {
      if (!updateData.orgId) {
        throw new Error('Organization ID is required for updates');
      }

      // Update the scenario in Firebase under the correct organization
      await updateScenario(scenarioId, {
        ...updateData,
        path: `organizations/${updateData.orgId}/scenarios/${scenarioId}`
      });

      return true;
    } catch (error) {
      console.error('Error updating scenario:', error);
      return false;
    }
  }, [updateScenario]);

  // Add effect to listen for exchange rates changes
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'exchangeRates' && e.newValue) {
        setExchangeRates(JSON.parse(e.newValue));
      }
      if (e.key === 'presentationCurrency' && e.newValue) {
        setPresentationCurrency(e.newValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  const handleGetStarted = () => {
    localStorage.setItem('hasVisited', 'true');
    setShowLanding(false);
  };

  if (showLanding) {
    return <LandingPage onGetStarted={handleGetStarted} />;
  }

  if (!user) {
    return <LandingPage onGetStarted={handleGetStarted} />;
  }

  return (
    <div className="app">
      <header className="app-header">
        <div className="header-content">
          <div className="header-left">
            <div className="header-brand">
              <button onClick={() => setShowLanding(true)} className="logo-button">
                <img src="/images/logo.png" alt="multipl.org" className="logo-image" />
              </button>
            </div>
            <MobileMenu isAdmin={isAdmin} />
          </div>
          <nav className="main-nav desktop-only">
            <NavLink to="/" className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`} end>
              Organization
            </NavLink>
            <NavLink to="/analysis" className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}>
              Analysis
            </NavLink>
            <NavLink to="/scenarios" className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}>
              Scenarios
            </NavLink>
            <NavLink to="/approvals" className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}>
              Approvals
            </NavLink>
            <NavLink to="/talent" className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}>
              Talent
            </NavLink>
            <NavLink to="/compensation" className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}>
              Compensation
            </NavLink>
            <NavLink to="/commission" className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}>
              Commission
            </NavLink>
            <NavLink to="/team-viewer" className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}>
              Team Viewer
            </NavLink>
            <NavLink 
              to="/admin" 
              className={({ isActive }) => `nav-button admin ${isActive ? 'active' : ''}`}
            >
              Admin Panel
            </NavLink>
          </nav>
          <div className="header-right">
            <NotificationBell />
            <button onClick={logout} className="auth-button">Log Out</button>
          </div>
        </div>
      </header>

      <main className="main-content">
        <Routes>
          <Route 
            path="/" 
            element={
              <CurrentOrganization 
                employees={firebaseEmployees} 
                setEmployees={async (newEmployees) => {
                  // Handle bulk employee updates by comparing with existing employees
                  const existingIds = new Set(firebaseEmployees.map(e => e.id));
                  const newIds = new Set(newEmployees.map(e => e.id));
                  
                  // Handle deletions
                  for (const emp of firebaseEmployees) {
                    if (!newIds.has(emp.id)) {
                      await deleteEmployee(emp.id);
                    }
                  }
                  
                  // Handle updates and additions
                  for (const emp of newEmployees) {
                    if (existingIds.has(emp.id)) {
                      await updateEmployee(emp.id, emp);
                    } else {
                      await createEmployee(emp);
                    }
                  }
                }}
                scenarios={firebaseScenarios}
                exchangeRates={exchangeRates}
                setExchangeRates={setExchangeRates}
                presentationCurrency={presentationCurrency}
                setPresentationCurrency={setPresentationCurrency}
              />
            } 
          />
          <Route path="/analysis" element={<Analysis employees={firebaseEmployees} scenarios={firebaseScenarios} />} />
          <Route 
            path="/scenarios" 
            element={
              <ScenarioPlanning 
                scenarios={firebaseScenarios}
                employees={firebaseEmployees}
              />
            } 
          />
          <Route 
            path="/approvals" 
            element={
              <ApprovalQueue 
                scenarios={firebaseScenarios}
                employees={firebaseEmployees}
                onUpdateApproval={handleApprovalUpdate}
              />
            } 
          />
          <Route path="/talent" element={<TalentAcquisition scenarios={firebaseScenarios} employees={firebaseEmployees} />} />
          <Route 
            path="/compensation" 
            element={
              <CompensationFramework 
                employees={firebaseEmployees} 
                scenarios={firebaseScenarios}
              />
            } 
          />
          <Route 
            path="/commission" 
            element={
              <Commission 
                employees={firebaseEmployees}
              />
            } 
          />
          <Route 
            path="/team-viewer" 
            element={
              <TeamViewer 
                employees={firebaseEmployees} 
                scenarios={firebaseScenarios}
                setEmployees={async (newEmployees) => {
                  // Handle bulk employee updates by comparing with existing employees
                  const existingIds = new Set(firebaseEmployees.map(e => e.id));
                  const newIds = new Set(newEmployees.map(e => e.id));
                  
                  // Handle deletions
                  for (const emp of firebaseEmployees) {
                    if (!newIds.has(emp.id)) {
                      await deleteEmployee(emp.id);
                    }
                  }
                  
                  // Handle updates and additions
                  for (const emp of newEmployees) {
                    if (existingIds.has(emp.id)) {
                      await updateEmployee(emp.id, emp);
                    } else {
                      await createEmployee(emp);
                    }
                  }
                }}
              />
            } 
          />
          <Route path="/admin" element={<AdminPanel />} />
        </Routes>
      </main>
    </div>
  );
}

function App() {
  return (
    <Router>
      <FirebaseProvider>
        <AppContent />
      </FirebaseProvider>
    </Router>
  );
}

export default App;