import React, { useState, useEffect } from 'react';
import { useFirebase } from '../../contexts/FirebaseContext';
import './AdminPanel.css';

export const AdminPanel = () => {
  const { 
    inviteUserToOrg, 
    getPendingInvites, 
    getOrganizationUsers, 
    updateUserRole,
    removeUserFromOrg,
    revokeInvite,
    getUserOrganizations,
    switchOrganization,
    deleteAllData,
    createOrganization,
    updateOrganization,
    handleInviteResponse,
    deleteAllScenarios,
    user: currentUser,
    isAdmin,
    manuallyInitializeScenarios
  } = useFirebase();
  const [email, setEmail] = useState('');
  const [inviteStatus, setInviteStatus] = useState('');
  const [pendingInvites, setPendingInvites] = useState([]);
  const [orgUsers, setOrgUsers] = useState([]);
  const [userOrganizations, setUserOrganizations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionStatus, setActionStatus] = useState('');
  const [userToRemove, setUserToRemove] = useState(null);
  const [inviteToRevoke, setInviteToRevoke] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [newOrgName, setNewOrgName] = useState('');
  const [showCreateOrgForm, setShowCreateOrgForm] = useState(false);
  const [isEditingOrgName, setIsEditingOrgName] = useState(false);
  const [editedOrgName, setEditedOrgName] = useState('');
  const [showDeleteScenariosConfirmation, setShowDeleteScenariosConfirmation] = useState(false);
  const [isDeletingScenarios, setIsDeletingScenarios] = useState(false);

  const handleDeleteScenarios = async () => {
    if (!showDeleteScenariosConfirmation) {
      setShowDeleteScenariosConfirmation(true);
      return;
    }

    try {
      setIsDeletingScenarios(true);
      await deleteAllScenarios();
      setShowDeleteScenariosConfirmation(false);
      setActionStatus('All scenarios deleted successfully');
    } catch (error) {
      console.error('Error deleting scenarios:', error);
      setActionStatus(error.message);
    } finally {
      setIsDeletingScenarios(false);
    }
  };

  // Load data
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const [invites, users, orgs] = await Promise.all([
          getPendingInvites(),
          getOrganizationUsers(),
          getUserOrganizations()
        ]);
        setPendingInvites(invites || []);
        setOrgUsers(users || []);
        setUserOrganizations(orgs || []);
      } catch (error) {
        console.error('Error loading data:', error);
        setActionStatus(error.message);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [getPendingInvites, getOrganizationUsers, getUserOrganizations]);

  const handleInvite = async (e) => {
    e.preventDefault();
    setLoading(true);
    setInviteStatus('');

    try {
      await inviteUserToOrg(email);
      setInviteStatus('Invitation sent successfully!');
      setEmail('');
      
      // Refresh pending invites
      const invites = await getPendingInvites();
      setPendingInvites(invites || []);
    } catch (error) {
      console.error('Error sending invite:', error);
      setInviteStatus(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRevokeInvite = async (email) => {
    setLoading(true);
    setActionStatus('');

    try {
      await revokeInvite(email);
      // Refresh pending invites
      const invites = await getPendingInvites();
      setPendingInvites(invites || []);
      setActionStatus('Invite revoked successfully!');
      setInviteToRevoke(null);
    } catch (error) {
      console.error('Error revoking invite:', error);
      setActionStatus(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRoleUpdate = async (userId, makeAdmin) => {
    setLoading(true);
    setActionStatus('');

    try {
      await updateUserRole(userId, makeAdmin);
      // Refresh user list
      const users = await getOrganizationUsers();
      setOrgUsers(users);
      setActionStatus('Role updated successfully!');
    } catch (error) {
      setActionStatus(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveUser = async (userId) => {
    setLoading(true);
    setActionStatus('');

    try {
      await removeUserFromOrg(userId);
      // Refresh user list
      const users = await getOrganizationUsers();
      setOrgUsers(users);
      setActionStatus('User removed successfully!');
      setUserToRemove(null);
    } catch (error) {
      setActionStatus(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSwitchOrg = async (orgId) => {
    setLoading(true);
    setActionStatus('');

    try {
      await switchOrganization(orgId);
      setActionStatus('Successfully switched organization!');
      // Refresh all data after switching
      const [users, orgs] = await Promise.all([
        getOrganizationUsers(),
        getUserOrganizations()
      ]);
      setOrgUsers(users || []);
      setUserOrganizations(orgs || []);
    } catch (error) {
      console.error('Error switching organization:', error);
      setActionStatus(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAllData = async () => {
    if (!showDeleteConfirmation) {
      setShowDeleteConfirmation(true);
      return;
    }

    try {
      setIsDeleting(true);
      await deleteAllData();
      setShowDeleteConfirmation(false);
      setActionStatus('All data deleted successfully');
    } catch (error) {
      console.error('Error deleting data:', error);
      setActionStatus(error.message);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleCreateOrg = async (e) => {
    e.preventDefault();
    setLoading(true);
    setActionStatus('');

    try {
      const newOrgId = await createOrganization(newOrgName);
      setActionStatus('Organization created successfully! Switching to new organization...');
      setNewOrgName('');
      setShowCreateOrgForm(false);
      
      // Refresh the organizations list first
      const updatedOrgs = await getUserOrganizations();
      setUserOrganizations(updatedOrgs || []);
      
      // Wait a moment before switching to the new organization
      setTimeout(async () => {
        try {
          await switchOrganization(newOrgId);
          // Refresh the organizations list again after switching
          const finalOrgs = await getUserOrganizations();
          setUserOrganizations(finalOrgs || []);
        } catch (switchError) {
          console.error('Error switching to new organization:', switchError);
          setActionStatus('Organization created but error switching to it. Please try switching manually.');
        }
      }, 1500);
    } catch (error) {
      console.error('Error creating organization:', error);
      setActionStatus(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOrgNameEdit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setActionStatus('');

    try {
      await updateOrganization(currentUser.orgId, { name: editedOrgName });
      setActionStatus('Organization name updated successfully!');
      setIsEditingOrgName(false);
    } catch (error) {
      console.error('Error updating organization name:', error);
      setActionStatus(error.message);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    
    // Handle Firestore Timestamp
    if (timestamp && typeof timestamp.toDate === 'function') {
      return timestamp.toDate().toLocaleDateString();
    }
    
    // Handle ISO string or other date formats
    return new Date(timestamp).toLocaleDateString();
  };

  const handleInviteAction = async (orgId, accept) => {
    setLoading(true);
    setActionStatus('');

    try {
      await handleInviteResponse(orgId, accept);
      // Refresh pending invites
      const invites = await getPendingInvites();
      setPendingInvites(invites || []);
      setActionStatus(accept ? 'Invite accepted successfully!' : 'Invite declined successfully!');
    } catch (error) {
      console.error('Error handling invite:', error);
      setActionStatus(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-panel">
      <h2>{isAdmin ? "Organization Management" : "Organization Settings"}</h2>
      
      {/* Current Organization Section */}
      <section className="current-org-section">
        <h3>Current Organization</h3>
        <div className="current-org">
          <h4>Current Organization</h4>
          {isEditingOrgName && isAdmin ? (
            <form onSubmit={handleOrgNameEdit} className="org-name-edit-form">
              <input
                type="text"
                value={editedOrgName}
                onChange={(e) => setEditedOrgName(e.target.value)}
                placeholder="Enter organization name"
                required
                className="org-name-input"
                disabled={loading}
                autoFocus
              />
              <div className="form-buttons">
                <button 
                  type="submit" 
                  disabled={loading} 
                  className="save-button"
                >
                  {loading ? 'Saving...' : 'Save'}
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setIsEditingOrgName(false);
                    setEditedOrgName('');
                  }}
                  className="cancel-button"
                  disabled={loading}
                >
                  Cancel
                </button>
              </div>
            </form>
          ) : (
            <div className="org-name-display">
              <p>{currentUser?.orgName || 'Loading...'}</p>
              {isAdmin && (
                <button 
                  onClick={() => {
                    setEditedOrgName(currentUser?.orgName || '');
                    setIsEditingOrgName(true);
                  }}
                  className="edit-org-name-button"
                  title="Edit organization name"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="edit-icon">
                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                  </svg>
                </button>
              )}
            </div>
          )}

          {/* Organization Switcher */}
          <div className="org-switcher">
            <h4>Your Organizations</h4>
            <ul className="orgs-list">
              {userOrganizations.map((org) => (
                <li key={org.id} className="org-item">
                  <div className="org-info">
                    <span className="org-name">{org.name}</span>
                    {org.isAdmin && <span className="admin-badge">Admin</span>}
                    {org.hasPendingInvite && <span className="pending-badge">Pending Invite</span>}
                  </div>
                  {org.id !== currentUser?.orgId && (
                    <div className="org-actions">
                      {org.hasPendingInvite ? (
                        <>
                          <button
                            onClick={() => handleInviteAction(org.id, true)}
                            disabled={loading}
                            className="accept-button"
                          >
                            Accept Invite
                          </button>
                          <button
                            onClick={() => handleInviteAction(org.id, false)}
                            disabled={loading}
                            className="reject-button"
                          >
                            Decline
                          </button>
                        </>
                      ) : (
                        <button
                          onClick={() => handleSwitchOrg(org.id)}
                          disabled={loading}
                          className="switch-button"
                        >
                          {loading ? 'Switching...' : 'Switch'}
                        </button>
                      )}
                    </div>
                  )}
                </li>
              ))}
            </ul>
            <button
              onClick={() => setShowCreateOrgForm(!showCreateOrgForm)}
              className="create-org-button"
            >
              Create New Organization
            </button>
            {showCreateOrgForm && (
              <form onSubmit={handleCreateOrg} className="create-org-form">
                <div className="form-group">
                  <input
                    type="text"
                    value={newOrgName}
                    onChange={(e) => setNewOrgName(e.target.value)}
                    placeholder="Enter organization name"
                    required
                    className="org-name-input"
                    disabled={loading}
                  />
                  <div className="form-buttons">
                    <button 
                      type="submit" 
                      disabled={loading} 
                      className="create-button"
                    >
                      {loading ? 'Creating...' : 'Create'}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setShowCreateOrgForm(false);
                        setNewOrgName('');
                      }}
                      className="cancel-button"
                      disabled={loading}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </section>

      {/* Admin-only sections */}
      {isAdmin && (
        <>
          {/* User Management Section */}
          <section className="user-management-section">
            <h3>User Management</h3>
            
            {/* Invite Users Form */}
            <div className="invite-users">
              <h4>Invite Users</h4>
              <form onSubmit={handleInvite} className="invite-form">
                <div className="form-group">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter email address"
                    required
                    className="email-input"
                    disabled={loading}
                  />
                  <button type="submit" disabled={loading} className="invite-button">
                    {loading ? 'Sending...' : 'Send Invite'}
                  </button>
                </div>
                {inviteStatus && (
                  <div className={`status-message ${inviteStatus.includes('Error') ? 'error' : 'success'}`}>
                    {inviteStatus}
                  </div>
                )}
              </form>
            </div>

            {/* Pending Invites */}
            <div className="pending-invites">
              <h4>Pending Invites</h4>
              {pendingInvites.length === 0 ? (
                <p>No pending invites</p>
              ) : (
                <ul className="invites-list">
                  {pendingInvites.map((invite) => (
                    <li key={invite.email} className="invite-item">
                      <div className="invite-info">
                        <span className="invite-email">{invite.email}</span>
                        <span className="invite-date">
                          Invited by {invite.invitedByEmail} on {new Date(invite.invitedAt).toLocaleDateString()}
                        </span>
                      </div>
                      <div className="invite-actions">
                        {currentUser.email === invite.email ? (
                          <>
                            <button
                              onClick={() => handleInviteAction(currentUser.orgId, true)}
                              disabled={loading}
                              className="accept-button"
                            >
                              Accept
                            </button>
                            <button
                              onClick={() => handleInviteAction(currentUser.orgId, false)}
                              disabled={loading}
                              className="reject-button"
                            >
                              Decline
                            </button>
                          </>
                        ) : isAdmin && (
                          <button
                            onClick={() => handleRevokeInvite(invite.email)}
                            disabled={loading}
                            className="revoke-button"
                          >
                            Revoke Invite
                          </button>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {/* Organization Users */}
            <div className="org-users">
              <h4>Organization Users</h4>
              {orgUsers.length === 0 ? (
                <p>No users in organization</p>
              ) : (
                <ul className="users-list">
                  {orgUsers.map(user => (
                    <li key={user.uid} className="user-item">
                      <div className="user-info">
                        {user.photoURL && (
                          <img src={user.photoURL} alt="" className="user-avatar" />
                        )}
                        <div className="user-details">
                          <div className="user-primary">
                            <span className="user-name">{user.displayName || user.email}</span>
                            {user.isAdmin && <span className="admin-badge">Admin</span>}
                          </div>
                          <span className="user-email">{user.email}</span>
                        </div>
                        {user.uid !== currentUser?.uid && (
                          <div className="user-actions">
                            <button
                              onClick={() => handleRoleUpdate(user.uid, !user.isAdmin)}
                              disabled={loading}
                              className={`role-button ${user.isAdmin ? 'remove-admin' : 'make-admin'}`}
                            >
                              {user.isAdmin ? 'Remove Admin' : 'Make Admin'}
                            </button>
                            {userToRemove === user.uid ? (
                              <div className="confirmation-buttons">
                                <button
                                  onClick={() => handleRemoveUser(user.uid)}
                                  disabled={loading}
                                  className="confirm-button confirm-yes"
                                >
                                  Confirm Remove
                                </button>
                                <button
                                  onClick={() => setUserToRemove(null)}
                                  className="confirm-button confirm-no"
                                >
                                  Cancel
                                </button>
                              </div>
                            ) : (
                              <button
                                onClick={() => setUserToRemove(user.uid)}
                                className="remove-button"
                              >
                                Remove User
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </section>

          {/* Danger Zone */}
          <section className="danger-zone">
            <h3>Danger Zone</h3>
            <div className="danger-actions">
              <div className="danger-action">
                <div className="danger-info">
                  <h4>Delete All Data</h4>
                  <p>This action cannot be undone. All your data will be permanently deleted.</p>
                </div>
                {showDeleteConfirmation ? (
                  <div className="delete-confirmation">
                    <div className="confirmation-buttons">
                      <button
                        onClick={handleDeleteAllData}
                        disabled={isDeleting}
                        className="delete-button danger"
                      >
                        {isDeleting ? 'Deleting...' : 'Yes, Delete All Data'}
                      </button>
                      <button 
                        onClick={() => setShowDeleteConfirmation(false)}
                        className="cancel-button"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <button
                    onClick={handleDeleteAllData}
                    className="delete-button danger"
                  >
                    Delete All Organization Data
                  </button>
                )}
              </div>

              <div className="danger-action">
                <div className="danger-info">
                  <h4>Delete All Scenarios</h4>
                  <p>This will delete all scenarios in the current organization. This action cannot be undone.</p>
                </div>
                {showDeleteScenariosConfirmation ? (
                  <div className="delete-confirmation">
                    <div className="confirmation-buttons">
                      <button
                        onClick={handleDeleteScenarios}
                        disabled={isDeletingScenarios}
                        className="delete-button danger"
                      >
                        {isDeletingScenarios ? 'Deleting...' : 'Yes, Delete All Scenarios'}
                      </button>
                      <button 
                        onClick={() => setShowDeleteScenariosConfirmation(false)}
                        className="cancel-button"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <button
                    onClick={handleDeleteScenarios}
                    className="delete-button danger"
                  >
                    Delete All Scenarios
                  </button>
                )}
              </div>
            </div>
          </section>
        </>
      )}

      {actionStatus && (
        <div className={`status-message ${actionStatus.includes('Error') ? 'error' : 'success'}`}>
          {actionStatus}
        </div>
      )}
    </div>
  );
}; 