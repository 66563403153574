import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './MobileMenu.css';

const MobileMenu = ({ isAdmin }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div className="mobile-menu-container">
      <button 
        className={`mobile-menu-button ${isOpen ? 'active' : ''}`}
        onClick={toggleMenu}
        aria-label="Toggle menu"
      >
        <span className="hamburger-line"></span>
        <span className="hamburger-line"></span>
        <span className="hamburger-line"></span>
      </button>

      <div className={`mobile-menu ${isOpen ? 'open' : ''}`}>
        <nav className="mobile-nav">
          <NavLink to="/" className={({ isActive }) => `mobile-nav-button ${isActive ? 'active' : ''}`} end onClick={closeMenu}>
            Organization
          </NavLink>
          <NavLink to="/scenarios" className={({ isActive }) => `mobile-nav-button ${isActive ? 'active' : ''}`} onClick={closeMenu}>
            Scenarios
          </NavLink>
          <NavLink to="/approvals" className={({ isActive }) => `mobile-nav-button ${isActive ? 'active' : ''}`} onClick={closeMenu}>
            Approvals
          </NavLink>
          <NavLink to="/talent" className={({ isActive }) => `mobile-nav-button ${isActive ? 'active' : ''}`} onClick={closeMenu}>
            Talent
          </NavLink>
          <NavLink to="/compensation" className={({ isActive }) => `mobile-nav-button ${isActive ? 'active' : ''}`} onClick={closeMenu}>
            Compensation
          </NavLink>
          <NavLink to="/commission" className={({ isActive }) => `mobile-nav-button ${isActive ? 'active' : ''}`} onClick={closeMenu}>
            Commission
          </NavLink>
          <NavLink to="/team-viewer" className={({ isActive }) => `mobile-nav-button ${isActive ? 'active' : ''}`} onClick={closeMenu}>
            Team Viewer
          </NavLink>
          <NavLink 
            to="/admin" 
            className={({ isActive }) => `mobile-nav-button admin ${isActive ? 'active' : ''}`}
            onClick={closeMenu}
          >
            Admin Panel
          </NavLink>
        </nav>
      </div>
    </div>
  );
};

export default MobileMenu; 