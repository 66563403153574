import React, { useState, useEffect, useMemo, useCallback, useRef, useContext } from 'react';
import './TalentAcquisition.css';
import TATeamModal from '../../components/modals/TATeamModal';
import TARoleModal from '../../components/modals/TARoleModal';
import { useFirebase } from '../../contexts/FirebaseContext';
import { doc, collection, setDoc, updateDoc, deleteDoc, serverTimestamp, onSnapshot, runTransaction, writeBatch, getDocs, getDoc, query, where } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { FirebaseContext } from '../../contexts/FirebaseContext';
import { getFirestore } from 'firebase/firestore';

const RECRUITMENT_STATUSES = [
  'Not Started',
  'Sourcing',
  'Interviewing',
  'Offer',
  'Completed',
  'On Hold'
];

function TalentAcquisition() {
  const {
    user: currentUser,
    scenarios,
    employees,
    updateScenario,
    createEmployee: createEmployeeFirebase
  } = useContext(FirebaseContext);

  const [approvedRoles, setApprovedRoles] = useState([]);
  const [hideCompleted, setHideCompleted] = useState(false);
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const [pendingCompletionRole, setPendingCompletionRole] = useState(null);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [latestStatusData, setLatestStatusData] = useState({});
  const [latestScenarioRoles, setLatestScenarioRoles] = useState([]);
  const updatePendingRef = useRef(false);
  const previousDataRef = useRef({ statusData: {}, scenarioRoles: [] });
  const statusListenersRef = useRef({});
  const scenarioListenersRef = useRef({});
  const currentRoleStatesRef = useRef({});
  const roleStatusRef = useRef({});
  const scenarioRoles = useRef([]);

  // Add back missing state
  const [taAssignments, setTaAssignments] = useState(() => {
    return JSON.parse(localStorage.getItem('taAssignments') || '{}');
  });

  const [stages] = useState(() => {
    const defaultStages = [
      { id: '1', name: 'Not Started', order: 0 },
      { id: '2', name: 'Job Description', order: 1 },
      { id: '3', name: 'Sourcing', order: 2 },
      { id: '4', name: 'Interviewing', order: 3 },
      { id: '5', name: 'Offer Stage', order: 4 },
      { id: '6', name: 'Completed', order: 5 },
      { id: '7', name: 'Cancelled', order: 6 }
    ];
    return defaultStages;
  });

  const [latestBackfillData, setLatestBackfillData] = useState({});

  // Helper functions for status and stage handling
  const getStageName = (role) => {
    if (!role) return 'Not Started';
    return (role.recruitmentStatus || role.status || 'Not Started').toString();
  };

  const getRoleStatus = (role) => {
    if (!role) return { status: 'Not Started', statusClass: 'not-started' };
    const status = getStageName(role);
    const statusClass = status.toLowerCase().replace(/\s+/g, '-');
    return { status, statusClass };
  };

  // Calculate stage statistics
  const stageStats = useMemo(() => {
    if (!Array.isArray(stages) || !Array.isArray(approvedRoles)) {
      return [];
    }

    return stages.map(stage => {
      if (!stage?.name) return { ...stage, count: 0 };
      
      const count = approvedRoles.filter(role => {
        if (!role) return false;
        return getStageName(role).toLowerCase() === stage.name.toLowerCase();
      }).length;
      
      return {
        ...stage,
        count
      };
    });
  }, [stages, approvedRoles]);

  // Helper function to check if a role has required data
  const hasRequiredData = useCallback((role) => {
    if (!role) return false;
    
    // Basic required fields for all roles
    const basicFields = ['id', 'approvalStatus'];
    if (!basicFields.every(field => role[field])) return false;
    
    // For backfills, check additional required fields
    if (role.isBackfill) {
      const backfillFields = ['roleTitle', 'organization', 'team', 'currentEmployee'];
      return backfillFields.every(field => role[field]);
    }
    
    // For new positions, just check if it's approved
    return role.approvalStatus === 'Approved';
  }, []);

  // Process scenarios and employees
  const processScenarios = useCallback((latestStatusData = {}) => {
    // Get approved hires from scenarios
    const approvedHires = scenarios
      .flatMap(scenario => {
        if (!scenario?.plannedHires) return [];
        return scenario.plannedHires.map(hire => ({
          ...hire,
          scenarioName: scenario.name,
          scenarioId: scenario.id,
          status: latestStatusData[hire.id]?.status || hire.status || 'Not Started',
          approvalStatus: hire.approvalStatus || 'Pending',
          recruitmentStatus: latestStatusData[hire.id]?.status || hire.recruitmentStatus || 'Not Started',
          isVisible: true,
          isActive: true
        }));
      })
      .filter(hire => hire.approvalStatus === 'Approved');

    console.log('Processed approved hires:', approvedHires);

    // Process backfills
    const approvedBackfills = employees
      .filter(emp => emp.endDate && emp.jobTitle && emp.org && emp.team)
      .map(emp => {
        const backfillId = `backfill-emp-${emp.id}`;
        const backfillStatus = latestStatusData[backfillId]?.status;
        return {
          id: backfillId,
          roleTitle: emp.jobTitle || emp.title || 'No Title',
          organization: emp.org,
          team: emp.team,
          totalCost: emp.baseSalary || 0,
          directManager: emp.directManager,
          isBackfill: true,
          currentEmployee: `${emp.firstName} ${emp.surname}`,
          leaverDate: emp.endDate,
          approvalStatus: 'Approved',
          isVisible: true,
          isActive: true,
          status: backfillStatus || 'Not Started',
          recruitmentStatus: backfillStatus || 'Not Started'
        };
      });

    console.log('Processed backfills:', approvedBackfills);

    // Combine and set roles
    setApprovedRoles([...approvedHires, ...approvedBackfills]);
  }, [scenarios, employees]);

  // Filter roles based on completion status with reduced logging
  const filteredRoles = useMemo(() => {
    if (!approvedRoles?.length) return [];
    
    return approvedRoles.filter(role => {
      if (!role || !role.id) return false;
      if (!hideCompleted) return true;
      return role.status !== 'Completed' && role.recruitmentStatus !== 'Completed';
    });
  }, [approvedRoles, hideCompleted]);

  // Use ref to track component mounted state
  const isMounted = useRef(true);
  const unsubscribersRef = useRef([]);

  // Cleanup function for Firebase listeners
  const cleanupListeners = useCallback(() => {
    unsubscribersRef.current.forEach(unsub => {
      try {
        if (typeof unsub === 'function') unsub();
      } catch (error) {
        // Silently handle cleanup errors
      }
    });
    unsubscribersRef.current = [];
  }, []);

  // Update the Firebase listeners setup with proper cleanup
  useEffect(() => {
    if (!currentUser?.orgId) return;

    const db = getFirestore();
    let setupComplete = false;

    const setupListeners = async () => {
      if (!isMounted.current) return;

      try {
        // Clean up existing listeners before setting up new ones
        cleanupListeners();

        // Set up backfills listener
        const backfillsRef = collection(db, `organizations/${currentUser.orgId}/backfills`);
        const backfillsUnsubscribe = onSnapshot(backfillsRef, snapshot => {
          if (!isMounted.current) return;
          
          if (!snapshot.empty) {
            const newStatusData = {};
            snapshot.forEach(doc => {
              const data = doc.data();
              if (data.id) {
                newStatusData[data.id] = {
                  ...data,
                  status: data.status || 'Not Started',
                  approvalStatus: data.approvalStatus || 'Pending'
                };
              }
            });
            setLatestStatusData(current => ({...current, ...newStatusData}));
          }
        });
        unsubscribersRef.current.push(backfillsUnsubscribe);

        // Set up recruitment status listener
        const statusRef = collection(db, `organizations/${currentUser.orgId}/recruitmentStatus`);
        const statusUnsubscribe = onSnapshot(statusRef, snapshot => {
          if (!isMounted.current) return;
          
          const newStatusData = {};
          snapshot.forEach(doc => {
            const data = doc.data();
            if (data.id) {
              newStatusData[data.id] = {
                ...data,
                status: data.status || 'Not Started',
                approvalStatus: data.approvalStatus || 'Pending',
                recruitmentStatus: data.status || 'Not Started'
              };
            }
          });
          
          setLatestStatusData(current => {
            const updated = {...current, ...newStatusData};
            // Process scenarios with updated status
            processScenarios(updated);
            return updated;
          });
        });
        unsubscribersRef.current.push(statusUnsubscribe);

        setupComplete = true;
      } catch (error) {
        console.error('Error setting up listeners:', error);
        cleanupListeners();
      }
    };

    setupListeners();

    return () => {
      if (setupComplete) {
        cleanupListeners();
      }
    };
  }, [currentUser?.orgId, cleanupListeners, processScenarios]);

  // Process scenarios and employees when they change
  useEffect(() => {
    if (!isMounted.current) return;

    const debounceTimeout = setTimeout(() => {
      console.log('Processing scenarios:', scenarios?.length);
      console.log('Processing employees:', employees?.length);
      processScenarios(latestStatusData);
    }, 300);

    return () => clearTimeout(debounceTimeout);
  }, [scenarios, employees, latestStatusData, processScenarios]);

  // Add safe status check helper
  const getStatus = useCallback((role) => {
    if (!role) return 'Not Started';
    return (role.recruitmentStatus || role.status || 'Not Started').toLowerCase();
  }, []);

  // Group roles by stage
  const rolesByStage = useMemo(() => {
    if (!Array.isArray(stages) || !Array.isArray(approvedRoles)) {
      return {};
    }

    return stages.reduce((acc, stage) => {
      if (!stage?.name) return acc;
      
      acc[stage.name] = approvedRoles.filter(role => {
        if (!role) return false;
        return getStageName(role).toLowerCase() === stage.name.toLowerCase();
      });
      
      return acc;
    }, {});
  }, [stages, approvedRoles]);

  // Add safe currency formatting helper
  const formatCurrency = useCallback((value, currency = 'USD') => {
    if (value == null) return 'Not set';
    const numValue = typeof value === 'string' ? parseFloat(value) : Number(value);
    if (!Number.isFinite(numValue)) return 'Not set';
    let formatted;
    try {
      formatted = numValue.toLocaleString('en-US');
    } catch {
      formatted = numValue.toString();
    }
    return `${currency} ${formatted}`;
  }, []);

  // Add backfill status listener
  useEffect(() => {
    const handleBackfillStatusChange = () => {
      const backfillStatuses = JSON.parse(localStorage.getItem('backfillStatuses') || '{}');
      const recruitmentStatus = JSON.parse(localStorage.getItem('recruitmentStatus') || '{}');
      const roleDetails = JSON.parse(localStorage.getItem('taRoleDetails') || '{}');

      // Process approved backfills
      const approvedBackfills = Object.entries(backfillStatuses)
        .filter(([_, status]) => status.status === 'Approved')
        .map(([id]) => {
          const details = roleDetails[id] || {};
          return {
            id,
            ...details,
            isBackfill: true,
            approvalStatus: 'Approved',
            recruitmentStatus: recruitmentStatus[id]?.status || 'Not Started',
            isVisible: true,
            isActive: true
          };
        });

      setApprovedRoles(current => {
        // Filter out existing backfills to avoid duplicates
        const nonBackfills = current.filter(role => !role.isBackfill);
        return [...nonBackfills, ...approvedBackfills];
      });
    };

    // Initial load
    handleBackfillStatusChange();

    // Listen for changes
    window.addEventListener('storage', (e) => {
      if (e.key === 'backfillStatuses' || e.key === 'recruitmentStatus' || e.key === 'taRoleDetails') {
        handleBackfillStatusChange();
      }
    });

    return () => {
      window.removeEventListener('storage', handleBackfillStatusChange);
    };
  }, []);

  const updateRoles = useCallback(() => {
    if (!updatePendingRef.current) return;
    
    // Check if data has actually changed
    const statusDataStr = JSON.stringify(latestStatusData);
    const scenarioRolesStr = JSON.stringify(latestScenarioRoles);
    const prevStatusDataStr = JSON.stringify(previousDataRef.current.statusData);
    const prevScenarioRolesStr = JSON.stringify(previousDataRef.current.scenarioRoles);
    
    if (statusDataStr === prevStatusDataStr && scenarioRolesStr === prevScenarioRolesStr) {
      updatePendingRef.current = false;
      return;
    }

    console.log('=== UPDATE ROLES CALLED ===');
    console.log('Latest status data:', latestStatusData);
    console.log('Latest scenario roles:', latestScenarioRoles);

    // Get backfill statuses from localStorage
    const backfillStatuses = JSON.parse(localStorage.getItem('backfillStatuses') || '{}');
    const approvalStatuses = JSON.parse(localStorage.getItem('approvalStatuses') || '{}');
    const recruitmentStatus = JSON.parse(localStorage.getItem('recruitmentStatus') || '{}');
    const roleDetails = JSON.parse(localStorage.getItem('taRoleDetails') || '{}');

    setApprovedRoles(current => {
      // Get approved roles from scenarios
      const approvedScenarioRoles = latestScenarioRoles
        .filter(role => role.approvalStatus === 'Approved' || approvalStatuses[role.id]?.status === 'Approved')
        .map(role => ({
          ...role,
          recruitmentStatus: recruitmentStatus[role.id]?.status || 'Not Started',
          isVisible: true,
          isActive: true
        }));

      // Get approved backfills
      const approvedBackfills = Object.entries(backfillStatuses)
        .filter(([_, status]) => status.status === 'Approved')
        .map(([id]) => {
          const details = roleDetails[id] || {};
          return {
            id,
            ...details,
            isBackfill: true,
            approvalStatus: 'Approved',
            recruitmentStatus: recruitmentStatus[id]?.status || 'Not Started',
            isVisible: true,
            isActive: true
          };
        });

      // Combine both types of roles
      const allRoles = [...approvedScenarioRoles, ...approvedBackfills];

      // Merge with existing roles to preserve state
      const mergedRoles = allRoles.map(role => {
        const existingRole = current.find(r => r.id === role.id);
        return {
          ...role,
          ...(existingRole?.status === 'Completed' ? {
            hireName: existingRole.hireName,
            baseSalary: existingRole.baseSalary,
            location: existingRole.location,
            confirmedStartDate: existingRole.confirmedStartDate,
            currency: existingRole.currency
          } : {})
        };
      });

      console.log('Final merged roles:', mergedRoles);
      
      // Update previous data references
      previousDataRef.current = {
        statusData: {...latestStatusData},
        scenarioRoles: [...latestScenarioRoles]
      };
      updatePendingRef.current = false;
      
      return mergedRoles;
    });
  }, [latestStatusData, latestScenarioRoles]);

  // Keep currentRoleStatesRef in sync with approvedRoles
  useEffect(() => {
    const roleStates = {};
    approvedRoles.forEach(role => {
      roleStates[role.id] = {
        status: role.status,
        recruitmentStatus: role.recruitmentStatus,
        approvalStatus: role.approvalStatus,
        // Include any other status-related fields
        isVisible: role.isVisible,
        isActive: role.isActive,
        // Include completion details if they exist
        ...(role.recruitmentStatus === 'Completed' && {
          hireName: role.hireName,
          baseSalary: role.baseSalary,
          location: role.location,
          confirmedStartDate: role.confirmedStartDate,
          currency: role.currency
        })
      };
    });
    currentRoleStatesRef.current = roleStates;
  }, [approvedRoles]);

  const checkRoleApproval = (hire, statusData) => {
    const isApproved = 
      hire.status === 'Approved' || 
      hire.approvalStatus === 'Approved' ||
      statusData?.status === 'Approved' ||
      statusData?.approvalStatus === 'Approved';

    return {
      hireStatus: hire.status,
      hireApprovalStatus: hire.approvalStatus,
      statusDataStatus: statusData?.status,
      statusDataApprovalStatus: statusData?.approvalStatus,
      isApproved
    };
  };

  const findScenarioForRole = async (roleId) => {
    // Find the role in scenarios
    for (const scenario of scenarios) {
      if (scenario.plannedHires) {
        const role = scenario.plannedHires.find(hire => hire.id === roleId);
        if (role) {
          return scenario.id;
        }
      }
    }
    return null;
  };

  const processScenarioAndStatusData = (hire, statusData) => {
    const hireStatus = hire?.status || '';
    const hireApprovalStatus = hire?.approvalStatus || '';
    const statusDataStatus = statusData?.status;
    const statusDataApprovalStatus = statusData?.approvalStatus;
    
    return {
      hireStatus,
      hireApprovalStatus,
      statusDataStatus,
      statusDataApprovalStatus,
      isApproved: hireApprovalStatus === 'Approved'
    };
  };

  const getRoleState = (currentState, savedStatus, approvalResult) => {
    // Preserve recruitment status from status document
    const recruitmentStatus = savedStatus || (currentState?.recruitmentStatus || 'Not Started');
    
    return {
      status: approvalResult.hireStatus,
      recruitmentStatus,
      approvalStatus: approvalResult.hireApprovalStatus,
      isVisible: true,
      isActive: true
    };
  };

  // Update recruitment status in Firebase
  const updateRecruitmentStatusInFirebase = useCallback(async (roleId, newStatus) => {
    console.log('=== updateRecruitmentStatusInFirebase START ===');
    console.log('roleId:', roleId);
    console.log('new status:', newStatus);

    if (!currentUser?.orgId || !roleId) {
      console.error('Missing required data for status update');
      return;
    }

    try {
      const statusRef = doc(db, `organizations/${currentUser.orgId}/recruitmentStatus/${roleId}`);
      const roleRef = doc(db, `organizations/${currentUser.orgId}/roles/${roleId}`);

      // Get current data
      const [statusDoc, roleDoc] = await Promise.all([
        getDoc(statusRef),
        getDoc(roleRef)
      ]);

      const currentRoleData = roleDoc.exists() ? roleDoc.data() : {};
      const currentStatusData = statusDoc.exists() ? statusDoc.data() : {};

      console.log('Current role data:', currentRoleData);
      console.log('Current status data:', currentStatusData);

      // Update both documents
      await Promise.all([
        setDoc(statusRef, {
          ...currentStatusData,
          status: newStatus,
          updatedAt: serverTimestamp(),
          updatedBy: currentUser.uid,
          updatedByTab: 'talent'
        }),
        setDoc(roleRef, {
          ...currentRoleData,
          recruitmentStatus: newStatus,
          status: newStatus,
          lastModified: serverTimestamp()
        })
      ]);

      console.log('Successfully updated recruitment status');
    } catch (error) {
      console.error('Error updating recruitment status:', error);
      throw error;
    }
    console.log('=== updateRecruitmentStatusInFirebase END ===');
  }, [currentUser?.orgId, currentUser?.uid]);

  // Update backfill status in Firebase
  const updateBackfillStatusInFirebase = useCallback(async (backfillId, newStatus) => {
    console.log('=== updateBackfillStatusInFirebase START ===');
    console.log('backfillId:', backfillId);
    console.log('new status:', newStatus);

    if (!currentUser?.orgId || !backfillId) {
      console.error('Missing required data for backfill status update');
      return;
    }

    try {
      const backfillRef = doc(db, `organizations/${currentUser.orgId}/backfills/${backfillId}`);
      const statusRef = doc(db, `organizations/${currentUser.orgId}/recruitmentStatus/${backfillId}`);

      // Get current data
      const [backfillDoc, statusDoc] = await Promise.all([
        getDoc(backfillRef),
        getDoc(statusRef)
      ]);

      const currentRoleData = backfillDoc.exists() ? backfillDoc.data() : {};
      const currentStatusData = statusDoc.exists() ? statusDoc.data() : {};

      console.log('Current role data:', currentRoleData);
      console.log('Current status data:', currentStatusData);

      // Update both documents
      await Promise.all([
        setDoc(backfillRef, {
          ...currentRoleData,
          recruitmentStatus: newStatus,
          status: newStatus,
          lastModified: serverTimestamp()
        }),
        setDoc(statusRef, {
          ...currentStatusData,
          status: newStatus,
          updatedAt: serverTimestamp(),
          updatedBy: currentUser.uid,
          updatedByTab: 'talent'
        })
      ]);

      console.log('Successfully updated backfill status');
    } catch (error) {
      console.error('Error updating backfill status:', error);
      throw error;
    }
    console.log('=== updateBackfillStatusInFirebase END ===');
  }, [currentUser?.orgId, currentUser?.uid]);

  // Handle status change
  const handleStatusChange = async (roleId, newStatus) => {
    console.log('=== handleStatusChange START ===');
    console.log('roleId:', roleId);
    console.log('newStatus:', newStatus);

    if (!currentUser?.orgId) {
        console.error('Missing organization ID');
        return;
    }

    try {
        // Get the current role data
        const role = approvedRoles.find(r => r.id === roleId);

        // If changing to Completed, show the modal instead of updating status
        if (newStatus === 'Completed') {
            setSelectedRole(role);
            setIsRoleModalOpen(true);
            return;
        }

        console.log('=== EMPLOYEE DELETION PROCESS START ===');
        console.log('Role ID:', roleId);
        console.log('Current user orgId:', currentUser.orgId);
        console.log('Role data:', role);

        const batch = writeBatch(db);

        // If changing from Completed status, delete the employee
        if (role?.status === 'Completed' && newStatus !== 'Completed') {
            // Use the employeeId that was stored when the role was completed
            if (role.employeeId) {
                console.log('Deleting employee document with ID:', role.employeeId);
                const employeeRef = doc(db, `organizations/${currentUser.orgId}/employees/${role.employeeId}`);
                batch.delete(employeeRef);
            }
        }

        // Get the status document reference and check if it exists
        const statusRef = doc(db, `organizations/${currentUser.orgId}/recruitmentStatus/${roleId}`);
        const statusDoc = await getDoc(statusRef);

        // Create or update the status document
        const statusData = {
            id: roleId,
            status: newStatus,
            recruitmentStatus: newStatus,
            updatedAt: serverTimestamp(),
            updatedBy: currentUser.uid,
            updatedByTab: 'talent',
            isActive: true,
            isVisible: true
        };

        if (!statusDoc.exists()) {
            // If document doesn't exist, use set
            batch.set(statusRef, statusData);
        } else {
            // If document exists, use update to preserve other fields
            batch.update(statusRef, statusData);
        }

        // Find and update the scenario containing this role
        const scenarioId = role?.scenarioId;
        if (scenarioId) {
            const scenarioRef = doc(db, `organizations/${currentUser.orgId}/scenarios/${scenarioId}`);
            const scenarioDoc = await getDoc(scenarioRef);
            
            if (scenarioDoc.exists()) {
                const scenarioData = scenarioDoc.data();
                const updatedHires = scenarioData.plannedHires.map(hire => {
                    if (hire.id === roleId) {
                        const updatedHire = {
                            ...hire,
                            status: newStatus,
                            recruitmentStatus: newStatus,
                            updatedAt: new Date().toISOString()
                        };

                        // If moving away from Completed, remove employee-related fields
                        if (hire.status === 'Completed' && newStatus !== 'Completed') {
                            updatedHire.employeeId = null;
                            updatedHire.hireName = null;
                            updatedHire.baseSalary = null;
                            updatedHire.location = null;
                            updatedHire.confirmedStartDate = null;
                            updatedHire.currency = null;
                            updatedHire.convertedToEmployee = false;
                        }

                        return updatedHire;
                    }
                    return hire;
                });

                batch.update(scenarioRef, {
                    plannedHires: updatedHires,
                    lastModified: serverTimestamp()
                });
            }
        }

        await batch.commit();
        console.log('Status updated successfully');

        // Update local storage
        const savedStatuses = JSON.parse(localStorage.getItem('recruitmentStatus') || '{}');
        savedStatuses[roleId] = {
            ...savedStatuses[roleId],
            status: newStatus,
            recruitmentStatus: newStatus
        };
        localStorage.setItem('recruitmentStatus', JSON.stringify(savedStatuses));

        // If moving away from Completed, clean up other local storage
        if (role?.status === 'Completed' && newStatus !== 'Completed') {
            const roleDetails = JSON.parse(localStorage.getItem('taRoleDetails') || '{}');
            delete roleDetails[roleId];
            localStorage.setItem('taRoleDetails', JSON.stringify(roleDetails));

            // Clean up employee storage
            const employeeDetails = JSON.parse(localStorage.getItem('employeeDetails') || '{}');
            if (role.employeeId) {
                delete employeeDetails[role.employeeId];
            }
            localStorage.setItem('employeeDetails', JSON.stringify(employeeDetails));
        }

        // Trigger storage event
        window.dispatchEvent(new Event('storage'));

    } catch (error) {
        console.error('Error updating status:', error);
        alert('Failed to update status. Please try again.');
    }
};

  // Function to handle role updates
  const handleRoleUpdate = async (updatedRole, newEmployee) => {
    console.log('=== handleRoleUpdate called ===');
    console.log('updatedRole:', updatedRole);
    console.log('newEmployee:', newEmployee);

    if (!currentUser?.orgId) {
        console.error('Missing organization ID');
        return;
    }

    try {
        const batch = writeBatch(db);
        const statusRef = doc(db, `organizations/${currentUser.orgId}/recruitmentStatus/${updatedRole.id}`);
        const statusDoc = await getDoc(statusRef);

        // Find the scenario containing this role
        const scenariosRef = collection(db, `organizations/${currentUser.orgId}/scenarios`);
        const scenarioQuery = query(scenariosRef, where("id", "==", updatedRole.scenarioId));
        const scenarioQuerySnapshot = await getDocs(scenarioQuery);
        
        if (scenarioQuerySnapshot.empty) {
            console.error('Could not find scenario for role:', updatedRole);
            return;
        }

        // Get the actual scenario document reference using the document ID from the query
        const scenarioDoc = scenarioQuerySnapshot.docs[0];
        const scenarioRef = doc(db, `organizations/${currentUser.orgId}/scenarios/${scenarioDoc.id}`);
        const scenarioData = scenarioDoc.data();
        const currentTimestamp = new Date().toISOString();

        if (newEmployee) {
            // This is a complete hire operation
            const roleUpdate = {
                status: 'Completed',
                recruitmentStatus: 'Completed',
                isActive: true,
                convertedToEmployee: true,
                updatedAt: currentTimestamp,
                employeeId: newEmployee.id,
                hireName: updatedRole.hireName,
                baseSalary: updatedRole.baseSalary,
                location: updatedRole.location,
                confirmedStartDate: updatedRole.confirmedStartDate,
                currency: updatedRole.currency
            };

            const statusUpdate = {
                id: updatedRole.id,
                status: 'Completed',
                recruitmentStatus: 'Completed',
                approvalStatus: 'Approved',
                isVisible: true,
                isActive: true,
                convertedToEmployee: true,
                updatedAt: serverTimestamp(),
                updatedBy: currentUser.uid,
                updatedByTab: 'talent',
                employeeId: newEmployee.id,
                ...roleUpdate
            };

            // Create the employee document
            const employeeRef = doc(db, `organizations/${currentUser.orgId}/employees/${newEmployee.id}`);
            const employeeData = {
                id: newEmployee.id,
                firstName: newEmployee.firstName,
                surname: newEmployee.surname || '',
                title: updatedRole.roleTitle,
                org: updatedRole.organization,
                team: updatedRole.team,
                directManager: updatedRole.directManager,
                baseSalary: updatedRole.baseSalary,
                currency: updatedRole.currency,
                location: updatedRole.location,
                startDate: updatedRole.confirmedStartDate,
                isActive: true,
                createdAt: currentTimestamp,
                updatedAt: currentTimestamp,
                orgId: currentUser.orgId,
                function: updatedRole.function,
                level: updatedRole.level,
                originalRoleId: updatedRole.id
            };
            batch.set(employeeRef, employeeData);

            // Update the role in the scenario's plannedHires array
            const updatedPlannedHires = scenarioData.plannedHires.map(hire => 
                hire.id === updatedRole.id 
                    ? { ...hire, ...roleUpdate }
                    : hire
            );

            batch.update(scenarioRef, {
                plannedHires: updatedPlannedHires,
                lastModified: serverTimestamp()
            });
            
            // Set or update the status document
            if (!statusDoc.exists()) {
                batch.set(statusRef, statusUpdate);
            } else {
                batch.update(statusRef, statusUpdate);
            }
        } else {
            // Regular role update
            const roleUpdate = {
                ...updatedRole,
                updatedAt: currentTimestamp
            };

            const statusUpdate = {
                id: updatedRole.id,
                ...updatedRole,
                updatedAt: serverTimestamp(),
                updatedBy: currentUser.uid,
                updatedByTab: 'talent',
                isActive: true,
                isVisible: true
            };

            // Update the role in the scenario's plannedHires array
            const updatedPlannedHires = scenarioData.plannedHires.map(hire => 
                hire.id === updatedRole.id 
                    ? { ...hire, ...roleUpdate }
                    : hire
            );

            batch.update(scenarioRef, {
                plannedHires: updatedPlannedHires,
                lastModified: serverTimestamp()
            });
            
            // Set or update the status document
            if (!statusDoc.exists()) {
                batch.set(statusRef, statusUpdate);
            } else {
                batch.update(statusRef, statusUpdate);
            }
        }

        await batch.commit();

        // Update local storage
        const savedStatuses = JSON.parse(localStorage.getItem('recruitmentStatus') || '{}');
        savedStatuses[updatedRole.id] = {
            ...savedStatuses[updatedRole.id],
            status: newEmployee ? 'Completed' : updatedRole.status,
            recruitmentStatus: newEmployee ? 'Completed' : updatedRole.recruitmentStatus
        };
        localStorage.setItem('recruitmentStatus', JSON.stringify(savedStatuses));

        // If we're completing a hire, update employee details in local storage
        if (newEmployee) {
            const employeeDetails = JSON.parse(localStorage.getItem('employeeDetails') || '{}');
            employeeDetails[newEmployee.id] = {
                firstName: newEmployee.firstName,
                surname: newEmployee.surname || '',
                title: updatedRole.roleTitle,
                org: updatedRole.organization,
                team: updatedRole.team
            };
            localStorage.setItem('employeeDetails', JSON.stringify(employeeDetails));
        }

        // Trigger storage event to update other components
        window.dispatchEvent(new Event('storage'));

    } catch (error) {
        console.error('Error updating role:', error);
        alert('Failed to update role. Please try again.');
    }
  };

  const checkBackfillApproval = (employee) => {
    const backfillId = `backfill-${employee.id}`;
    const backfillStatuses = JSON.parse(localStorage.getItem('backfillStatuses') || '{}');
    const backfillStatus = backfillStatuses[backfillId];
    
    console.log('Checking backfill status:', {
      backfillId,
      backfillStatus,
      employee
    });

    return {
      isApproved: backfillStatus?.status === 'Approved',
      hasEndDate: !!employee.endDate,
      statusApproval: backfillStatus?.status === 'Approved',
      statusStatus: backfillStatus?.status || null,
      localStorageStatus: backfillStatus || null,
      backfillId
    };
  };

  // Listen for scenario updates from the Firebase context
  useEffect(() => {
    const handleScenarioUpdate = (event) => {
      const { scenarios } = event.detail;
      const allRoles = [];
      scenarios.forEach((scenario) => {
        if (scenario.plannedHires) {
          allRoles.push(...scenario.plannedHires.map(hire => ({
            ...hire,
            scenarioId: scenario.id,
            scenarioName: scenario.name
          })));
        }
      });
      setLatestScenarioRoles(allRoles);
    };

    window.addEventListener('scenariosUpdated', handleScenarioUpdate);
    return () => {
      window.removeEventListener('scenariosUpdated', handleScenarioUpdate);
    };
  }, []);

  // Process updates when data changes
  useEffect(() => {
    if (!latestStatusData || !latestScenarioRoles) return;

    const processedRoles = latestScenarioRoles.map(role => {
      const statusData = latestStatusData[role.id];
      return {
        ...role,
        status: statusData?.status || role.status || 'Not Started',
        recruitmentStatus: statusData?.recruitmentStatus || statusData?.status || role.recruitmentStatus || 'Not Started'
      };
    });

    setApprovedRoles(processedRoles);
  }, [latestStatusData, latestScenarioRoles]);

  const handleModalClose = () => {
    setIsRoleModalOpen(false);
    setSelectedRole(null);
    setPendingCompletionRole(null);
  };

  // Filter roles for display
  const visibleRoles = useMemo(() => {
    console.log('=== FILTERING ROLES ===');
    console.log('Input roles:', approvedRoles);
    console.log('Hide completed:', hideCompleted);
    
    return approvedRoles.filter(role => {
      const visibility = {
        isVisible: role.isVisible,
        isActive: role.isActive,
        status: role.status,
        approvalStatus: role.approvalStatus,
        recruitmentStatus: role.recruitmentStatus,
        isBackfill: role.isBackfill
      };
      console.log(`Checking visibility for role ${role.id}:`, visibility);
      
      // Basic validation
      if (!role.roleTitle || !role.team || !role.organization) {
        console.log(`Role ${role.id} filtered out due to missing required data`);
        return false;
      }

      // Filter completed roles if hideCompleted is true
      if (hideCompleted && role.recruitmentStatus === 'Completed') {
        console.log(`Role ${role.id} filtered out because it's completed and hide completed is true`);
        return false;
      }
      
      // Show all other roles
      console.log(`Role ${role.id} will be shown`);
      return true;
    });
  }, [approvedRoles, hideCompleted]);

  const getTeamMembers = () => {
    const savedMembers = JSON.parse(localStorage.getItem('taTeamMembers') || '[]');
    return savedMembers.filter(member => member.active !== false);
  };

  const assignTAtoRole = (roleId, taId) => {
    const newAssignments = {
      ...taAssignments,
      [roleId]: {
        taId,
        assignedAt: new Date().toISOString()
      }
    };
    setTaAssignments(newAssignments);
    localStorage.setItem('taAssignments', JSON.stringify(newAssignments));
  };

  // Add effect to persist hideCompleted state
  useEffect(() => {
    localStorage.setItem('hideCompleted', JSON.stringify(hideCompleted));
  }, [hideCompleted]);

  const filterRoles = (roles) => {
    console.log('=== FILTERING ROLES ===');
    console.log('Input roles:', roles);
    console.log('Hide completed:', hideCompleted);

    return roles.filter(role => {
      const statusData = latestStatusData[role.id] || {};
      console.log('Checking visibility for role', role.id + ':', statusData);

      // Always show roles that are:
      // 1. Approved AND
      // 2. Either:
      //    a) Active (not completed) OR
      //    b) Completed but we're showing completed roles
      const isApproved = role.isApproved || statusData.approvalStatus === 'Approved';
      const isCompleted = statusData.status === 'Completed';
      const shouldShow = isApproved && (!isCompleted || !hideCompleted);

      if (shouldShow) {
        console.log('Role', role.id, 'will be shown');
      }

      return shouldShow;
    });
  };

  // Render role card
  const renderRoleCard = useCallback((role) => {
    if (!role) return null;
    
    const statusData = latestStatusData[role.id] || {};
    const currentStatus = statusData.status || role.status || 'Not Started';
    const { statusClass } = getRoleStatus(role);
    
    return (
      <div key={role.id} className={`role-card ${statusClass}`}>
        <div className="role-header">
          <h3>{role.roleTitle || 'Untitled Role'}</h3>
          {role.isBackfill && (
            <span className="backfill-badge">Backfill</span>
          )}
        </div>
        <div className="role-details">
          <div className="detail-row">
            <span className="label">Status:</span>
            <select
              value={currentStatus}
              onChange={(e) => handleStatusChange(role.id, e.target.value)}
              className="status-select"
            >
              {['Not Started', 'Sourcing', 'Interviewing', 'Offer', 'Completed', 'On Hold'].map(status => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>
          <div className="detail-row">
            <span className="label">Organization:</span>
            <span>{role.organization || 'Not specified'}</span>
          </div>
          <div className="detail-row">
            <span className="label">Team:</span>
            <span>{role.team || 'Not specified'}</span>
          </div>
          {role.isBackfill && (
            <>
              <div className="detail-row">
                <span className="label">Current Employee:</span>
                <span>{role.currentEmployee || 'Not specified'}</span>
              </div>
              <div className="detail-row">
                <span className="label">Leave Date:</span>
                <span>{role.leaverDate ? new Date(role.leaverDate).toLocaleDateString() : 'Not specified'}</span>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }, [latestStatusData, handleStatusChange]);

  return (
    <div className="talent-acquisition">
      <div className="ta-header">
        <div className="ta-header-top">
          <h2>Talent Acquisition Pipeline</h2>
          <div className="header-buttons">
            <button 
              className="hide-completed-button"
              onClick={() => setHideCompleted(!hideCompleted)}
            >
              {hideCompleted ? 'Show Completed' : 'Hide Completed'}
            </button>
            <button 
              className="manage-team-button"
              onClick={() => setIsTeamModalOpen(true)}
            >
              Manage TA Settings
            </button>
          </div>
        </div>

        <div className="pipeline-stats">
          <div className="stage-stats-grid">
            {stageStats.map(stage => (
              <div key={stage.id} className="stage-stat-item">
                <div className="stage-stat-label">{stage.name}</div>
                <div className="stage-stat-count">{stage.count}</div>
                <div className="stage-stat-bar">
                  <div 
                    className="stage-stat-bar-fill"
                    style={{ 
                      width: `${approvedRoles.length ? (stage.count / approvedRoles.length) * 100 : 0}%`
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="roles-list">
        {filteredRoles.length === 0 ? (
          <div className="no-roles">
            <p>No approved roles to display</p>
          </div>
        ) : (
          filteredRoles.map(role => {
            const { status, statusClass } = getRoleStatus(role);
            return (
              <div 
                key={role.id} 
                className="role-card" 
                onClick={() => {
                  setSelectedRole(role);
                  setIsRoleModalOpen(true);
                }}
              >
                <div className="role-header">
                  <div className="role-title">
                    <h3>{role.roleTitle}</h3>
                    {role.isBackfill ? (
                      <span className="role-type backfill">Backfill</span>
                    ) : (
                      <span className={`role-type ${(role.type || 'new-hire').toLowerCase().replace(' ', '-')}`}>
                        {role.type || 'New Hire'}
                      </span>
                    )}
                  </div>
                  <div className="role-controls">
                    <select
                      value={taAssignments[role.id]?.taId || ''}
                      onChange={(e) => assignTAtoRole(role.id, e.target.value)}
                      className="ta-select"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <option value="">Assign TA</option>
                      {getTeamMembers().map(member => (
                        <option key={member.id} value={member.id}>
                          {member.name} ({member.role})
                        </option>
                      ))}
                    </select>
                    <select
                      value={latestStatusData[role.id]?.status || role.recruitmentStatus || role.status || 'Not Started'}
                      onChange={(e) => handleStatusChange(role.id, e.target.value)}
                      className={`status-select ${statusClass}`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {RECRUITMENT_STATUSES.map(status => (
                        <option key={status} value={status}>
                          {status}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="role-details">
                  <div className="detail-group">
                    <label>Organization:</label>
                    <span>{role.organization}</span>
                  </div>
                  <div className="detail-group">
                    <label>Team:</label>
                    <span>{role.team}</span>
                  </div>
                  <div className="detail-group">
                    <label>{status === 'Completed' ? 'Start Date' : 'Target Start'}:</label>
                    <span>
                      {role.confirmedStartDate ? 
                        new Date(role.confirmedStartDate).toLocaleDateString() : 
                        role.startDate ? 
                          new Date(role.startDate).toLocaleDateString() :
                          'Not set'}
                    </span>
                  </div>
                  <div className="detail-group">
                    <label>{status === 'Completed' ? 'Salary' : 'Budget'}:</label>
                    <span>
                      {status === 'Completed' ? 
                        (role.baseSalary != null ? formatCurrency(role.baseSalary, role.currency || 'USD') : 'Not set') :
                        (role.totalCost != null ? formatCurrency(role.totalCost) : 'Not set')}
                    </span>
                  </div>
                  {status === 'Completed' && role.location && (
                    <div className="detail-group">
                      <label>Location:</label>
                      <span>{role.location}</span>
                    </div>
                  )}
                  {status === 'Completed' && role.hireName && (
                    <div className="detail-group">
                      <label>Hire:</label>
                      <span>{role.hireName}</span>
                    </div>
                  )}
                  {!role.isBackfill && (
                    <div className="detail-group">
                      <label>Scenario:</label>
                      <span>{role.scenarioName}</span>
                    </div>
                  )}
                  {role.isBackfill && (
                    <div className="detail-group">
                      <label>Current Employee:</label>
                      <span>{role.currentEmployee}</span>
                    </div>
                  )}
                </div>
              </div>
            );
          })
        )}
      </div>

      <TATeamModal 
        isOpen={isTeamModalOpen}
        onClose={() => setIsTeamModalOpen(false)}
      />
      
      {isRoleModalOpen && (
        <TARoleModal
          isOpen={isRoleModalOpen}
          onClose={handleModalClose}
          role={selectedRole}
          recruitmentStatus="Completed"
          onUpdateRole={(updatedRole, newEmployee) => {
            handleRoleUpdate(updatedRole, newEmployee);
            handleModalClose();
          }}
        />
      )}
    </div>
  );
}

export default TalentAcquisition;