import React from 'react';
import './features/scenarios/ScenarioPlanning.css';
import { useFirebase } from './contexts/FirebaseContext';

const UnallocatedRoles = ({ scenarios, expandedOrg, setExpandedOrg }) => {
    const { updateScenario } = useFirebase();

    const handleDeleteRole = async (scenarioName, roleId) => {
        try {
            // Clean up any associated data in localStorage
            const savedRecruitmentStatus = JSON.parse(localStorage.getItem('recruitmentStatus') || '{}');
            const savedRoleDetails = JSON.parse(localStorage.getItem('taRoleDetails') || '{}');
            const savedApprovalStatuses = JSON.parse(localStorage.getItem('approvalStatuses') || '{}');
            const savedBackfillStatuses = JSON.parse(localStorage.getItem('backfillStatuses') || '{}');

            // Remove the role from all relevant storage
            delete savedRecruitmentStatus[roleId];
            delete savedRoleDetails[roleId];
            delete savedApprovalStatuses[roleId];
            delete savedBackfillStatuses[roleId];

            // Update localStorage
            localStorage.setItem('recruitmentStatus', JSON.stringify(savedRecruitmentStatus));
            localStorage.setItem('taRoleDetails', JSON.stringify(savedRoleDetails));
            localStorage.setItem('approvalStatuses', JSON.stringify(savedApprovalStatuses));
            localStorage.setItem('backfillStatuses', JSON.stringify(savedBackfillStatuses));

            // Find the scenario and update it
            const scenario = scenarios.find(s => s.name === scenarioName);
            if (scenario) {
                const updatedScenario = {
                    ...scenario,
                    plannedHires: (scenario.plannedHires || []).filter(h => h.id !== roleId),
                    updatedAt: new Date().toISOString()
                };
                await updateScenario(scenario.id, updatedScenario);
            }
        } catch (error) {
            console.error('Error deleting role:', error);
            alert('Failed to delete role. Please try again.');
        }
    };

    // Calculate total unallocated roles
    const totalUnallocated = scenarios.reduce((count, scenario) => 
        count + (scenario.plannedHires || [])
            .filter(hire => {
                // Use scenario's organization if hire doesn't have one
                const effectiveOrg = hire.organization || scenario.organization;
                // Only consider it unallocated if both team and function are missing
                return !effectiveOrg || (!hire.team && !hire.function);
            })
            .length
    , 0);

    // Don't render anything if there are no unallocated roles
    if (totalUnallocated === 0) {
        return null;
    }

    return (
        <div className="org-section unallocated-section">
            <div 
                className="org-header"
                onClick={() => setExpandedOrg(expandedOrg === 'unallocated' ? null : 'unallocated')}
            >
                <div className="org-title">
                    <h3>Unallocated Roles</h3>
                    <div className="org-quick-stats">
                        <div className="case-stat">
                            <span className="case-label">Total Unallocated: </span>
                            <span className="case-value">
                                {totalUnallocated}
                            </span>
                        </div>
                    </div>
                </div>
                <button className={`expand-button ${expandedOrg === 'unallocated' ? 'expanded' : ''}`}>
                    ▼
                </button>
            </div>
            {expandedOrg === 'unallocated' && (
                <div className="org-content">
                    {scenarios.map(scenario => {
                        const unallocatedHires = (scenario.plannedHires || [])
                            .filter(hire => {
                                // Use scenario's organization if hire doesn't have one
                                const effectiveOrg = hire.organization || scenario.organization;
                                // Only consider it unallocated if both team and function are missing
                                return !effectiveOrg || (!hire.team && !hire.function);
                            });
                        
                        if (unallocatedHires.length === 0) return null;
                        
                        return (
                            <div key={scenario.name} className="scenario-type-section">
                                <div className="scenario-type-header">
                                    <div className="scenario-type-info">
                                        <h4>{scenario.name}</h4>
                                        <div className="scenario-stats">
                                            <span className="stat-badge">
                                                {unallocatedHires.length} unallocated
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="unallocated-roles">
                                    {unallocatedHires.map((hire, index) => {
                                        const effectiveOrg = hire.organization || scenario.organization;
                                        return (
                                            <div key={hire.id || `${scenario.name}-${index}`} className="unallocated-role-card">
                                                <div className="role-info">
                                                    <h5>{hire.roleTitle || 'Untitled Role'}</h5>
                                                    <div className="role-details">
                                                        <span>ID: {hire.id || 'No ID'}</span>
                                                        <span>Manager: {hire.directManager || 'Unassigned'}</span>
                                                        <span>Missing: {[
                                                            !effectiveOrg && 'Organization',
                                                            !hire.team && 'Team',
                                                            !hire.function && 'Function'
                                                        ].filter(Boolean).join(', ')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default UnallocatedRoles; 